import React from 'react';

import {
    MainContainerHeader, ReactAux, Tabs, TabList,
    TabItem, TabItemTitle, TabContent, TabContentItem, Collapse,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, ActionSettingsForm, LinkWithIcon, Button, Select,
    MainContainerScrollable, Checkbox,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';

class DemoLoanRequest extends React.Component {

    state = {
        currentlyActiveTab: 1,
        collapseDemo2: false,
        collapseDemo3: false,
        actionContainerActiveFormIndex: -1,
        isFirstActionDisabled: false,
        isActionContainerLocked: true,
        isLockDisabled: false,
        selectValue1: '1',
        selectValue2: '1',
    }

    setCurrentlyActiveTab = (newTabIndex: number) => {
        this.setState({ currentlyActiveTab: newTabIndex });
    }

    openActionContainerForm(formIndex: number) {
        this.setState({ actionContainerActiveFormIndex: formIndex });
    }

    toggleActiveActionSetting() {
        this.setState({ isFirstActionDisabled: !this.state.isFirstActionDisabled });
        this.openActionContainerForm(-1);
    }

    toggleActionContainerLocked() {
        this.setState({ isActionContainerLocked: !this.state.isActionContainerLocked });
    }

    toggleLockDisabled() {
        this.setState({ isLockDisabled: !this.state.isLockDisabled });
        this.openActionContainerForm(-1);
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader>
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                            <ActionSettings>
                                <LinkWithIcon disabled={this.state.isFirstActionDisabled} icon="fal fa-cog"><a data-tip="Click to edit process definitions" onClick={(e) => { e.preventDefault(); this.openActionContainerForm(1) }} href="/">Commercial loan request</a></LinkWithIcon>
                                <LinkWithIcon icon="fal fa-eye"><a onClick={(e) => { e.preventDefault(); this.openActionContainerForm(2) }} href="/">Collateral Fields mode</a></LinkWithIcon>
                                <Button disabled={this.state.isLockDisabled} data-tip="All changes are allowed" onClick={() => this.toggleActionContainerLocked()} btnType="icon" icon={this.state.isActionContainerLocked ? 'fas fa-lock' : 'fal fa-lock-open'} isDanger={this.state.isActionContainerLocked}></Button>
                            </ActionSettings>
                            <ActionSettingsForm isActive={this.state.actionContainerActiveFormIndex === 1}>
                                <p>Process definitions:</p>
                                <Select
                                    className="width-m"
                                    value={this.state.selectValue1}
                                    onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                                    options={[{ value: '1', label: 'Commercial loan request' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button onClick={e => this.toggleLockDisabled()} icon="fal fa-save">Update</Button>
                                <Button onClick={e => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                            <ActionSettingsForm isActive={this.state.actionContainerActiveFormIndex === 2}>
                                <p>Process definitions:</p>
                                <Select
                                    className="width-m"
                                    value={this.state.selectValue2}
                                    onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                                    options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button icon="fal fa-save" onClick={() => this.toggleActiveActionSetting()}>Update</Button>
                                <Button onClick={e => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                        </ActionContainerHeader>
                        <Button icon="fal fa-pen">Edit</Button>
                        <Button btnType="secondary" icon="fal fa-percent">LTV</Button>
                        <Button btnType="secondary" icon="fal fa-expand">Spreading</Button>
                        <Button btnType="secondary" icon="fal fa-coins">Appraisal</Button>
                        <Button btnType="secondary" icon="fal fa-map-marker-alt">Visit</Button>
                    </ActionContainer>
                </MainContainerHeader>
                <Tabs className="mt-m" currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setState({ currentlyActiveTab: i })}>
                    <TabList>
                        <TabItem>
                            <TabItemTitle>Request</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Borrowers</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Terms</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>ARM</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Schedules</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Fund</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Participations</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Source/Use</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Lines-of-credit</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Declarations</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Miscellaneous</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Compliance</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Fees/Closing</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Doc-Portal</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent>
                        <TabContentItem>Overview tab content</TabContentItem>
                        <TabContentItem>

                            <MainContainerScrollable>
                                <DemoLocationPage />
                                <Collapse
                                    elementRender={() => <Checkbox />}
                                    title="Colapse 2"
                                    expanded={this.state.collapseDemo2}
                                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                                >
                                    <p>Colapse 2 Content...</p>
                                </Collapse>
                                <Collapse
                                    title="Colapse 3"
                                    expanded={this.state.collapseDemo3}
                                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                                >
                                    <p>Colapse 3 Content...</p>
                                </Collapse>
                            </MainContainerScrollable>

                        </TabContentItem>
                        <TabContentItem>Miscellaneous tab content</TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                        <TabContentItem></TabContentItem>
                    </TabContent>
                </Tabs>
            </ReactAux>
        );
    }
}

export default DemoLoanRequest;