import React from 'react';
import Highlight from 'react-highlight';

import {
    SummaryBox
} from '@jkhy/vsg-loanvantage-design-system';


const SummaryBoxPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-l">Summary Box</h1>

            <p className="mb-m">Information boxes.</p>

            <SummaryBox className="mb-s mr-s" title="Product:" content={<a href='/'>*BB BL CONSSUMER PRODUCT</a>} />
            <SummaryBox className="mb-s mr-s" title="Loan amount:" content="$960,000" />
            <SummaryBox className="mb-s mr-s" title="Payment:" content="0" />
            <SummaryBox className="mb-s mr-s" title="Interest Rate:" content="4.25%" />
            <SummaryBox className="mb-s mr-s" title="Term:" content="36 months" />

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { SummaryBox } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    render() {
        return (
            <SummaryBox className="mb-s mr-s" title="Product:" content={<a href='/'>*BB BL CONSSUMER PRODUCT</a>} />
            <SummaryBox className="mb-s mr-s" title="Loan amount:" content="$960,000" />
            <SummaryBox className="mb-s mr-s" title="Payment:" content="0" />
            <SummaryBox className="mb-s mr-s" title="Interest Rate:" content="4.25%" />
            <SummaryBox className="mb-s mr-s" title="Term:" content="36 months" />
        );
    }
}

export default Example;
        `}
            </Highlight>


            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string || JSX.Element(only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>content</td>
                        <td>string || JSX.Element(only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Set the content.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set class to the wrapper.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default SummaryBoxPage;
