import React from 'react';
import Highlight from 'react-highlight';
import { Diagram } from '@jkhy/vsg-loanvantage-design-system';

const diagramData = [
    {
        title: 'Iv Glore',
        titleIcon: 'fal fa-user',
        text: '50.0000%',
        onAddAction: () => console.log('add Iv Glore'),
        children: [
            {
                title: 'Testcase New'
            },
            {
                title: 'Maria Testcase',
                titleIcon: 'fal fa-user',
                text: '50.0000%',
                onAddAction: () => console.log('add action Maria Testcase'),
                onEditAction: () => console.log('edit action Maria Testcase'),
                onDeleteAction: () => console.log('delete action Maria Testcase'),
                children: [
                    {
                        title:'Test Sun'
                    },
                    {
                        title: 'Teresa Balkam Ginnan',
                        titleIcon: 'fal fa-user',
                        text: '50.0000%',
                        onAddAction: () => console.log('add action Teresa Balkam Ginnan'),
                        onEditAction: () => console.log('edit action Teresa Balkam Ginnan'),
                    },
                    {
                        title: 'Elizabeth K Adams',
                        titleIcon: 'fal fa-user',
                        text: '50.0000%',
                        onEditAction: () => console.log('edit action Elizabeth K Adams'),
                        onDeleteAction: () => console.log('delete action Elizabeth K Adams'),
                        children: [
                            {
                                title: 'MANDERE CONSTRUCTION INC',
                                titleIcon: 'fal fa-user',
                                text: '50.0000%',
                                onDeleteAction: () => console.log('delete action MANDERE CONSTRUCTION INC'),
                            },
                            {
                                title: 'Bobby G Stevence',
                                titleIcon: 'fal fa-user',
                                text: '50.0000%',
                                onEditAction: () => console.log('edit action Bobby G Stevence'),
                                children: [
                                    {
                                        title: 'CHASE M RANDALL',
                                        text: '50.0000%',
                                        onAddAction: () => console.log('add action CHASE M RANDALL'),
                                        onEditAction: () => console.log('edit action CHASE M RANDALL'),
                                        onDeleteAction: () => console.log('delete action CHASE M RANDALL'),
                                    },
                                    {
                                        title: "Sam's GMM",
                                        titleIcon: 'fal fa-user',
                                        text: '50.0000%',
                                        onAddAction: () => console.log("add action Sam's GMM"),
                                        onEditAction: () => console.log("edit action Sam's GMM"),
                                        onDeleteAction: () => console.log("delete action Sam's GMM"),
                                        children: [
                                            {
                                                title: 'Gunner Wright Inc',
                                                titleIcon: 'fal fa-user',
                                                text: '50.0000%',
                                                onAddAction: () => console.log("add action Gunner Wright Inc"),
                                                onEditAction: () => console.log("edit action Gunner Wright Inc"),
                                                onDeleteAction: () => console.log("delete action Gunner Wright Inc"),
                                            },
                                            {
                                                title: 'Sprinkles Holdings LLC',
                                                titleIcon: 'fal fa-user',
                                                text: '50.0000%',
                                                onAddAction: () => console.log("add action Sprinkles Holdings LLC"),
                                                onEditAction: () => console.log("edit action Sprinkles Holdings LLC"),
                                                onDeleteAction: () => console.log("delete action Sprinkles Holdings LLC"),
                                            }
                                        ]
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        title: 'TestCase Adams',
                        children:[
                          {title: 'Test Red LTD'},
                          {title: 'Test John LTD'},
                        ]
                    }
                ]
            },
            {
                title: 'DUC0N',
                children: [
                    {
                        title:'DUC0N 1',
                        children:[
                            {
                                title: 'DUC0N 1.1'
                            },
                            {
                                title: 'DUC0N 1.2'
                            },
                            {
                                title: 'DUC0N 1.3'
                            },
                            {
                                title: 'DUC0N 1.4'
                            },
                            {
                                title: 'DUC0N 1.5'
                            },
                        ]
                        
                    },
                    {
                        title:'DUC0N 2'
                    },
                    {
                        title:'DUC0N 3'
                    },
                    {
                        title:'DUC0N 4'
                    }
                ]
            },
        ],
    },
];

const DiagramPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Diagram</h1>
            <p className="mb-l">The Diagram component is used to show tree view of given content.</p>

            <div className="mb-m">
                <Diagram data={diagramData} />
            </div>



            <Highlight className="React">
                {
                    `
import React from 'react';
import { Diagram } from '@jkhy/vsg-loanvantage-design-system';

//Example Data
const diagramData = [
    {
        title: 'Iv Glore',
        titleIcon: 'fal fa-user',
        text: '50.0000%',
        onAddAction: () => console.log('add Iv Glore'),
        children: [
            {
                title: 'Testcase New'
            },
            {
                title: 'Maria Testcase',
                titleIcon: 'fal fa-user',
                text: '50.0000%',
                onAddAction: () => console.log('add action Maria Testcase'),
                onEditAction: () => console.log('edit action Maria Testcase'),
                onDeleteAction: () => console.log('delete action Maria Testcase'),
                children: [
                    {
                        title:'Test Sun'
                    },
                    {
                        title: 'Teresa Balkam Ginnan',
                        titleIcon: 'fal fa-user',
                        text: '50.0000%',
                        onAddAction: () => console.log('add action Teresa Balkam Ginnan'),
                        onEditAction: () => console.log('edit action Teresa Balkam Ginnan'),
                    },
                    {
                        title: 'Elizabeth K Adams',
                        titleIcon: 'fal fa-user',
                        text: '50.0000%',
                        onEditAction: () => console.log('edit action Elizabeth K Adams'),
                        onDeleteAction: () => console.log('delete action Elizabeth K Adams'),
                        children: [
                            {
                                title: 'MANDERE CONSTRUCTION INC',
                                titleIcon: 'fal fa-user',
                                text: '50.0000%',
                                onDeleteAction: () => console.log('delete action MANDERE CONSTRUCTION INC'),
                            },
                            {
                                title: 'Bobby G Stevence',
                                titleIcon: 'fal fa-user',
                                text: '50.0000%',
                                onEditAction: () => console.log('edit action Bobby G Stevence'),
                                children: [
                                    {
                                        title: 'CHASE M RANDALL',
                                        text: '50.0000%',
                                        onAddAction: () => console.log('add action CHASE M RANDALL'),
                                        onEditAction: () => console.log('edit action CHASE M RANDALL'),
                                        onDeleteAction: () => console.log('delete action CHASE M RANDALL'),
                                    },
                                    {
                                        title: "Sam's GMM",
                                        titleIcon: 'fal fa-user',
                                        text: '50.0000%',
                                        onAddAction: () => console.log("add action Sam's GMM"),
                                        onEditAction: () => console.log("edit action Sam's GMM"),
                                        onDeleteAction: () => console.log("delete action Sam's GMM"),
                                        children: [
                                            {
                                                title: 'Gunner Wright Inc',
                                                titleIcon: 'fal fa-user',
                                                text: '50.0000%',
                                                onAddAction: () => console.log("add action Gunner Wright Inc"),
                                                onEditAction: () => console.log("edit action Gunner Wright Inc"),
                                                onDeleteAction: () => console.log("delete action Gunner Wright Inc"),
                                            },
                                            {
                                                title: 'Sprinkles Holdings LLC',
                                                titleIcon: 'fal fa-user',
                                                text: '50.0000%',
                                                onAddAction: () => console.log("add action Sprinkles Holdings LLC"),
                                                onEditAction: () => console.log("edit action Sprinkles Holdings LLC"),
                                                onDeleteAction: () => console.log("delete action Sprinkles Holdings LLC"),
                                            }
                                        ]
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        title: 'TestCase Adams',
                        children:[
                          {title: 'Test Red LTD'},
                          {title: 'Test John LTD'},
                        ]
                    }
                ]
            },
            {
                title: 'DUC0N',
                children: [
                    {
                        title:'DUC0N 1',
                        children:[
                            {
                                title: 'DUC0N 1.1'
                            },
                            {
                                title: 'DUC0N 1.2'
                            },
                            {
                                title: 'DUC0N 1.3'
                            },
                            {
                                title: 'DUC0N 1.4'
                            },
                            {
                                title: 'DUC0N 1.5'
                            },
                        ]
                        
                    },
                    {
                        title:'DUC0N 2'
                    },
                    {
                        title:'DUC0N 3'
                    },
                    {
                        title:'DUC0N 4'
                    }
                ]
            },
        ],
    },
];

const Example: React.FC = (props) => {
    return (
      <div>
        <Diagram data={diagramData} />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>data</td>
                        <td>
                            Array of objects with props:<br/>
                            "title": "string" - set the title of the node <br/>
                            "titleIcon": "string" - set icon before the node title <br/>
                            "text": "string" - set the text under the title <br/>
                            "onAddAction": void - set function on click add button <br/>
                            "onEditAction": void - set function on click edit button <br/>
                            "onDeleteAction": void - set function on click delete button <br/>
                            "children": Array of objects with props listed above.
                        </td>
                        <td className="text-center">-</td>
                        <td>Set diagram data.</td>
                    </tr>
                    <tr>
                        <td>height</td>
                        <td>string</td>
                        <td>"560px"</td>
                        <td>Set height to diagram component.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set class to diagram container.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default DiagramPage;
