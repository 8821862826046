import React from 'react';
import Highlight from 'react-highlight';
// TODO Temporarily removed Kendo
// import {
//     Grid, GridColumn as Column, GridContainer, Checkbox,
// } from '@jkhy/vsg-loanvantage-design-system';

// const productsGrid =
// [{
//     "ProductID": 1,
//     "ProductName": "Chai",
//     "SupplierID": 1,
//     "CategoryID": 1,
//     "QuantityPerUnit": "10 boxes x 20 bags",
//     "UnitPrice": 18.0000,
//     "UnitsInStock": 39,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 10,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 1,
//         "CategoryName": "Beverages",
//         "Description": "Soft drinks, coffees, teas, beers, and ales"
//     }
// }, {
//     "ProductID": 2,
//     "ProductName": "Chang",
//     "SupplierID": 1,
//     "CategoryID": 1,
//     "QuantityPerUnit": "24 - 12 oz bottles",
//     "UnitPrice": 19.0000,
//     "UnitsInStock": 17,
//     "UnitsOnOrder": 40,
//     "ReorderLevel": 25,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 1,
//         "CategoryName": "Beverages",
//         "Description": "Soft drinks, coffees, teas, beers, and ales"
//     }
// }, {
//     "ProductID": 3,
//     "ProductName": "Aniseed Syrup",
//     "SupplierID": 1,
//     "CategoryID": 2,
//     "QuantityPerUnit": "12 - 550 ml bottles",
//     "UnitPrice": 10.0000,
//     "UnitsInStock": 13,
//     "UnitsOnOrder": 70,
//     "ReorderLevel": 25,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 2,
//         "CategoryName": "Condiments",
//         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
//     }
// }, {
//     "ProductID": 4,
//     "ProductName": "Chef Anton's Cajun Seasoning",
//     "SupplierID": 2,
//     "CategoryID": 2,
//     "QuantityPerUnit": "48 - 6 oz jars",
//     "UnitPrice": 22.0000,
//     "UnitsInStock": 53,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 0,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 2,
//         "CategoryName": "Condiments",
//         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
//     }
// }, {
//     "ProductID": 5,
//     "ProductName": "Chef Anton's Gumbo Mix",
//     "SupplierID": 2,
//     "CategoryID": 2,
//     "QuantityPerUnit": "36 boxes",
//     "UnitPrice": 21.3500,
//     "UnitsInStock": 0,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 0,
//     "Discontinued": true,
//     "Category": {
//         "CategoryID": 2,
//         "CategoryName": "Condiments",
//         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
//     }
// }, {
//     "ProductID": 6,
//     "ProductName": "Grandma's Boysenberry Spread",
//     "SupplierID": 3,
//     "CategoryID": 2,
//     "QuantityPerUnit": "12 - 8 oz jars",
//     "UnitPrice": 25.0000,
//     "UnitsInStock": 120,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 25,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 2,
//         "CategoryName": "Condiments",
//         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
//     }
// }, {
//     "ProductID": 7,
//     "ProductName": "Uncle Bob's Organic Dried Pears",
//     "SupplierID": 3,
//     "CategoryID": 7,
//     "QuantityPerUnit": "12 - 1 lb pkgs.",
//     "UnitPrice": 30.0000,
//     "UnitsInStock": 15,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 10,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 7,
//         "CategoryName": "Produce",
//         "Description": "Dried fruit and bean curd"
//     }
// }, {
//     "ProductID": 8,
//     "ProductName": "Northwoods Cranberry Sauce",
//     "SupplierID": 3,
//     "CategoryID": 2,
//     "QuantityPerUnit": "12 - 12 oz jars",
//     "UnitPrice": 40.0000,
//     "UnitsInStock": 6,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 0,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 2,
//         "CategoryName": "Condiments",
//         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
//     }
// }, {
//     "ProductID": 9,
//     "ProductName": "Mishi Kobe Niku",
//     "SupplierID": 4,
//     "CategoryID": 6,
//     "QuantityPerUnit": "18 - 500 g pkgs.",
//     "UnitPrice": 97.0000,
//     "UnitsInStock": 29,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 0,
//     "Discontinued": true,
//     "Category": {
//         "CategoryID": 6,
//         "CategoryName": "Meat/Poultry",
//         "Description": "Prepared meats"
//     }
// }, {
//     "ProductID": 10,
//     "ProductName": "Ikura",
//     "SupplierID": 4,
//     "CategoryID": 8,
//     "QuantityPerUnit": "12 - 200 ml jars",
//     "UnitPrice": 31.0000,
//     "UnitsInStock": 31,
//     "UnitsOnOrder": 0,
//     "ReorderLevel": 0,
//     "Discontinued": false,
//     "Category": {
//         "CategoryID": 8,
//         "CategoryName": "Seafood",
//         "Description": "Seaweed and fish"
//     }
// }];

const Table: React.FC = () => {

    return <div>
        <h1 className="mb-s">Table</h1>
        <p className="mb-s">
            Tables in LoanVantage Design System exports <a href="https://www.telerik.com/kendo-react-ui/components/grid/get-started/"
            target="_blank" rel="noopener noreferrer">Kendo React Grid</a>:<br/>
                - components : <strong>Grid</strong> ,<strong>GridColumn</strong>, <strong>GridToolbar</strong> <strong>GridDetailRow</strong>, <strong>GridNoRecords</strong> <br/>
                - utility functions : <strong>process</strong>, <strong>orderBy</strong>, <strong>filterBy</strong>, <strong>groupBy</strong>, <strong>distinct</strong>
        </p>
        <p>
            <strong>Note</strong>: Always wrap <strong>Grid</strong> component in <strong>GridContainer</strong> to prevent styling issues.
        </p>

        {/* TODO Temporarily removed Kendo */}
        {/* <GridContainer>
            <Grid
                data={[...productsGrid]}
            >
                <Column field="ProductID" title="ID" />
                <Column field="ProductName" title="Name" width="250px" />
                <Column field="Category.CategoryName" title="CategoryName" />
                <Column field="UnitPrice" title="Price" />
                <Column field="UnitsInStock" title="In stock" />
                <Column
                    field="Discontinued"
                    cell={(props) => {
                        return (
                            <td>
                                <Checkbox disabled checked={props.dataItem[props.field as any]} />
                            </td>
                        )
                    }}
                />
            </Grid>
        </GridContainer> */}

        <Highlight className="React mb-l">
            {
                `
import React from 'react';
import { 
    Grid, GridColumn as Column, GridContainer, Checkbox,
 } from '@jkhy/vsg-loanvantage-design-system';

 const productsGrid =
 [{
     "ProductID": 1,
     "ProductName": "Chai",
     "SupplierID": 1,
     "CategoryID": 1,
     "QuantityPerUnit": "10 boxes x 20 bags",
     "UnitPrice": 18.0000,
     "UnitsInStock": 39,
     "UnitsOnOrder": 0,
     "ReorderLevel": 10,
     "Discontinued": false,
     "Category": {
         "CategoryID": 1,
         "CategoryName": "Beverages",
         "Description": "Soft drinks, coffees, teas, beers, and ales"
     }
 }, {
     "ProductID": 2,
     "ProductName": "Chang",
     "SupplierID": 1,
     "CategoryID": 1,
     "QuantityPerUnit": "24 - 12 oz bottles",
     "UnitPrice": 19.0000,
     "UnitsInStock": 17,
     "UnitsOnOrder": 40,
     "ReorderLevel": 25,
     "Discontinued": false,
     "Category": {
         "CategoryID": 1,
         "CategoryName": "Beverages",
         "Description": "Soft drinks, coffees, teas, beers, and ales"
     }
 }, {
     "ProductID": 3,
     "ProductName": "Aniseed Syrup",
     "SupplierID": 1,
     "CategoryID": 2,
     "QuantityPerUnit": "12 - 550 ml bottles",
     "UnitPrice": 10.0000,
     "UnitsInStock": 13,
     "UnitsOnOrder": 70,
     "ReorderLevel": 25,
     "Discontinued": false,
     "Category": {
         "CategoryID": 2,
         "CategoryName": "Condiments",
         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
     }
 }, {
     "ProductID": 4,
     "ProductName": "Chef Anton's Cajun Seasoning",
     "SupplierID": 2,
     "CategoryID": 2,
     "QuantityPerUnit": "48 - 6 oz jars",
     "UnitPrice": 22.0000,
     "UnitsInStock": 53,
     "UnitsOnOrder": 0,
     "ReorderLevel": 0,
     "Discontinued": false,
     "Category": {
         "CategoryID": 2,
         "CategoryName": "Condiments",
         "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
     }
 }
 .....
];

class Grid extends React.Component{
    render(){
        return (
            <GridContainer>
                <Grid
                    data={[...productsGrid]}
                >
                    <Column field="ProductID" title="ID" />
                    <Column field="ProductName" title="Name" width="250px" />
                    <Column field="Category.CategoryName" title="CategoryName" />
                    <Column field="UnitPrice" title="Price" />
                    <Column field="UnitsInStock" title="In stock" />
                    <Column
                        field="Discontinued"
                        cell={(props) => {
                            return (
                                <td>
                                    <Checkbox disabled checked={props.dataItem[props.field as any]} />
                                </td>
                            )
                        }}
                    />
                </Grid>
        </GridContainer>
        );
    }
}
export default Grid;
    `}
        </Highlight>

        <h2 className="mb-m">GridContainer API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native div attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native div attributes like: "style", "className", "data", etc.</td>
                    </tr>
                </tbody>
            </table>
    </div>;
}

export default Table;
