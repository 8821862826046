import React from 'react';
import Highlight from 'react-highlight';

import { Button, Card, CardSection, Row, Col, Input } from '@jkhy/vsg-loanvantage-design-system';

const CardPage: React.FC = () => {

    // TODO This structure is only for demo/test. A real structure must be devised.
    const addressForm =
        <Row>
            <Col xs="3" className="align-self-center">
                <p className="text-right mb-m">Address Line 1</p>
            </Col>
            <Col xs="3">
                <Input className="mb-m" type="text" />
            </Col>
            <Col xs="3" className="align-self-center">
                <p className="text-right mb-m">County</p>
            </Col>
            <Col xs="3">
                <Input className="mb-m" type="text" />
            </Col>
            <Col xs="3" className="align-self-center">
                <p className="text-right mb-m">Address Line 2</p>
            </Col>
            <Col xs="3">
                <Input className="mb-m" type="text" />
            </Col>
            <Col xs="3" className="align-self-center">
                <p className="text-right mb-m">County</p>
            </Col>
            <Col xs="3">
                <Input className="mb-m" type="text" />
            </Col>
        </Row>;


    return (
        <div>
            <h1 className="mb-l">Card</h1>

            <h2 className="mb-m">Card module components:</h2>
            <p className="mb-s"><strong><a href="#card">Card</a></strong> - The main wrapper component</p>
            <p className="mb-l"><strong><a href="#card-section">CardSection</a></strong> - The content wrapper that separates content.</p>

            <Card className="mb-s" actionButton={<Button icon="fal fa-trash" btnType="secondary">Delete</Button>}>
                <CardSection icon="fal fa-briefcase" title="Section 1 Title">{addressForm}</CardSection>
            </Card>
            <Card className="mb-l" actionButton={<Button icon="fal fa-trash" btnType="secondary">Delete</Button>}>
                <CardSection title="Section 1 Title">{addressForm}</CardSection>
                <CardSection icon="fal fa-home" title="Section 2 Title">{addressForm}</CardSection>
            </Card>
            <Card className="mb-s" fullWidth actionButton={
                <>
                    <Button className="mr-s" icon="fal fa-history" btnType="secondary">History</Button>
                    <Button icon="fal fa-trash" btnType="secondary">Delete</Button>
                </>
            }>
                <CardSection title="Very long long loooong title">{addressForm}</CardSection>
            </Card>

            <Highlight className="React">
                {
                    `
import React from 'react';

import { Button, Card, CardSection, Row, Col, Input } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {
    
    render() {
        return (
            <Card className="mb-s" actionButton={<Button icon="fal fa-trash" btnType="secondary">Delete</Button>}>
                <CardSection icon="fal fa-briefcase" title="Section 1 Title">{addressForm}</CardSection>
            </Card>

            <Card className="mb-l" actionButton={<Button icon="fal fa-trash" btnType="secondary">Delete</Button>}>
                <CardSection title="Section 1 Title">{addressForm}</CardSection>
                <CardSection icon="fal fa-home" title="Section 2 Title">{addressForm}</CardSection>
            </Card>

            <Card className="mb-s" fullWidth actionButton={
                <>
                    <Button className="mr-s" icon="fal fa-history" btnType="secondary">History</Button>
                    <Button icon="fal fa-trash" btnType="secondary">Delete</Button>
                </>
            }>
                <CardSection title="Very long long loooong title">{addressForm}</CardSection>
            </Card>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <p className="mb-xl">CardSection component in Card module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

            <h3 id="card">Card</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>actionButton</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets the element for actionButton.</td>
                    </tr>
                    <tr>
                        <td>fullWidth</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Makes the CardSection expand to the full width of its container.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="card-section">CardSection</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon.</td>
                    </tr>
                </tbody>
            </table>

        </div >
    )
};

export default CardPage;
