import * as React from 'react';
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';

import products from './products.json';

import './kendo_styles.css';

interface PageState {
  skip: number,
  take: number
}

const initialDataState: PageState = { skip: 0, take: 50 };

const KendoTable = () => {
  const [page, setPage] = React.useState<PageState>(initialDataState);

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  }

  return (
    <Grid
      style={{ maxHeight: '100%' }}
      data={products.slice(page.skip, page.take + page.skip)}
      skip={page.skip}
      take={page.take}
      total={products.length}
      pageable={true}
      onPageChange={pageChange}
    >
      <GridColumn field="ProductID" />
      <GridColumn field="ProductName" title="Product Name" />
      <GridColumn field="UnitPrice" title="Unit Price" />
    </Grid>
  );
}

export default KendoTable;