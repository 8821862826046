import React from 'react';

import { ReactAux, MainContainerScrollable, Button, MainContainerHeader, WizardStepsContainer, WizardStep, InfoBox, Select, } from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';

class DemoScroll4 extends React.Component {

    state = {
        currentStepIndex: 1,
        collapseDemo2: false,
        collapseDemo3: false,
        collapseDemo4: false,
        selectValue1: '1'
    }

    render() {

        const currentStepIndex = this.state.currentStepIndex;

        const previousStep = currentStepIndex > 0 ?
            <Button icon="fal fa-arrow-circle-left" btnType="secondary" onClick={() => this.setState({ currentStepIndex: currentStepIndex - 1 })}>Previous step</Button>
            : undefined;

        const nextStep = currentStepIndex === 2 ?
            undefined
            : <Button className="ml-s" icon="fal fa-arrow-circle-right" onClick={() => this.setState({ currentStepIndex: currentStepIndex + 1 })}  >Next step</Button>;


        return (
            <ReactAux>
                <MainContainerHeader>
                    <WizardStepsContainer
                        title="Credit Check"
                        previousStep={previousStep}
                        nextStep={nextStep}
                        currentStepIndex={currentStepIndex}>
                        <WizardStep title="1. Credit History" status="success" icon="fal fa-history" onClick={() => this.setState({ currentStepIndex: 0 })} />
                        <WizardStep title="2. Debt Schedule" status="warning" icon="fal fa-calendar-alt" onClick={() => this.setState({ currentStepIndex: 1 })} />
                        <WizardStep title="3. Loan Options" icon="fal fa-tasks" onClick={() => this.setState({ currentStepIndex: 2 })} />
                        <WizardStep title="4. Underwriting Ratio" status="success" icon="fal fa-tachometer-alt" onClick={() => this.setState({ currentStepIndex: 3 })} disabled />
                    </WizardStepsContainer>
                </MainContainerHeader>
                {currentStepIndex === 0 && <MainContainerScrollable><h2 className="mt-m" style={{ height: '1500px' }}>1500px div</h2></MainContainerScrollable>}
                {currentStepIndex === 1 && (
                    <MainContainerScrollable>
                        <InfoBox className="mb-s mt-m">
                            By clicking this button, you confirm that you have a Permissible Purpose for obtaining this credit report.Unauthorized access is prohibited under the Fair Credit Reporting Act (FCRA) and punishable by $2500 and/or 1 year in Federal prison per occurrence. Commercial Customers in this File.
                        </InfoBox>
                        <div className="d-flex justify-content-end mb-l">
                            <Select
                                className="mr-s width-m"
                                options={[{ value: "1", label: "Credit request" }, { value: "2", label: "Option 2" }]}
                                value={this.state.selectValue1}
                                onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                            />
                            <Button icon="fal fa-share-square" btnType="secondary">Send request</Button>
                        </div>
                        <DemoLocationPage />
                        <DemoLocationPage />
                    </MainContainerScrollable>
                )}
                {currentStepIndex === 2 && <h2 className="mt-m">3. Loan Options Content...</h2>}
            </ReactAux>
        );
    }
}

export default DemoScroll4;