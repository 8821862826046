import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, ActionSettingsForm, LinkWithIcon, Button, Select, ModalScrollableContainer,
    MainContainerScrollable, Col, Label, Row, Icon, ShowImage, Modal, Toolbar, FileUpload, ConfirmationDialog,
} from '@jkhy/vsg-loanvantage-design-system';


class DemoShowImage extends React.Component {
    private fileUploadRef: any;

    state = {
        actionContainerActiveFormIndex: -1,
        isFirstActionDisabled: false,
        isActionContainerLocked: false,
        isLockDisabled: false,
        selectValue1: '1',
        selectValue2: '1',
        modal1: false,
        modal2: false,
        file: null,
        image: null,
        show1: false,
        isDisabled: true,
    }

    handleOnChange = (files: any, ref: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                ref.onError(file);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    ref.updateProgress(file, progress);
                }, time / 100)

                setTimeout(() => {
                    clearInterval(interval);
                    this.setState({
                        file: file.file,
                        isDisabled: false,
                    });
                    ref.onSuccess(file);
                }, time)
            }
        }
    }

    openActionContainerForm(formIndex: number) {
        this.setState({ actionContainerActiveFormIndex: formIndex });
    }

    toggleActiveActionSetting() {
        this.setState({ isFirstActionDisabled: !this.state.isFirstActionDisabled });
        this.openActionContainerForm(-1);
    }

    toggleActionContainerLocked() {
        this.setState({ isActionContainerLocked: !this.state.isActionContainerLocked });
    }

    toggleLockDisabled() {
        this.setState({ isLockDisabled: !this.state.isLockDisabled });
        this.openActionContainerForm(-1);
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader>
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={['Loan Request Refi TN Bldg (File 41962)']} />
                            <ActionSettings>
                                <LinkWithIcon disabled={this.state.isFirstActionDisabled} icon="fal fa-cog"><a data-tip="Click to edit process definitions" onClick={(e) => { e.preventDefault(); this.openActionContainerForm(1) }} href="/">Robin - Commercial</a></LinkWithIcon>
                                <Button disabled={this.state.isLockDisabled} data-tip="All changes are allowed" onClick={() => this.toggleActionContainerLocked()} btnType="icon" icon={this.state.isActionContainerLocked ? 'fal fa-lock' : 'fal fa-lock-open'}></Button>
                            </ActionSettings>
                            <ActionSettingsForm isActive={this.state.actionContainerActiveFormIndex === 1}>
                                <p>Process definitions:</p>
                                <Select
                                    className="width-m"
                                    value={this.state.selectValue1}
                                    onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                                    options={[{ value: '1', label: 'Robin - Commercial' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button onClick={e => this.toggleLockDisabled()} icon="fal fa-save">Update</Button>
                                <Button onClick={e => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                            <ActionSettingsForm isActive={this.state.actionContainerActiveFormIndex === 2}>
                                <p>Process definitions:</p>
                                <Select
                                    className="width-m"
                                    value={this.state.selectValue2}
                                    onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                                    options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button icon="fal fa-save" onClick={() => this.toggleActiveActionSetting()}>Update</Button>
                                <Button onClick={e => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                        </ActionContainerHeader>
                        <Button icon="fal fa-pen">Edit</Button>
                        <Button btnType="secondary" icon="fal fa-copy">Copy</Button>
                        <Button btnType="secondary" icon="fal fa-hand-paper">Move</Button>
                        <Button btnType="secondary" icon="fal fa-sticky-note">Notes (0)</Button>
                        <Button btnType="secondary" icon="fal fa-bullseye">Leads</Button>
                        <Button btnType="secondary" icon="fal fa-user-tie">Agents</Button>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <Row className="mt-m">
                        <Col xs={4}>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">Collateral Tracking  ID</Label>
                                <Label className="mr-s"><strong>347318</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">Certificate Number</Label>
                                <Label><strong>2007088239</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">Flood Deter. Date</Label>
                                <Label><strong>7/29/2020</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">Flood Zone</Label>
                                <Label><strong>X500*</strong></Label>
                            </div>
                            <Label className="mb-m justify-content-center">SFHA</Label>
                            <Label className="mb-m justify-content-center">Comments</Label>
                            <Label className="mb-m justify-content-center">Search Results</Label>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">NFIP Comm. Name</Label>
                                <Label><strong>CAMDEN, CITY OF</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">NFIP County</Label>
                                <Label><strong>CAMDEN</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">NFIP State</Label>
                                <Label><strong>NJ</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">NFIP Comm. #</Label>
                                <Label><strong>347318</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">No NFIP Map</Label>
                                <Label><Icon icon="far fa-check" size="S" /></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">NFIP Map Number</Label>
                                <Label><strong>34007C 0036F</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">NFIP Map Panel Date</Label>
                                <Label><strong>8/17/2016</strong></Label>
                            </div>
                            <Label className="mb-m justify-content-center">LOMA/LOMR Date</Label>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">Fed. Flood Ins. Is Ava.</Label>
                                <Label><Icon icon="far fa-check" size="S" /></Label>
                            </div>
                            <Label className="mb-m justify-content-center">Fed. Flood Ins. Is Not Ava.</Label>
                            <Label className="mb-m justify-content-center">CBRA Or OPA</Label>
                            <Label className="mb-m justify-content-center">CBRA/OPA Date</Label>
                            <div className="d-flex justify-content-center mb-m">
                                <Label className="mr-s">Regular Program</Label>
                                <Label><Icon icon="far fa-check" size="S" /></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <ShowImage
                                    onClickAddBtn={() => this.setState({ modal1: true, isDisabled: true })}
                                    image={this.state.image ? { src: this.state.image || '' } : undefined}
                                    helperBtns={<>
                                        <Button className="mb-s" btnType="icon" icon="fal fa-search-plus" data-tip="Zoom" onClick={() => this.setState({ modal2: true })} />
                                        <ConfirmationDialog
                                            show={this.state.show1}
                                            render={() => <>
                                                <p className="text-center mb-m">Are you sure?</p>
                                                <Toolbar leftSide={<>
                                                    <Button onClick={() => this.setState({ image: null, show1: false })} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                    <Button onClick={() => this.setState({ show1: false })} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                } />
                                            </>}
                                            onClose={() => this.setState({ show1: false })}
                                        >
                                            <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => this.setState({ show1: true })} />
                                        </ConfirmationDialog>
                                    </>}
                                />
                            </div>
                            <Modal title="Add Image" isVisible={this.state.modal1} size="S" onClose={() => this.setState({ modal1: false })}>
                                <Toolbar
                                    className="mb-m"
                                    leftSide={
                                        <>
                                            <Button className="mr-s" icon="fal fa-save" disabled={this.state.isDisabled} onClick={() => { this.setState({ modal1: false, image: URL.createObjectURL(this.state.file), }); }}>Save</Button>
                                            <Button icon="fal fa-times-circle" btnType="secondary" onClick={() => this.setState({ modal1: false })}>Cancel</Button>
                                        </>
                                    }
                                />
                                <FileUpload
                                    className="mb-xl"
                                    fileUploadRef={(ref: any) => this.fileUploadRef = ref}
                                    isMultiple={false}
                                    maxFileSizeMB={2}
                                    onChange={(files: any) => {
                                        this.handleOnChange(files, this.fileUploadRef);
                                    }}
                                />
                            </Modal>

                            <Modal title="Sprinkles Holdings LLC Photo" isVisible={this.state.modal2} size="L" onClose={() => this.setState({ modal2: false })}>
                                <ModalScrollableContainer>
                                    <div className="text-center">
                                        <img className="img-fit" src={this.state.image || undefined} alt="View" />
                                    </div>
                                </ModalScrollableContainer>
                            </Modal>
                        </Col>
                    </Row>
                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoShowImage;