import React from 'react';

import {
    ReactAux, Main, MainContainer, MainContainerRemainingPart, LeftSideBar, TopSection, ScrollableContainer,
    Button, WizardNavStepsControls, ActionContainer, ActionContainerCrumbs,
    ActionContainerHeader, MainContainerHeader, LeftBarCrumbs, LinkWithIcon, Label, ButtonGroup,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';

class DemoWorkPage4 extends React.Component {

    state = {
        activeBtn: 0,
    }

    render() {
        return (
            <ReactAux>
                <DemoHeader />
                <Main>
                    <LeftSideBar>
                        <TopSection>
                            <LeftBarCrumbs
                                className="mb-m lv-side-bar-neggative-margin"
                                links={[<LinkWithIcon icon="fal fa-arrow-left" iconPlace="left">
                                    <a href="/">Back to Work Queue</a>
                                </LinkWithIcon>]}
                            />

                            <ScrollableContainer>
                                <Button className="mb-m" icon="fal fa-file-alt" btnType="secondary">Edit File</Button>
                                <div className="d-flex mb-s">
                                    <Label className="mr-s"><strong>File Number</strong></Label>
                                    <Label>20698</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label className="mr-s"><strong>File Type</strong></Label>
                                    <Label>Loan Request</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label className="mr-s"><strong>File Name</strong></Label>
                                    <Label>New Line of Credit</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label className="mr-s"><strong>Current Exposure</strong></Label>
                                    <Label>$10,527</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label className="mr-s"><strong>Proposed Changes</strong></Label>
                                    <Label>$5,000,000</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label className="mr-s"><strong>Plus Pending Approvals</strong></Label>
                                    <Label>$0</Label>
                                </div>
                                <div className="d-flex mb-m">
                                    <Label className="mr-s"><strong>Total Proposed Exposure</strong></Label>
                                    <Label>$15,527,000</Label>
                                </div>

                                <WizardNavStepsControls
                                    className="mb-m"
                                    saveBtn={<Button icon="fal fa-save" btnType="secondary">Save and proceed</Button>}
                                    previousStep={<a href="/">Prev</a>}
                                    nextStep={<a href="/">Skip</a>}
                                />
                                <p className="text-small"><strong>27 more</strong> files remaining</p>
                            </ScrollableContainer>
                        </TopSection>
                    </LeftSideBar>

                    <MainContainer>
                        <MainContainerHeader>
                            <ActionContainer singleLine>
                                <ActionContainerHeader>
                                    <ActionContainerCrumbs crumbs={[
                                        <div className="d-flex align-items-center">
                                            <ButtonGroup>
                                                <Button btnType={this.state.activeBtn === 1 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 1 })}>File</Button>
                                                <Button btnType={this.state.activeBtn === 2 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 2 })}>Facility</Button>
                                                <Button disabled btnType={this.state.activeBtn === 3 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 3 })}>Presentation Report</Button>
                                            </ButtonGroup>
                                            <p className="text-muted ml-s">No presentation report is on file.</p>
                                        </div>
                                    ]} />
                                </ActionContainerHeader>
                                <div className="d-flex align-items-center ml-l">
                                    <Button disabled btnType="secondary" icon="fal fa-arrow-circle-left">Prev</Button>
                                    <p className="mr-s"><strong>Facility 1 of 2</strong></p>
                                    <Button btnType="secondary" icon="fal fa-arrow-circle-right">Next</Button>
                                </div>
                            </ActionContainer>
                        </MainContainerHeader>

                        {/* <MainContainerScrollable></MainContainerScrollable> */}
                        <MainContainerRemainingPart>
                            <iframe
                                title="demo-pdf"
                                src={`${process.env.PUBLIC_URL}/img/anatomy-of-large-scale.pdf`}

                                // Important
                                height="100%"
                                // Important
                                width="100%"
                            />
                        </MainContainerRemainingPart>
                    </MainContainer>
                </Main>
            </ReactAux>
        );
    }
}

export default DemoWorkPage4;