import React from 'react';
import Highlight from 'react-highlight';

import {
    ValidationMessage
} from '@jkhy/vsg-loanvantage-design-system';


const ValidationMessagePage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">ValidationMessage</h1>

            <p className="mb-l">Component that helps to display validation message with current type.</p>

            <ValidationMessage message="This field is required." type="error" />
            <ValidationMessage message="This field is required." type="info" />
            <ValidationMessage message="This field is required." type="warning" />
            <ValidationMessage message="This field is required." type="success" />

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    render() {
        return (
            <div>
                <ValidationMessage message="This field is required." type="error" />
                <ValidationMessage message="This field is required." type="info" />
                <ValidationMessage message="This field is required." type="warning" />
                <ValidationMessage message="This field is required." type="success" />
            </div>
        );
    }
}

export default Example;
        `}
            </Highlight>


            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>message</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the message.</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>'error' | 'info' | 'warning' | 'success'</td>
                        <td>'error'</td>
                        <td>Set the style/color of message.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default ValidationMessagePage;
