import React from 'react';
import Highlight from 'react-highlight';
import { Button } from '@jkhy/vsg-loanvantage-design-system';


const ButtonPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Button</h1>
            <p className="mb-l">The Button component extends the native Button element.</p>

            <h3 className="mb-s">Button types</h3>
            <p className="mb-l">
                There are several types of buttons : "primary", "secondary", "narrow", "icon".
            </p>

            <div className="d-flex mb-m">
                <Button btnType="primary" icon='fal fa-check' className="mr-s" >Primary</Button>
                <Button btnType="secondary" icon='fal fa-check' className="mr-s">Secondary</Button>
                <Button btnType="narrow" className="mr-s" data-tip="Narrow button">Narrow</Button>
                <Button btnType="icon" icon='fal fa-file-alt' className='mr-s' data-tip="File" />
                <Button isSuccess btnType="icon" icon='fal fa-file-alt' className='mr-s' data-tip="File" />
                <Button isDanger btnType="icon" icon='fal fa-file-alt' className='mr-s' data-tip="File" />
            </div>

            <h3 className="mb-s">Disabled Button</h3>
            <div className="d-flex mb-xl">
                <Button btnType="primary" icon='fal fa-check' className="mr-s" disabled>Primary</Button>
                <Button btnType="secondary" icon='fal fa-check' className="mr-s" disabled>Secondary</Button>
                <Button btnType="narrow" className="mr-s" data-tip="Narrow button" disabled>Narrow</Button>
                <Button btnType="icon" icon='fal fa-file-alt' className="mr-s" disabled data-tip="File" />
                <Button isSuccess btnType="icon" icon='fal fa-file-alt' className="mr-s" disabled data-tip="File" />
            </div>

            <p className="mb-xl"><strong>Important: </strong>Buttons with <i>btnType</i> "narrow" always require tooltip.</p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Button } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    return (
      <div>
        <Button btnType="primary" icon='fal fa-check' >Primary</Button>
        <Button btnType="secondary" icon='fal fa-check' >Secondary</Button>
        <Button btnType="narrow" className="mr-s" data-tip="Narrow button">Narrow</Button>
        <Button btnType="icon" icon='fal fa-file-alt' data-tip="File"/>
        <Button isSuccess btnType="icon" icon='fal fa-file-alt' data-tip="File" />
        <Button isDanger btnType="icon" icon='fal fa-file-alt' data-tip="File" />

        // Disabled buttons
        <Button btnType="primary" icon='fal fa-check' disabled >Primary</Button>
        <Button btnType="secondary" icon='fal fa-check' disabled >Secondary</Button>
        <Button btnType="narrow" className="mr-s" data-tip="Narrow button" disabled>Narrow</Button>
        <Button btnType="icon" icon='fal fa-file-alt' disabled data-tip="File" />
        <Button isSuccess btnType="icon" icon='fal fa-file-alt' disabled data-tip="File" />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>btnType</td>
                        <td>"primary", "secondary", "icon"</td>
                        <td>"primary"</td>
                        <td>Sets different style to the button.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon to the button.</td>
                    </tr>
                    <tr>
                        <td>isSuccess</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Shows a success icon. Available only when <i>btnType</i> is "icon".</td>
                    </tr>
                    <tr>
                        <td>isDanger</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Makes icon to be 'danger' color.</td>
                    </tr>
                    <tr>
                        <td>all native button attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native button attributes like: "type", "disabled", "name", etc.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ButtonPage;
