import React from 'react';
import Highlight from 'react-highlight';

import {
    HelpBox, LinkWithIcon
} from '@jkhy/vsg-loanvantage-design-system';

const HelpBoxPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">HelpBox</h1>

            <p className="mb-l">Container for information text and links with icon.</p>

            <HelpBox className="mb-l" infoText="Info text">
                <LinkWithIcon icon="fal fa-play-circle"><a href="/">Play tutorial</a></LinkWithIcon>
                <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
            </HelpBox>

            <HelpBox className="mb-l" infoText="Info text Info text Info text Info text text">
                <LinkWithIcon icon="fal fa-play-circle"><a href="/">Play tutorial</a></LinkWithIcon>
                <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
                <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
            </HelpBox>

            <HelpBox className="mb-l" infoText="Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options?Do you want to learn more about Loan Options?Do you want to learn more about Loan Options?">
                <LinkWithIcon icon="fal fa-play-circle"><a href="/">Play tutorial</a></LinkWithIcon>
                <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
            </HelpBox>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { HelpBox } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    render() {
        return (
            <div>
                <HelpBox infoText="Info text">
                    <LinkWithIcon icon="fal fa-play-circle"><a href="/">Play tutorial</a></LinkWithIcon>
                    <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
                </HelpBox>

                <HelpBox infoText="Info text Info text Info text Info text text">
                    <LinkWithIcon icon="fal fa-play-circle"><a href="/">Play tutorial</a></LinkWithIcon>
                    <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
                    <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
                </HelpBox>

                <HelpBox infoText="Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options? Do you want to learn more about Loan Options?Do you want to learn more about Loan Options?Do you want to learn more about Loan Options?">
                    <LinkWithIcon icon="fal fa-play-circle"><a href="/">Play tutorial</a></LinkWithIcon>
                    <LinkWithIcon icon="fal fa-book-open"><a href="/">Read help</a></LinkWithIcon>
                </HelpBox>
            </div>
        );
    }
}

export default Example;
        `}
            </Highlight>


            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>infoText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the info text.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td>'fal fa-question-circle'</td>
                        <td>Sets the HelpBox icon.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default HelpBoxPage;
