import React from 'react';
import Highlight from 'react-highlight';

import {

} from '@jkhy/vsg-loanvantage-design-system';


const ActivityChecklist: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">ActivityChecklist</h1>
            <p className="mb-l">
                ActivityChecklist is used in the BottomSection of LeftSideBar. See example <a href='/components' title="ActivityChecklist example">here</a>.
            </p>

            <h2>ActivityChecklist components:</h2>
            <p className="mb-s"><strong><a href="#api-checklist-actions">ChecklistActions</a></strong> - Wraps Checklist actions.</p>
            <p className="mb-s"><strong><a href="#api-checklist-content">ChecklistContent</a></strong> - Wraps all ChecklistItem elements.</p>
            <p className="mb-s"><strong><a href="#api-checklist-item">ChecklistItem</a></strong> - You can nest ChecklistItems. Parent element will be visualized differently and will set separator</p>
            <p className="mb-s"><strong><a href="#api-checklist-content">AttachmentsContent</a></strong> - Wraps all Attachment components and will set separator.</p>
            <p className="mb-xl"><strong><a href="#api-attachment">Attachment</a></strong> - Represents a single attachment item in the list.</p>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import {
    TopSection, ScrollableContainer, BottomSection, Tabs,
    TabList, TabItem, TabItemTitle, TabContent, TabContentItem, Checkbox, Button,
    ChecklistActions, ChecklistContent, ChecklistItem, AttachmentsContent,
    Attachment,
} from '@jkhy/vsg-loanvantage-design-system';

const getActivityChecklistDataTip = (isChecked:any) =>{
    return isChecked ? 'Completed' : 'Opened'
}

class Example extends React.Component {
     
    state = {
        activityChecklistTabIndex: 0,
        attachments: attachments.map((at:any,i:any) => {at['key']=i; return at}),
        activityChecklistStatuses:[
            {isChecked: false},{isChecked: true},{isChecked: false},{isChecked: false},{isChecked: false}
         ]
    }; 

    onAddNewAttachment = () =>{
        const attachmentsCopy = this.state.attachments.map((at:any,i:any) => {at['key']=i; return at});
        const element = { 
            key:[attachmentsCopy.length + 1], animation:true , 
            title: <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }} >NEW</a>, 
            renderBtn: <Button btnType="icon" icon='fal fa-times' data-tip="Some text" />
        };
        
        this.setState({
            attachments: [
                element,
                ...attachmentsCopy,
            ]
        })
    }

    onUpdateAnimation = (i :any) => {
        const attachmentsCopy = this.state.attachments;

        if (attachmentsCopy[possition].animation) {
            attachmentsCopy[possition].animation = false;

            this.setState({
                attachments: attachmentsCopy
            });
        }
    }

    onChangeActivitiChecklistStatus= (index:any)=>{
        const copyStatuses = [...this.state.activityChecklistStatuses];
        copyStatuses[index].isChecked = !copyStatuses[index].isChecked;
        this.setState({
            activityChecklistStatuses: copyStatuses
        });
    }

    render() {
        return (
            <LeftSideBar>
            <TopSection>
                ...
            </TopSection>

            <BottomSection>
            <h2 className="mb-s text-muted">Activity Checklist</h2>
            <Tabs currentlyActiveTabIndex={this.state.activityChecklistTabIndex} 
                onTabIndexChange={(index: number) => this.setState({ activityChecklistTabIndex: index })}
            >
                <TabList>
                    <TabItem>
                        <TabItemTitle>Checklist</TabItemTitle>
                    </TabItem>
                    <TabItem>
                        <TabItemTitle>Attachments</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent>
                    <TabContentItem>
                        <ChecklistActions title="Show only:">
                            <Checkbox name="parents-check">Parents</Checkbox>
                            <Checkbox name="unfinished-tasks-check">Unfinished tasks</Checkbox>
                        </ChecklistActions>
                    </TabContentItem>
                    <TabContentItem>
                        // For the demo only. Here should be fileupload component.
                        <Button className="mb-m" icon="fal fa-file-alt" 
                            onClick={this.onAddNewAttachment}
                        >
                            Select files
                        </Button>
                        <p>‘picture.jpg’ is uploaded</p>
                    </TabContentItem>
                </TabContent>
            </Tabs>
            <ScrollableContainer>
                {this.state.activityChecklistTabIndex === 0 &&
                    <ChecklistContent>
                    <ChecklistItem
                        title="Group task name"
                        assignedTo="BL Approval Team 3"
                        renderBtn={<Button btnType="icon" icon='fal fa-file-alt' data-tip="Some Action" data-place="top" />}
                        renderStatus={<Checkbox 
                            data-tip={ getActivityChecklistDataTip(this.state.activityChecklistStatuses[0].isChecked) }
                            data-place="top"
                            checked={this.state.activityChecklistStatuses[0].isChecked}
                            onChange={()=>this.onChangeActivitiChecklistStatus(0)}
                         />}
                        onClick={() => console.log('click span')}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                     >

                    <ChecklistItem
                        title="Subtask name 1"
                        assignedTo="BL Approval Team 3"
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        renderStatus={<Checkbox
                            data-tip={ getActivityChecklistDataTip(this.state.activityChecklistStatuses[1].isChecked) }
                            data-place="top"
                            checked={this.state.activityChecklistStatuses[1].isChecked}
                            onChange={() => this.onChangeActivitiChecklistStatus(1)}
                        />}
                        onClick={() => console.log('click span 2')}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                    />

                    <ChecklistItem
                        title="Subtask name 2"
                        assignedTo="BL Approval Team 3"
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        renderStatus={<Checkbox disabled checked data-tip="Winged" data-place="top" />}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                    />

                    <ChecklistItem title="Subtask name 3" assignedTo="BL Approval Team 3"
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        renderStatus={<Checkbox disabled data-tip="Opened" data-place="top" />}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                    />

                    <ChecklistItem
                        title={<a href='/' onClick={(e) => { e.preventDefault(); console.log('click href 1') }}>Subtask Link</a>}
                        assignedTo={<a href='/' onClick={(e) => { e.preventDefault(); console.log('click href 2') }}>Approval Team 2</a>}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        renderStatus={<Icon size="S" icon="far fa-ban" data-tip="Cancelled" data-place="top" />}
                    />
                </ChecklistItem>

                <ChecklistItem
                    title={<a href="/" onClick={(e) => { e.preventDefault(); console.log('link parent') }}>Group task name</a>}
                    assignedTo="BL Approval Team 3"
                    renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                    renderStatus={<Icon size="S" icon="far fa-check-double" data-tip="N/A" data-place="top" />}
                    contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                >
                    <ChecklistItem
                        title="Subtask name 1"
                        assignedTo="BL Approval Team 3"
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        renderStatus={<Checkbox 
                            data-tip={ getActivityChecklistDataTip( this.state.activityChecklistStatuses[2].isChecked ) }
                            data-place="top"
                            onChange={() => this.onChangeActivitiChecklistStatus(2)}
                            checked={this.state.activityChecklistStatuses[2].isChecked}
                        />}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                    />

                    <ChecklistItem
                        title="Subtask name 2"
                        assignedTo="BL Approval Team 3"
                        renderStatus={<Checkbox
                                data-tip={ getActivityChecklistDataTip(this.state.activityChecklistStatuses[3].isChecked) }
                                data-place="top"
                                onChange={() => this.onChangeActivitiChecklistStatus(3)}
                                checked={this.state.activityChecklistStatuses[3].isChecked}
                        />}
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                    />

                    <ChecklistItem
                        title="Subtask name 3"
                        assignedTo="BL Approval Team 3"
                        renderStatus={<Checkbox 
                                data-tip={ getActivityChecklistDataTip(this.state.activityChecklistStatuses[4].isChecked) }
                                data-place="top"
                                onChange={() => this.onChangeActivitiChecklistStatus(4)}
                                checked={this.state.activityChecklistStatuses[4].isChecked}
                        />}
                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                    />

                    </ChecklistItem>

                    </ChecklistContent>
                }

                {this.state.activityChecklistTabIndex === 1 && 
                    <AttachmentsContent 
                    >
                        {this.state.attachments.map((at, i) => 
                            <Attachment key={at.key}  {...at}  onUpdateAnimation={()=>this.onUpdateAnimation(i)}/>)
                        }
                    </AttachmentsContent>
                }
            </ScrollableContainer>
        </BottomSection>
        </LeftSideBar>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3 id="api-checklist-actions">ChecklistActions</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title for actions group.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets class to the wrapper.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-checklist-content">ChecklistContent and AttachmentsContent</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets class to the wrapper.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-checklist-item">ChecklistItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string || JSX.Element(only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Sets the title.</td>
                    </tr>
                    <tr>
                        <td>assignedTo</td>
                        <td>string || JSX.Element(only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Sets the assignedTo.</td>
                    </tr>
                    <tr>
                        <td>onClick</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Sets the onClick to title and assignedTo when they are string.</td>
                    </tr>
                    <tr>
                        <td>renderBtn</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets the button.</td>
                    </tr>
                    <tr>
                        <td>renderStatus</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets the checkbox or icon.</td>
                    </tr>
                    <tr>
                        <td>contextMenuItems</td>
                        <td>Arry of JSX.Elements (only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Sets the context menu.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets class to the wrapper.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-attachment">Attachment</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string || JSX.Element(only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Sets the title.</td>
                    </tr>
                    <tr>
                        <td>renderBtn</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets the button.</td>
                    </tr>
                    <tr>
                        <td>animation</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets the animation.</td>
                    </tr>
                    <tr>
                        <td>onUpdateAnimation</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Update animation prop on component render.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
};

export default ActivityChecklist;
