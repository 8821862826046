import React from 'react';
import Highlight from 'react-highlight';
import { Input } from '@jkhy/vsg-loanvantage-design-system';


const InputPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Input</h1>
            <p className="mb-l">The Input component extends the native input element.</p>

            <div className="mb-xl">
                <Input className="mb-s" />
                <Input placeholder={'Input Placeholder'} className="mb-s" />
                <Input value="Input Disabled" name="input" disabled className="mb-s" onChange={() => { }} />
                <Input name="input-icon" className="mb-s" onChange={() => { }} icon='fal fa-file-alt' />
            </div>


            <Highlight className="React">
                {
                    `
import React from 'react';
import { Input } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    return (
      <div>
            <Input name="input" type="text" onChange={() => {} }/>
            <Input name="inputPlaceholeder" type="text" placeholder={'Input Placeholder'}  onChange={() => {} }/>
            <Input name="inputDisabled" type="text" value="Input Disabled" disabled onChange={() => {} } />
            <Input name="input-icon" className="mb-s" onChange={() => { }} icon='fal fa-file-alt' />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td className='text-center'>string</td>
                        <td className='text-center'>-</td>
                        <td>Set icon</td>
                    </tr>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "type", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default InputPage;
