import React from 'react';
import Highlight from 'react-highlight';
import { ReactAux } from '@jkhy/vsg-loanvantage-design-system';

const Testing: React.FC = () => {
    return (
        <ReactAux>
            <h1 className="mb-s">Testing</h1>
            <p className="mb-l">
                In order to make UI testing easier, all components in LoanVantage Design System provide a special property - <strong><i>dataUI</i></strong>.
                It helps querying the component elements in the DOM, with a generic approach in the whole design system.
                The value of the <strong><i>dataUI</i></strong> property is always set as the value of a unique <strong><i>data-ui</i></strong> HTML attribute
                to the wrapping element of a given component.
            </p>

            <Highlight className="mb-l">
                {`
import React from 'react';
import { Input, Button } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    // Adding the testing prop to components:
    return (
        <div>
            <Input type="text" dataUI="testInput"/>
            <Button icon='fal fa-check' dataUI="testButton">Test button</Button>
        </div>
    );
}

// The rendered HTML output:
<div>
    <input type="text" class="lv-form-control-input" data-ui="testInput">
    <button type="button" class="lv-form-control-btn lv-form-control-btn-primary" data-ui="testButton">...</button>
</div>

`}
            </Highlight>
        </ReactAux>
    )
}

export default Testing;
