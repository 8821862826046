import React, { useState } from 'react';
import Highlight from 'react-highlight';

import { WorkQueue, WorkQueueItem } from '@jkhy/vsg-loanvantage-design-system';

type WorkQueueItemColor = 'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink';
const WorkQueuePage: React.FC = () => {

    const [itemOneColor, setItemOneColor] = useState<WorkQueueItemColor>('default');
    const [itemTwoColor, setItemTwoColor] = useState<WorkQueueItemColor>('blue');
    const [itemThreeColor, setItemThreeColor] = useState<WorkQueueItemColor>('green');
    const [itemFourColor, setItemFourColor] = useState<WorkQueueItemColor>('yellow');
    const [itemFiveColor, setItemFiveColor] = useState<WorkQueueItemColor>('orange');
    const [itemSixColor, setItemSixColor] = useState<WorkQueueItemColor>('pink');
    const [itemSevenColor, setItemSevenColor] = useState<WorkQueueItemColor>('default');
    const [itemEightColor, setItemEightColor] = useState<WorkQueueItemColor>('default');
    const [itemNineColor, setItemNineColor] = useState<WorkQueueItemColor>('default');
    const [itemTenColor, setItemTenColor] = useState<WorkQueueItemColor>('default');
    const [itemElevenColor, setItemElevenColor] = useState<WorkQueueItemColor>('default');
    const [itemTwelveColor, setItemTwelveColor] = useState<WorkQueueItemColor>('default');

    return (
        <div>
            <h1 className="mb-s">WorkQueue</h1>

            <p className="mb-l">
                The WorkQueue is a container for specific WorkQueue items, which are
                designed for resizable dashboard sections. These items adapt to the size of their
                wrapper container and expose color customization options.
            </p>

            <h2 className="mb-m">WorkQueue module components:</h2>
            <p className="mb-s"><strong>WorkQueue</strong> - The wrapper for all single items.</p>
            <p className="mb-xl"><strong><a href="#api-work-queue-item">WorkQueueItem</a></strong> - A separate flexible and customizable card element.</p>



            <div style={{ padding: '20px 0' }}>
                <WorkQueue>
                    <WorkQueueItem
                        onColorChange={color => setItemOneColor(color)}
                        title="Logan photography" 
                        details="2290"
                        color={itemOneColor}
                        onClick={() => console.log('Click on WorkQueueItem 1')}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemTwoColor(color)}
                        title="Invitation WQ"
                        details="23232"
                        color={itemTwoColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemThreeColor(color)}
                        title="Allan A Morgan"
                        details="23232"
                        color={itemThreeColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemFourColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemFourColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemFiveColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemFiveColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemSixColor(color)}
                        title="Invitation WQ"
                        details="23232"
                        color={itemSixColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemSevenColor(color)}
                        title="Allan A Morgan"
                        details="23232"
                        color={itemSevenColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemEightColor(color)}
                        title="Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan"
                        details="2290"
                        color={itemEightColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemNineColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemNineColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemTenColor(color)}
                        title="Invitation WQ"
                        details="23232"
                        color={itemTenColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemElevenColor(color)}
                        title="Allan A Morgan"
                        details="23232"
                        color={itemElevenColor}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemTwelveColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemTwelveColor}
                    />
                </WorkQueue>
            </div>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { WorkQueue, WorkQueueItem } from '@jkhy/vsg-loanvantage-design-system';

type WorkQueueItemColor = 'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink';

class Example extends React.Component {

    const [itemOneColor, setItemOneColor] = useState<WorkQueueItemColor>('default');
    const [itemTwoColor, setItemTwoColor] = useState<WorkQueueItemColor>('blue');
    const [itemThreeColor, setItemThreeColor] = useState<WorkQueueItemColor>('green');
    const [itemFourColor, setItemFourColor] = useState<WorkQueueItemColor>('yellow');
    const [itemFiveColor, setItemFiveColor] = useState<WorkQueueItemColor>('orange');
    const [itemSixColor, setItemSixColor] = useState<WorkQueueItemColor>('pink');
    const [itemSevenColor, setItemSevenColor] = useState<WorkQueueItemColor>('default');
    const [itemEightColor, setItemEightColor] = useState<WorkQueueItemColor>('default');
    const [itemNineColor, setItemNineColor] = useState<WorkQueueItemColor>('default');
    const [itemTenColor, setItemTenColor] = useState<WorkQueueItemColor>('default');
    const [itemElevenColor, setItemElevenColor] = useState<WorkQueueItemColor>('default');
    const [itemTwelveColor, setItemTwelveColor] = useState<WorkQueueItemColor>('default');

    render() {
        return (
            <WorkQueue>
                <WorkQueueItem
                    onColorChange={color => setItemOneColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemOneColor}
                    onClick={() => console.log('Click on WorkQueueItem 1')}
                />
                <WorkQueueItem
                    onColorChange={color => setItemTwoColor(color)}
                    title="Invitation WQ"
                    details="23232"
                    color={itemTwoColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemThreeColor(color)}
                    title="Allan A Morgan"
                    details="23232"
                    color={itemThreeColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemFourColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemFourColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemFiveColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemFiveColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemSixColor(color)}
                    title="Invitation WQ"
                    details="23232"
                    color={itemSixColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemSevenColor(color)}
                    title="Allan A Morgan"
                    details="23232"
                    color={itemSevenColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemEightColor(color)}
                    title="Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan"
                    details="2290"
                    color={itemEightColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemNineColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemNineColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemTenColor(color)}
                    title="Invitation WQ"
                    details="23232"
                    color={itemTenColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemElevenColor(color)}
                    title="Allan A Morgan"
                    details="23232"
                    color={itemElevenColor}
                />
                <WorkQueueItem
                    onColorChange={color => setItemTwelveColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemTwelveColor}
                />
            </WorkQueue>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            
            <h3 id="api-work-queue-item">WorkQueueItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title text in the center of the WorkQueueItem card.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the additional text below the title, wrapped in parenthesis.</td>
                    </tr>
                    <tr>
                        <td>color</td>
                        <td>'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink'</td>
                        <td>'default'</td>
                        <td>Sets the color style of the current WorkQueueItem.</td>
                    </tr>
                    <tr>
                        <td>onColorChange</td>
                        <td>(color: 'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink') {'=>'} void</td>
                        <td>null</td>
                        <td>Callback to execute when the color of the WorkQueueItem is changed in the colors dropdown.</td>
                    </tr>
                    <tr>
                        <td>onClick</td>
                        <td>(e?: React.MouseEvent) {'=>'} void</td>
                        <td>null</td>
                        <td>Callback, executed after click on the WorkQueueItem (ignoring clicks on the dropdown icon and its list items).</td>
                    </tr>
                    <tr>
                        <td>dataID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Attaches a data-id attribute with the passed in value.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
};

export default WorkQueuePage;
