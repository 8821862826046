import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button, Header, HeaderButtonIcon, DropdownMenu, MenuItem, HeaderSearch, SearchForm,
    SearchInput, SearchMenu, Select, SearchFilters, Radio, SearchResults, SearchResultsList,
    SearchResultsItem, SearchDetails, Icon, SearchDetailsList, SearchDetailsItem, SearchResultsFooter,
    HeaderMenu, FacilityMenuItem, FacilityList, FacilityItem, CollateralItem, FacilityFooter,
    HeaderRightSection, HeaderInfo, ThemeChanger, themeChangerHelpers, Modal, Toolbar, Tabs, TabList, TabItem, TabItemTitle,
    TabContent, TabContentItem, SimpleTableContainer, Badge, Checkbox, UploadedFileRow, Label,
    LinkWithIcon, ModalScrollableContainer,
} from '@jkhy/vsg-loanvantage-design-system';

const searchSelectOptions = [{ value: "1", label: "Default" }, { value: "2", label: "File" }, { value: "3", label: "Party Starts With" }, { value: "4", label: "Party Contains" }, { value: "5", label: "Property Address" }];

type DemoHeaderProps = {
    onThemeChange?(theme: any): void
};

class DemoHeader extends React.Component<DemoHeaderProps> {
    private inputFileRef: any;

    state = {
        isOpenIndex: [
            false, false, false, false, false, false, false, false, false, false, false
        ],
        hasSearchResults: false,
        searchInputValue: '',
        searchSelectValue: searchSelectOptions[0].value,
        searchRadioValue: '',
        headerSearchActive: false,
        theme: undefined,
        isHidden: true,
        isHidden2: true,
        isHidden3: true,
        spreadListModalVisible: false,
        spreadListTabIndex: 0,
        mySettingsTabIndex: 0,
        uploadsModalVisible: false,
        modalSettings: false,
        image: null,
    }

    componentDidMount() {
        this.setState({ theme: 'navy' });
    }

    handleOnChangeImage = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const targetFile = e.target.files[0];

            this.setState({ image: URL.createObjectURL(targetFile), });
        }
    }

    onImageUploadClick = () => {
        if (this.inputFileRef) this.inputFileRef.click();
    };

    onToggleDropDown = (index: any) => {
        const { isOpenIndex } = this.state;
        if (isOpenIndex[index]) {
            isOpenIndex[index] = false;
        } else {
            isOpenIndex.forEach((el, i) => {
                if (i === index) {
                    isOpenIndex[i] = true;
                } else {
                    isOpenIndex[i] = false;
                }
            });
        }

        this.setState({
            isOpenIndex: isOpenIndex,
        })
    }

    onSearchInputChange = (event: any) => {
        const newVal = event.target.value;
        this.setState({ searchInputValue: newVal });
    }

    onSearchSelectChange = (obj: any) => {
        const newVal = obj.value;
        this.setState({ searchSelectValue: newVal });
    }

    onSearchRadioChange = (event: any) => {
        const newVal = event.target.value;
        this.setState({ searchRadioValue: newVal });
    }

    onSearchSubmit = () => {
        const searchValue = this.state.searchInputValue;
        if (searchValue.trim().length > 0) {
            this.setState({ hasSearchResults: true })
        } else {
            this.setState({ hasSearchResults: false })
        }
    }

    activateHeaderSearch() {
        this.setState({ headerSearchActive: true });
    }

    deactivateHeaderSearch() {
        this.setState({ headerSearchActive: false });
    }

    setTheme = (theme: any) => {
        this.setState({ theme: theme.name });
        const { onThemeChange } = this.props;
        if (onThemeChange) onThemeChange(theme);
    }

    setSpreadListModalVisibility(showModal: boolean) {
        if (this.state.spreadListModalVisible !== showModal) {
            this.setState({ spreadListModalVisible: showModal });
        }
    }

    setSpreadListTabIndex(newIndex: number) {
        if (this.state.spreadListTabIndex !== newIndex) {
            this.setState({ spreadListTabIndex: newIndex });
        }
    }

    setMySettingsTabIndex(newIndex: number) {
        if (this.state.mySettingsTabIndex !== newIndex) {
            this.setState({ mySettingsTabIndex: newIndex });
        }
    }

    setUploadsModalVisibility(showModal: boolean) {
        if (this.state.uploadsModalVisible !== showModal) {
            this.setState({ uploadsModalVisible: showModal });
        }
    }

    render() {
        const { theme, isHidden, isHidden2, isHidden3 } = this.state;

        // These files are programatically created only for the sake of the demo.
        const examplePDF = new File(['example pdf'], 'file.pdf', { type: 'application/pdf' })
        const exampleJPG = new File(['example jpg image'], 'picture02.jpg', { type: 'image/jpeg' })
        const exampleDoc = new File([''], 'file.doc', { type: 'application/msword' })

        return (
            <>
                <Header>
                    <Link to="/home">
                        <HeaderButtonIcon icon="fal fa-home" />
                    </Link>
                    <DropdownMenu
                        onToggleDropdownMenu={() => this.onToggleDropDown(9)}
                        isOpen={this.state.isOpenIndex[9]}
                        icon='fal fa-bars'
                    >
                        <MenuItem title={'Hello, VSG'} avatar={{ src: 'https://www.w3schools.com/howto/img_avatar2.png', alt: 'avatarTest' }} noHoverEffect />
                        <MenuItem title={'My Settings'} icon={{ name: 'fal fa-cog' }} onClick={() => this.setState({ modalSettings: true })} />
                        <MenuItem title={'Sign Out'} icon={{ name: 'fal fa-sign-out-alt' }} />
                        <MenuItem title={'Set Up'} />
                        <MenuItem title={'Approval Log'} />
                        <MenuItem title={'Dashboard'} />
                        <MenuItem title={'FPC'} />
                        <MenuItem title={'Change Theme'} icon={{ name: 'fal fa-palette', color: 'text-primary' }}>
                            {themeChangerHelpers.THEMES.map((t, i) => {
                                return <MenuItem
                                    key={i}
                                    className={`lv-theme-menu-${t.base}`}
                                    title={t.title}
                                    icon={{ name: `fas fa-${theme === t.name ? 'check-circle' : 'circle'}`, color: `lv-theme-${t.name}-primary` }}
                                    onClick={() => this.setTheme(t)}
                                />
                            })}
                        </MenuItem>
                    </DropdownMenu>
                    <HeaderButtonIcon icon="fal fa-cogs" />
                    <HeaderSearch
                        isActive={this.state.headerSearchActive}
                        onActivate={() => this.activateHeaderSearch()}
                        onDeactivate={() => this.deactivateHeaderSearch()}
                    >
                        <SearchForm>
                            <SearchInput
                                placeholder="Search"
                                value={this.state.searchInputValue}
                                onChange={e => this.onSearchInputChange(e)}
                                onSearchSubmit={() => this.onSearchSubmit()}
                            />
                            <SearchMenu>
                                <Select value={this.state.searchSelectValue} onChange={(obj: any) => this.onSearchSelectChange(obj)} options={searchSelectOptions} noMenuPortal />
                                <Button onClick={() => this.onSearchSubmit()} icon="fal fa-search">Search</Button>
                                <SearchFilters title="Search in:">
                                    <Radio className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Customers"} onChange={e => this.onSearchRadioChange(e)} value="Customers">Customers</Radio>
                                    <Radio className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Prospects"} onChange={e => this.onSearchRadioChange(e)} value="Prospects">Prospects</Radio>
                                    <Radio className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Leads"} onChange={e => this.onSearchRadioChange(e)} value="Leads">Leads</Radio>
                                    <Radio className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Tenants"} onChange={e => this.onSearchRadioChange(e)} value="Tenants">Tenants</Radio>
                                    <Radio name="customer-type" type="radio" checked={this.state.searchRadioValue === "All"} onChange={e => this.onSearchRadioChange(e)} value="All">All</Radio>
                                </SearchFilters>
                            </SearchMenu>
                        </SearchForm>
                        {
                            this.state.hasSearchResults &&
                            <SearchResults title={'65 results found for "Joro"'}>
                                <SearchResultsList>
                                    <SearchResultsItem>
                                        <SearchDetails title="test" icon={<Icon size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} />
                                            <SearchDetailsItem officer="Officer" render={<a href="./components">(12240) File Name</a>} icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem>
                                        <SearchDetails title="test" icon={<Icon size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            <p>test 1</p>
                                            <p>test 2</p>
                                            <p>test 3</p>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem>
                                        <SearchDetails render={<><a href="./components" onClick={() => console.log('click')}>Party</a>, some info</>} icon={<Icon size="S" icon="fal fa-user"></Icon>}>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem title="(12240) File Name" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} officer="Officer" />
                                            <SearchDetailsItem officer="Officer" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} render={<a href="./components">(12240) File Name</a>} />
                                            <SearchDetailsItem title="Party name" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem>
                                        <SearchDetails title="test" icon={<Icon size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} />
                                            <SearchDetailsItem officer="Officer" render={<a href="./components">(12240) File Name</a>} icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem>
                                        <SearchDetails title="test" icon={<Icon size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            <p>test 1</p>
                                            <p>test 2</p>
                                            <p>test 3</p>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem>
                                        <SearchDetails render={<><a href="./components" onClick={() => console.log('click')}>Party</a>, some info</>} icon={<Icon size="S" icon="fal fa-user"></Icon>}>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem title="(12240) File Name" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} officer="Officer" />
                                            <SearchDetailsItem officer="Officer" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} render={<a href="./components">(12240) File Name</a>} />
                                            <SearchDetailsItem title="Party name" icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                </SearchResultsList>
                                <SearchResultsFooter>
                                    Didn't find the party you are searching for?
                                    <Button className="ml-s" icon="fal fa-plus" btnType="secondary">Add party</Button>
                                </SearchResultsFooter>
                            </SearchResults>
                        }
                    </HeaderSearch>

                    <HeaderMenu>
                        <DropdownMenu title="Facility" onToggleDropdownMenu={() => this.onToggleDropDown(10)} isOpen={this.state.isOpenIndex[10]} >
                            <FacilityMenuItem>
                                <FacilityList>
                                    <FacilityItem
                                        price="9,000"
                                        loan={{ element: <a href="/">7777-2-L1111</a>, isActive: true }}
                                        collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    >
                                        <CollateralItem render={() => <a href="/">White house</a>} />
                                        <CollateralItem render={() => <a href="/">White house</a>} />
                                        <CollateralItem render={() => <a href="/">White house</a>} />
                                    </FacilityItem>
                                    <FacilityItem
                                        price="8,500,000"
                                        loan={{ element: <a href="/">26CApprovalRequest</a> }}
                                        collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    />
                                    <FacilityItem
                                        price="21,266,000"
                                        loan={{ element: <a href="/">7777-6 - 26CApprovalRequest</a> }}
                                        collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    >
                                        <CollateralItem render={() => <a href="/">L111 Collat for CAR reports</a>} />
                                        <CollateralItem render={() => <a href="/">Titled -Motor Vehicles-Personal</a>} />
                                        <CollateralItem render={() => <a href="/">CAP Rate Test</a>} />
                                        <CollateralItem render={() => <a href="/">Test</a>} />
                                        <CollateralItem render={() => <a href="/">Titled -Motor Vehicles-Personal</a>} />
                                        <CollateralItem render={() => <a href="/">CAP Rate Test</a>} />
                                    </FacilityItem>
                                </FacilityList>
                                <FacilityFooter>
                                    <Button btnType="secondary" icon="fal fa-plus">Add Facility</Button>
                                </FacilityFooter>
                            </FacilityMenuItem>
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Collateral'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(0)}
                            isOpen={this.state.isOpenIndex[0]}
                        >
                            <MenuItem title={'Level 1'} icon={{ name: 'fal fa-cog' }} isActive={true} />
                            <MenuItem title={'Level 1'} icon={{ name: 'fal fa-sign-out-alt', color: 'text-primary' }} />
                            <MenuItem title={'Dropdown Level 1'} >
                                <MenuItem title={'Dropdown Level 2'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem title={'Level 3'} render={() => <span>LAST</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 2'} separator={true} />
                                {
                                    Array(20).fill('').map((el, i) => <MenuItem key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                }

                                <MenuItem title={'Dropdown Level 2'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem title={'Level 3'} render={() => <span>LAST</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                                <MenuItem title={'Level 3'} render={() => <span>LAST</span>} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>Render Link</a>} />
                            <MenuItem title={'Dropdown Level 1'} >
                                <MenuItem title={'Level 2'} separator={true} />
                                <MenuItem title={'Dropdown Level 2'}>
                                    <MenuItem title={'Level 3'} />
                                    <MenuItem title={'Level 3'} />
                                    <MenuItem title={'Level 3'} render={() => <span>Render Span</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>Render Link</a>} />
                            <MenuItem title={'Dropdown Level 1'} >
                                <MenuItem title={'Level 2'} separator={true} />
                                <MenuItem title={'Dropdown Level 2'}>
                                    <MenuItem title={'Level 3'} />
                                    <MenuItem title={'Level 3'} />
                                    <MenuItem title={'Level 3'} render={() => <span>Render Span</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>Render Link</a>} />
                            <MenuItem title={'Dropdown Level 1'} >
                                <MenuItem title={'Level 2'} separator={true} />
                                <MenuItem title={'Dropdown Level 2'}>
                                    <MenuItem title={'Level 3'} />
                                    <MenuItem title={'Level 3'} />
                                    <MenuItem title={'Level 3'} render={() => <span>Render Span</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem title={'Dropdown Level 1'} >
                                <MenuItem title={'Level 2'} separator={true} />
                                <MenuItem title={'Dropdown Level 2'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem key={i} title={'Level 2'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem title={'Level 3'} render={() => <span>LAST</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem title={'Dropdown Level 2'}>
                                {
                                    Array(9).fill('').map((el, i) => <MenuItem key={i} title={'Level 2'} render={() => <span>Render Span</span>} />)
                                }
                                <MenuItem title={'Dropdown Level 3'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem title={'Level 3'} render={() => <span>LAST 3</span>} />
                                </MenuItem>
                                <MenuItem title={'Level 3'} render={() => <span>LAST</span>} />
                            </MenuItem>
                            {
                                Array(5).fill('').map((el, i) => <MenuItem key={i} title={'Level 1'} render={() => <span>Render Span</span>} />)
                            }
                            <MenuItem title={'Dropdown Level 2'}>
                                {
                                    Array(4).fill('').map((el, i) => <MenuItem key={i} title={'Level 2'} render={() => <span>Render Span</span>} />)
                                }
                                <MenuItem title={'Level 3'} render={() => <span>LAST 3</span>} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>LAST LEVEL 1</a>} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Risk'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(1)}
                            isOpen={this.state.isOpenIndex[1]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Tracking'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(2)}
                            isOpen={this.state.isOpenIndex[2]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Documents'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(3)}
                            isOpen={this.state.isOpenIndex[3]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Decision'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(4)}
                            isOpen={this.state.isOpenIndex[4]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Presentation'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(5)}
                            isOpen={this.state.isOpenIndex[5]}
                        >
                            <MenuItem
                                onMouseLeave={() => this.setState({ isHidden: true })}
                                render={() => <div className={`lv-custom-presentation-menu-item${!isHidden ? ' lv-custom-show-btn' : ''}`}>
                                    <a className="lv-link-styles" href="./">Test Blank Narrative</a>
                                    <Button onClick={() => this.setState({ isHidden: !isHidden })} btnType="icon" icon="far fa-ellipsis-v" data-tip="Some text" />
                                </div>}>
                                <MenuItem isHidden={isHidden} title={'Download plain'} />
                                <MenuItem isHidden={isHidden} title={'Reset to template'} />
                            </MenuItem>
                            <MenuItem
                                onMouseLeave={() => this.setState({ isHidden2: true })}
                                render={() => <div className={`lv-custom-presentation-menu-item${!isHidden2 ? ' lv-custom-show-btn' : ''}`}>
                                    <a className="lv-link-styles" href="./">E-mail from Dave</a>
                                    <Button onClick={() => this.setState({ isHidden2: !isHidden2 })} btnType="icon" icon="far fa-ellipsis-v" data-tip="Some text" />
                                </div>}>
                                <MenuItem isHidden={isHidden2} title={'Download plain'} />
                                <MenuItem isHidden={isHidden2} title={'Reset to template'} />
                            </MenuItem>
                            <MenuItem
                                onMouseLeave={() => this.setState({ isHidden3: true })}
                                render={() => <div className={`lv-custom-presentation-menu-item${!isHidden3 ? ' lv-custom-show-btn' : ''}`}>
                                    <a className="lv-link-styles" href="./">RSB Word Narrative</a>
                                    <Button onClick={() => this.setState({ isHidden3: !isHidden3 })} btnType="icon" icon="far fa-ellipsis-v" data-tip="Some text" />
                                </div>}>
                                <MenuItem isHidden={isHidden3} title={'Download plain'} />
                                <MenuItem isHidden={isHidden3} title={'Reset to template'} />
                            </MenuItem>
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Approval'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(6)}
                            isOpen={this.state.isOpenIndex[6]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Links'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(7)}
                            isOpen={this.state.isOpenIndex[7]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Reports'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(8)}
                            isOpen={this.state.isOpenIndex[8]}
                        >
                            <MenuItem title={'Level 1'} />
                            <MenuItem title={'Level 1'} >
                                <MenuItem title={'Level 2'} />
                                <MenuItem title={'Level 2'} />
                            </MenuItem>
                            <MenuItem title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            render={() => <span>Link</span>}
                        />
                    </HeaderMenu>

                    <HeaderRightSection>
                        <HeaderButtonIcon icon="fal fa-file-upload" notification={{ title: "3", type: 'danger' }} onClick={() => this.setUploadsModalVisibility(true)} />
                        <HeaderButtonIcon icon="fal fa-file-spreadsheet" notification={{ title: '', type: 'danger' }} onClick={() => this.setSpreadListModalVisibility(true)} />
                        <HeaderButtonIcon icon="fal fa-stamp" notification={{ title: "6", type: 'danger' }} />
                        <HeaderInfo sync="03-01" user="IBS\Stoil" version="3.19.25" />
                    </HeaderRightSection>
                </Header>
                <ThemeChanger theme={theme} />
                <Modal
                    isVisible={this.state.spreadListModalVisible}
                    title="Spread List"
                    onClose={() => this.setSpreadListModalVisibility(false)}>
                    <Toolbar
                        leftSide={
                            <>
                                <Button className="mr-s" icon="fal fa-check-double" type="button">Check in Selected</Button>
                                <Button btnType="secondary" type="button">Cancel</Button>
                            </>
                        } />
                    <Tabs
                        onTabIndexChange={(index: number) => this.setSpreadListTabIndex(index)}
                        currentlyActiveTabIndex={this.state.spreadListTabIndex}
                    >
                        <TabList>
                            <TabItem>
                                <TabItemTitle>Spread Status list</TabItemTitle>
                            </TabItem>
                            <TabItem>
                                <TabItemTitle>Spread Checkout list</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent>
                            <TabContentItem>
                                <SimpleTableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Party</th>
                                                <th>Period</th>
                                                <th style={{ width: '200px' }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Anthony Testcase</td>
                                                <td>6/9/2020</td>
                                                <td><Badge type="warning" icon="fal fa-exclamation-triangle" fullWidth>Needs Attention</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>Cupcakeds on Wheels</td>
                                                <td>6/8/2018</td>
                                                <td><Badge type="info" icon="fal fa-file-search" fullWidth>Sent to Review</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>David Test</td>
                                                <td>6/9/2020</td>
                                                <td><Badge type="success" icon="fal fa-check" fullWidth>Recognised Successful</Badge></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </TabContentItem>

                            <TabContentItem>
                                <p className="mb-m">The following parties have spreads that you have open in edit mode and thus may not be edited by others. Select the customers you wish to Check In:</p>
                                <SimpleTableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '46px' }}><Checkbox checked onChange={() => { }} /></th>
                                                <th>Description</th>
                                                <th>Last Checked</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>Anthony Testcase</td>
                                                <td>6/9/2020 10:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>Cupcakeds on Wheels</td>
                                                <td>3/9/2020 11:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>David Test</td>
                                                <td>5/9/2020 1:03:16 PM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>Anthony Testcase</td>
                                                <td>6/9/2020 10:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>Cupcakeds on Wheels</td>
                                                <td>3/9/2020 11:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>David Test</td>
                                                <td>5/9/2020 1:03:16 PM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox checked onChange={() => { }} /></td>
                                                <td>Sprinkles Holdings LLC</td>
                                                <td>6/9/2020 9:17:45 AM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>

                <Modal isVisible={this.state.uploadsModalVisible} title="Upload Document" onClose={() => this.setUploadsModalVisibility(false)}>
                    <SimpleTableContainer>
                        <table>
                            <thead>
                                <tr>
                                    <th>Context</th>
                                    <th>Document and Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Required Doc</td>
                                    <td><UploadedFileRow uploadedFile={{ file: examplePDF, uploadSuccessful: true }} /></td>
                                </tr>
                                <tr>
                                    <td>Site Visit</td>
                                    <td><UploadedFileRow uploadedFile={{ file: exampleJPG, progress: 90 }} /></td>
                                </tr>
                                <tr>
                                    <td>Attachments</td>
                                    <td><UploadedFileRow uploadedFile={{ file: exampleDoc, error: 'Has error' }} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </Modal>

                <Modal isVisible={this.state.modalSettings} title="My Settings" onClose={() => this.setState({ modalSettings: false })}>
                    <Toolbar
                        className="mb-m"
                        leftSide={
                            <>
                                <Button className="mr-s" icon="fal fa-save">Save</Button>
                                <Button icon="fal fa-times-circle" btnType="secondary">Cancel</Button>
                            </>
                        }
                        rightSide={
                            <>
                                <Button className="mr-s" icon="fal fa-key" btnType="secondary">Set Pin</Button>
                                <Button icon="fal fa-lock-alt" btnType="secondary">Change Password</Button>
                            </>
                        }
                    />
                    <Tabs
                        onTabIndexChange={(index: number) => this.setMySettingsTabIndex(index)}
                        currentlyActiveTabIndex={this.state.mySettingsTabIndex}
                    >
                        <TabList>
                            <TabItem>
                                <TabItemTitle>User Settings</TabItemTitle>
                            </TabItem>
                            <TabItem>
                                <TabItemTitle>Mobile Settings</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent>
                            <TabContentItem>
                                <ModalScrollableContainer>
                                    <div className="d-flex mb-m">
                                        <div className="section-container flex-1 mr-m">
                                            <h2 className="mb-m">Profile Picture</h2>
                                            <hr className="mb-m" />
                                            <div className="d-flex align-items-center">
                                                <img
                                                    className="mr-m object-fit-cover"
                                                    style={{ width: '95px', height: '95px', borderRadius: '50%' }}
                                                    src={this.state.image ? this.state.image as any : `${process.env.PUBLIC_URL}/img/empty-avatar.svg`}
                                                    alt="Avatar" />
                                                <div>
                                                    {!this.state.image && <Button
                                                        icon="fal fa-upload"
                                                        btnType="secondary"
                                                        onClick={this.onImageUploadClick}>
                                                        Upload picture
                                                    </Button>}
                                                    {this.state.image && <>
                                                        <Button
                                                            className="mb-s"
                                                            icon="fal fa-pen"
                                                            btnType="secondary"
                                                            onClick={this.onImageUploadClick}>
                                                            Change picture
                                                        </Button>
                                                        <Button
                                                            icon="fal fa-trash"
                                                            btnType="secondary"
                                                            onClick={() => this.setState({ image: null })}>
                                                            Remove picture
                                                        </Button>
                                                    </>}
                                                </div>
                                                <input
                                                    className="d-none"
                                                    ref={(ref) => this.inputFileRef = ref}
                                                    id="image-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.handleOnChangeImage}
                                                />
                                            </div>
                                        </div>
                                        <div className="section-container flex-1">
                                            <h2 className="mb-m">User Info</h2>
                                            <hr className="mb-m" />
                                            <div className="d-flex mb-s">
                                                <Label className="mr-m">Login ID</Label>
                                                <Label><strong>IBS/Joro</strong></Label>
                                            </div>
                                            <div className="d-flex mb-s">
                                                <Label className="mr-m">First Name</Label>
                                                <Label><strong>Joro</strong></Label>
                                            </div>
                                            <div className="d-flex">
                                                <Label className="mr-m">Last Name</Label>
                                                <Label><strong>Georgiev</strong></Label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-container mb-m">
                                        <h2 className="mb-m">Download LoanVantage Add-Ins</h2>
                                        <hr className="mb-m" />
                                        <LinkWithIcon className="d-flex mb-m" icon="fal fa-download" iconPlace="left">
                                            <a href="/">Microsoft Word LoanVantage Add-In 1.0.312</a>
                                        </LinkWithIcon>
                                        <LinkWithIcon className="d-flex" icon="fal fa-download" iconPlace="left">
                                            <a href="/">Microsoft Word LoanVantage Add-In 1.0.200</a>
                                        </LinkWithIcon>
                                    </div>
                                    <div className="d-flex">
                                        <div className="section-container flex-1 mr-m">
                                            <h2 className="mb-m">Notifications</h2>
                                            <hr className="mb-m" />
                                            <Checkbox className="d-block mb-m">Approval</Checkbox>
                                            <Checkbox className="d-block mb-m">Site Visit</Checkbox>
                                            <Checkbox className="d-block">Chat</Checkbox>
                                        </div>
                                        <div className="section-container flex-1">
                                            <h2 className="mb-m">Order of Financial Periods From Left</h2>
                                            <hr className="mb-m" />
                                            <div className="mb-m">
                                                <Radio className="d-block" name="radio-3">Oldest To Most Recent (E.g. 2019, 2020, 2021)</Radio>
                                                <Radio className="d-block" name="radio-3">Recent To Oldest (E.g. 2021, 2020, 2019)</Radio>
                                            </div>
                                        </div>
                                    </div>
                                </ModalScrollableContainer>
                            </TabContentItem>

                            <TabContentItem>
                                <ModalScrollableContainer>
                                    <div className="section-container mb-m">
                                        <h2 className="mb-m">Mobile Registration</h2>
                                        <hr className="mb-m" />
                                        <Toolbar
                                            className="mb-m"
                                            leftSide={
                                                <Button icon="fal fa-plus">Add Registration</Button>
                                            }
                                        />
                                        <SimpleTableContainer>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '54px' }}>Enable</th>
                                                        <th style={{ width: '42px' }}></th>
                                                        <th style={{ width: '42px' }}></th>
                                                        <th>Registration Code</th>
                                                        <th>Complete Date</th>
                                                        <th>Device Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><Checkbox checked onChange={() => { }} /></td>
                                                        <td><Button btnType="icon" icon="fal fa-trash" data-tip="Delete Registration" /></td>
                                                        <td><Button btnType="icon" icon="fal fa-envelope" data-tip="Re-Send E-mail" /></td>
                                                        <td>8c3d2a29a4</td>
                                                        <td>05/23/21</td>
                                                        <td>Samsung SM-G988BB Android 11</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox checked onChange={() => { }} /></td>
                                                        <td><Button btnType="icon" icon="fal fa-trash" data-tip="Delete Registration" /></td>
                                                        <td><Button btnType="icon" icon="fal fa-envelope" data-tip="Re-Send E-mail" /></td>
                                                        <td>5c42h7ah7b</td>
                                                        <td>05/23/21</td>
                                                        <td>Samsung SM-N986B Android 10</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox checked onChange={() => { }} /></td>
                                                        <td><Button btnType="icon" icon="fal fa-trash" data-tip="Delete Registration" /></td>
                                                        <td><Button btnType="icon" icon="fal fa-envelope" data-tip="Re-Send E-mail" /></td>
                                                        <td>7g5t3d58h3</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox checked onChange={() => { }} /></td>
                                                        <td><Button btnType="icon" icon="fal fa-trash" data-tip="Delete Registration" /></td>
                                                        <td><Button btnType="icon" icon="fal fa-envelope" data-tip="Re-Send E-mail" /></td>
                                                        <td>3c52h7ah7f</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </SimpleTableContainer>
                                    </div>
                                    <div className="section-container">
                                        <h2 className="mb-m">Mobile Notifications</h2>
                                        <hr className="mb-m" />
                                        <Checkbox className="d-block mb-m">Approval</Checkbox>
                                        <Checkbox className="d-block">Chat</Checkbox>
                                    </div>
                                </ModalScrollableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>
            </>
        );
    }
}

export default DemoHeader;
