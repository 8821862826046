import React from 'react';

import { ReactAux, Collapse, MainContainerScrollable, } from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';

class DemoScroll3 extends React.Component {

    state = {
        collapseDemo2: false,
        collapseDemo3: false,
        collapseDemo4: false,
    }

    render() {

        return (
            <ReactAux>
                <MainContainerScrollable>
                    <h1 className="mb-xl">MainContainerScrollable without Static elements</h1>
                    <div style={{ height: '400px' }}>
                        <h3>400px div</h3>
                    </div>
                    <DemoLocationPage />
                    <Collapse
                        title="Colapse 2"
                        expanded={this.state.collapseDemo2}
                        onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                    >
                        <p>Colapse 2 Content...</p>
                    </Collapse>
                    <Collapse
                        title="Colapse 3"
                        expanded={this.state.collapseDemo3}
                        onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                    >
                        <p>Colapse 3 Content...</p>
                    </Collapse>
                    <Collapse
                        title="Colapse 4"
                        expanded={this.state.collapseDemo4}
                        onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                    >
                        <p>Colapse 4 Content...</p>
                    </Collapse>
                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoScroll3;