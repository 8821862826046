import React, { useState, useEffect, useRef } from 'react';

import {
    MainContainerHeader, ReactAux, Collapse,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, MainContainerScrollable, SimpleTableContainer, Select,
    Checkbox, Tooltip, Input, Modal, ModalScrollableContainer,
    Row, Col, ConfirmationDialog, Icon, Badge,
} from '@jkhy/vsg-loanvantage-design-system';


const DemoTable = (props: any) => {
    const [rowsState, setRowsState] = useState([
        {
            isEditMode: false,
            inputInitValue: 'Method',
            inputValue: 'Method',
            inputRef: useRef(),
            isChecked: true,
            isCheckedInit: true,
        },
        {
            isEditMode: false,
            inputInitValue: '',
            inputValue: '',
            inputRef: useRef(),
            isChecked: false,
            isCheckedInit: false,
        },
    ] as any);

    const [focusedIndex, setFocusedIndex] = useState();

    const onEdit = (rowIndex: any) => {
        const currentState = [...rowsState];
        currentState[rowIndex] = {
            ...rowsState[rowIndex],
            isEditMode: !rowsState[rowIndex].isEditMode,
        }

        setRowsState([
            ...currentState
        ]);

        setFocusedIndex(rowIndex)
    }

    const onSave = (rowIndex: any) => {
        const currentState = [...rowsState];
        currentState[rowIndex] = {
            ...rowsState[rowIndex],
            isEditMode: !rowsState[rowIndex].isEditMode,
            inputInitValue: rowsState[rowIndex].inputValue
        }

        setRowsState([
            ...currentState
        ]);
    }

    const onInputChange = (e: any, rowIndex: any) => {
        const currentState = [...rowsState];
        currentState[rowIndex] = {
            ...rowsState[rowIndex],
            inputValue: e.target.value,
        }

        setRowsState([
            ...currentState
        ]);
    }

    const onCancelEdit = (rowIndex: any) => {
        const currentState = [...rowsState];
        currentState[rowIndex] = {
            ...rowsState[rowIndex],
            inputValue: rowsState[rowIndex].inputInitValue,
            isChecked: rowsState[rowIndex].isCheckedInit,
            isEditMode: false,
        }

        setRowsState([
            ...currentState
        ]);
    }

    const onCheck = (e: any, rowIndex: any) => {
        const currentState = [...rowsState];
        currentState[rowIndex] = {
            ...rowsState[rowIndex],
            isChecked: e.target.checked,
        }

        setRowsState([
            ...currentState
        ]);
    }

    useEffect(() => {
        Tooltip.rebuild();
    });

    useEffect(() => {
        rowsState.forEach((row: any, index: any) => {
            if (focusedIndex === index && row.isEditMode) {
                row.inputRef.current.focus();
            }
        });
    }, [rowsState, focusedIndex]);

    return (
        <SimpleTableContainer>
            <table>
                <thead>
                    <tr>
                        <th style={{ minWidth: '78px' }} className='white-space-nowrap text-center'>Edit</th>
                        <th>Personal Party</th>
                        <th>Results</th>
                        <th style={{ width: 1 }} className='white-space-nowrap'>Manual Override</th>
                        <th className="width-full">Method/Notes</th>
                    </tr>

                </thead>
                <tbody>
                    <tr className={rowsState[0].isEditMode ? 'background-color-select' : ''} >
                        <td className="text-center">
                            {!rowsState[0].isEditMode && <Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => { onEdit(0) }} />}
                            {rowsState[0].isEditMode &&
                                <div className="d-flex">
                                    <Button btnType="icon" className="mr-s" icon="fal fa-save" data-tip="Save" onClick={() => { onSave(0) }} />
                                    <Button btnType="icon" icon="fal fa-times-circle" data-tip="Cancel" onClick={() => { onCancelEdit(0) }} />
                                </div>
                            }
                        </td>
                        <td className="white-space-nowrap">Elizabeth K. Adams</td>
                        <td className="white-space-nowrap">Manually Validated</td>
                        <td className="text-center"><Checkbox disabled={!rowsState[0].isEditMode} checked={rowsState[0].isChecked} onChange={(e) => { onCheck(e, 0) }} /></td>
                        <td>
                            {rowsState[0].isEditMode ?
                                <Input value={rowsState[0].inputValue} onChange={(e) => { onInputChange(e, 0) }} ref={rowsState[0].inputRef} /> :
                                rowsState[0].inputValue
                            }
                        </td>
                    </tr>
                    <tr className={rowsState[1].isEditMode ? 'background-color-select' : ''}>
                        <td className="text-center">
                            {!rowsState[1].isEditMode && <Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => { onEdit(1) }} />}
                            {rowsState[1].isEditMode &&
                                <div className="d-flex">
                                    <Button btnType="icon" className="mr-s" icon="fal fa-save" data-tip="Save" onClick={() => { onSave(1) }} />
                                    <Button btnType="icon" icon="fal fa-times-circle" data-tip="Cancel" onClick={() => { onCancelEdit(1) }} />
                                </div>
                            }
                        </td>
                        <td className="white-space-nowrap">Sprinkles Holdings LLC</td>
                        <td className="white-space-nowrap">Manually Validated</td>
                        <td className="text-center"><Checkbox disabled={!rowsState[1].isEditMode} checked={rowsState[1].isChecked} onChange={(e) => { onCheck(e, 1) }} /></td>
                        <td>
                            {rowsState[1].isEditMode ?
                                <Input value={rowsState[1].inputValue} onChange={(e) => { onInputChange(e, 1) }} ref={rowsState[1].inputRef} /> :
                                rowsState[1].inputValue
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </SimpleTableContainer>
    )
}

const DemoTableSecond = (props: any) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);

    return (
        <>
            <SimpleTableContainer>
                <table>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>IP Address</th>
                            <th>Action</th>
                            <th>Party Name</th>
                            <th className="text-center">Status</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-tip="04/15/21 11:07:56	">04/15/21 11:07:56</td>
                            <td>40.84.59.100</td>
                            <td>Ovation</td>
                            <td>Elizabeth K. Adams</td>
                            <td className="text-no-break">
                                <Icon className="mr-s text-success" icon="fal fa-check-circle" size="M" />
                                Success
                            </td>
                            <td>
                                <span onClick={() => setIsVisible2(true)} className="lv-link-styles">Party Match was found for Mark Jones ID</span>
                            </td>
                        </tr>
                        <tr>
                            <td data-tip="04/15/21 11:07:57">04/15/21 11:07:57</td>
                            <td>40.84.59.100</td>
                            <td>Manual Status Change</td>
                            <td>Sprinkles Holdings LLC</td>
                            <td className="text-no-break">
                                <Icon className="mr-s" icon="fal fa-info-circle" size="M" />
                                No pull
                            </td>
                            <td>
                                Never Pull Credit Check
                            </td>
                        </tr>
                        <tr>
                            <td data-tip="04/15/21 11:07:57	">04/15/21 11:07:57	</td>
                            <td>40.84.59.100</td>
                            <td>Loan Request Created</td>
                            <td>Carlos Testcase</td>
                            <td className="text-no-break">
                                <Icon className="mr-s text-danger" icon="fal fa-times-circle" size="M" />
                                <span onClick={() => setIsVisible(true)} className="lv-link-styles">Error</span>
                            </td>
                            <td>
                                Score 693 : Product Used - Eperian
                            </td>
                        </tr>
                    </tbody>
                </table>
            </SimpleTableContainer>


            <Modal title="Error details" isVisible={isVisible} size="M" onClose={() => setIsVisible(false)}>
                <ModalScrollableContainer>
                    <SimpleTableContainer>
                        <table>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>708</td>
                                    <td>The following exception occured while validating field: _street of class: com.experian.preciseid.castor.CurrentAddress: objects of this type must match the following regular expression: {`([a-zA-Z0-9# \\-'$ / \\.]{1,60}){1}{file: [not available]; line: 1; column: 1239}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </ModalScrollableContainer>
            </Modal>

            <Modal title="Details on ’High Risk NAICS, Less than 5 years in Biz, etc’" isVisible={isVisible2} size="M" onClose={() => setIsVisible2(false)}>
                <ModalScrollableContainer>
                    <SimpleTableContainer>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '1px' }}>(</th>
                                    <th>Condition</th>
                                    <th style={{ width: '1px' }}>)</th>
                                    <th className="text-no-break">Logical Operator</th>
                                    <th className="text-center">Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>[Borrower] - Business NAICS Code IN 454311 Oil Distributors (heating oil);531110 Real Estate Lessors - Malls ;552110 Financial Institutions;813410 Civic Organziations</td>
                                    <td></td>
                                    <td className="text-center">OR</td>
                                    <td className="text-no-break">
                                        <Badge fullWidth type="danger" icon="fal fa-times-circle">False</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>[Purpose/Product] - Months In Business {'<'} 60</td>
                                    <td></td>
                                    <td className="text-center">OR</td>
                                    <td className="text-no-break">
                                        <Badge fullWidth type="success" icon="fal fa-check">True</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>[Borrower] - Is Owner US Citizen = No</td>
                                    <td></td>
                                    <td className="text-center">OR</td>
                                    <td className="text-no-break">
                                        <Badge fullWidth type="danger" icon="fal fa-times-circle">False</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>[Question] - Reg O Question = Yes</td>
                                    <td></td>
                                    <td className="text-center">OR</td>
                                    <td className="text-no-break">
                                        <Badge fullWidth type="danger" icon="fal fa-times-circle">False</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Question - CREDIT: Do you agree to have your credit pu... = No</td>
                                    <td></td>
                                    <td className="text-center">OR</td>
                                    <td className="text-no-break">
                                        <Badge fullWidth type="danger" icon="fal fa-times-circle">False</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>[Borrower] - Owner Identity Check IN Validated; Not Validated; Manually Validated</td>
                                    <td></td>
                                    <td className="text-center">OR</td>
                                    <td className="text-no-break">
                                        <Badge fullWidth type="danger" icon="fal fa-times-circle">False</Badge>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </ModalScrollableContainer>
            </Modal>
        </>
    )
}

const options = [
    { value: '1', label: 'On Hold' },
    { value: '2', label: 'Other' }
] as any;

const DemoApplicationCIBA = (props: any) => {

    const [collapsOne, setCollapsOne] = useState(true);
    const [collapsTwo, setCollapsTwo] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [status, setStatus] = useState(options[0]);
    const [statusInit, setStatusInit] = useState(options[0]);
    const [note, setNote] = useState("");
    const [noteInit, setNoteInit] = useState("");
    const [isReviewMode, setIsRevieMode] = useState(false);
    const [isCurrentAppMode, setIsCurrentAppMode] = useState(false);
    const [isEditCurrentMode, setIsEditCurrentMode] = useState(false)


    const [revCollOne, setColOne] = useState(true);
    const [revCollTwo, setColTwo] = useState(true);
    const [revCollThree, setColThree] = useState(true);
    const [revCollFour, setColFour] = useState(true);
    const [revCollFive, setColFive] = useState(false);
    const [revCollSix, setColSix] = useState(false);
    const [revCollSeven, setColSeven] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);


    const onEdit = () => {
        setIsEdit(true);
    };
    const onCalnceEdit = () => {
        setIsEdit(false);
        setNote(noteInit);
        setStatus(statusInit);
    };

    const onSave = () => {
        setStatusInit(status);
        setNoteInit(note);
        setIsEdit(false);
    };

    const onChangeStatus = (obj: any) => {
        setStatus(obj);
    }

    const onChangeNote = (e: any) => {
        setNote(e.target.value);
    }

    const onCancelEditCurrentMode = () => {
        setIsEditCurrentMode(false)
    }

    const onPrint = () => {
        window.print();
    }

    let crumbs = ['Application CIBA Vision Corp'] as any;
    let actionBtns = <>
        <Button btnType="secondary" icon="fal fa-eye" onClick={() => { setIsRevieMode(true) }}>Application Review</Button>
        <Button btnType="secondary" icon="fal fa-pen" onClick={() => { setIsCurrentAppMode(true) }}>Current Application</Button>
    </>


    if (isReviewMode) {
        crumbs = [<a onClick={(e) => { e.preventDefault(); setIsRevieMode(false) }} href="/">Application CIBA Vision Corp</a>, 'Application Review'];
        actionBtns = <Button btnType="primary" icon="fal fa-print" onClick={onPrint}>Print</Button>
    }

    if (isCurrentAppMode) {
        crumbs = [<a onClick={(e) => { e.preventDefault(); setIsCurrentAppMode(false) }} href="/">Application CIBA Vision Corp</a>, 'Current Application'];
        actionBtns = isEditCurrentMode ? <>
            <Button btnType="primary" icon="fal fa-save" onClick={onCancelEditCurrentMode}>Save</Button>
            <Button btnType="secondary" icon="fal fa-times-circle" onClick={onCancelEditCurrentMode}>Cancel</Button>
            <ConfirmationDialog
                show={showConfirm}
                render={() => <>
                    <p className="mb-m">Are you sure you want to delete?</p>
                    <Toolbar leftSide={<>
                        <Button onClick={() => {
                            setShowConfirm(false);
                            onCancelEditCurrentMode();
                        }} btnType="primary" icon='fal fa-check' className="mr-s" >Yes</Button>
                        <Button onClick={() => setShowConfirm(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
                </>}
                onClose={() => setShowConfirm(false)}
            >
                <Button btnType="secondary" icon="fal fa-trash" onClick={() => setShowConfirm(true)}>Delete application</Button>
            </ConfirmationDialog>
        </> : <>
            <Button btnType="primary" icon="fal fa-pen" onClick={() => { setIsEditCurrentMode(true) }}>Edit</Button>
            <Button btnType="secondary" icon="fal fa-print" onClick={onPrint}>Print</Button>
        </>
    }
    return (
        <ReactAux>
            <MainContainerHeader>
                <ActionContainer>
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={crumbs} />
                    </ActionContainerHeader>
                    {actionBtns}
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                {!isReviewMode && !isCurrentAppMode &&
                    <>
                        <div className='section-container mt-m'>
                            <Toolbar
                                leftSide={
                                    isEdit ? <>
                                        <Button className="mr-s" icon="fal fa-save" onClick={onSave}>Save</Button>
                                        <Button className="mr-s" icon="fal fa-times-circle" btnType="secondary" onClick={onCalnceEdit}>Cancel</Button>
                                    </> :
                                        <Button className="mr-s" icon="fal fa-pen" onClick={onEdit}>Edit</Button>
                                }
                            />
                            <Row className="mt-s">
                                <Col xs="3" className="mb-s">
                                    <label className="lv-label justify-content-end text-right">
                                        Status
                                    </label>
                                </Col>
                                <Col xs="9" className="mb-s">
                                    {isEdit ?
                                        <div className='d-flex align-items-center'>
                                            <Select className="width-m mr-s" options={options} value={status.value} onChange={(obj) => { onChangeStatus(obj) }} />
                                            <a href="/" className="lv-link-styles">View offers and selections</a>
                                        </div>
                                        : <label className="lv-label"><strong>{status.label}</strong></label>}
                                </Col>
                                <Col xs="3" className="mb-s">
                                    <label className="lv-label justify-content-end text-right">
                                        Override Notes
                                    </label>
                                </Col>
                                <Col xs="9" className="mb-s">
                                    {isEdit ? <Input className="width-m" value={note} onChange={onChangeNote} /> : <label className="lv-label"><strong>{note}</strong></label>}
                                </Col>
                                <Col className="col-3">
                                    <label className="lv-label justify-content-end text-right">
                                        Last Activity
                                    </label>
                                </Col>
                                <Col xs="9" className="mb-s">
                                    <label className="lv-label">
                                        <strong>E-mail Sent - April 15, 2021 11:10 AM</strong>
                                    </label>
                                </Col>
                                <Col xs="3" className="mb-s">
                                    <label className="lv-label justify-content-end text-right">
                                        Application #
                                    </label>
                                </Col>
                                <Col xs="9" className="mb-s">
                                    <label className="lv-label">
                                        <strong>009K5</strong>
                                    </label>
                                </Col>
                            </Row>
                        </div>
                        <Collapse
                            title="Identity Verification"
                            expanded={collapsOne}
                            onClick={() => setCollapsOne(!collapsOne)}
                            className="mt-m"
                        >
                            <DemoTable />
                        </Collapse>

                        <Collapse
                            title="Log"
                            expanded={collapsTwo}
                            onClick={() => setCollapsTwo(!collapsTwo)}
                        >
                            <DemoTableSecond />
                        </Collapse>
                    </>
                }
                {
                    (isReviewMode || isCurrentAppMode) &&
                    <div className="mt-m">

                        <Collapse
                            title="Purpose / Product"
                            expanded={revCollOne}
                            onClick={() => setColOne(!revCollOne)}
                        >
                            <Row>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Statement Date</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Purpose</label>
                                        </Col>
                                        <Col xs='6'>
                                            <label className='lv-label'><strong>Working Capital</strong></label>
                                        </Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Product Type</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                    </Row>
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Amount Requested</label>
                                        </Col>
                                        <Col xs="6"><label className='lv-label'><strong>$50,000</strong></label></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Specific Reason</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Date Business Started</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>
                        <Collapse
                            title="Auto Details"
                            expanded={revCollTwo}
                            onClick={() => setColTwo(!revCollTwo)}
                        >
                            <Row>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>New or Used</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Purchase Price</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Year</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6"><label className='lv-label justify-content-end text-right'>Make</label></Col>
                                        <Col xs='6'></Col>
                                    </Row>
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Model</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>VIN</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Mileage</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>
                        <Collapse
                            title="Your Information"
                            expanded={revCollThree}
                            onClick={() => setColThree(!revCollThree)}
                        >
                            <Row>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>First Name</label>
                                        </Col>
                                        <Col xs='6'><label className='lv-label'><strong>Ruth</strong></label></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Middle Name</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Last Name</label>
                                        </Col>
                                        <Col xs='6'><label className='lv-label'><strong>Ruth</strong></label></Col>
                                        <Col xs="6"><label className='lv-label justify-content-end text-right'>Social Security Number</label></Col>
                                        <Col xs='6'><label className='lv-label'><strong>666647983</strong></label></Col>
                                        <Col xs="6"><label className='lv-label justify-content-end text-right'>Mobile Phone</label></Col>
                                        <Col xs='6'><label className='lv-label'><strong>(818) 352-3798</strong></label></Col>
                                        <Col xs="6"><label className='lv-label justify-content-end text-right'>Home Phone</label></Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6"><label className='lv-label justify-content-end text-right'>Email Address</label></Col>
                                        <Col xs='6'><label className='lv-label'><strong>ruth.simpson@mail.com</strong></label></Col>
                                        <Col xs="6"><label className='lv-label justify-content-end text-right'>Date of Birth</label></Col>
                                        <Col xs='6'> <label className='lv-label'><strong>1/1/1950</strong></label></Col>
                                    </Row>
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>
                                                Business Email Address<br />
                                                (If different from Owner below)
                                            </label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>ID Type</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>ID Number</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>ID Issue Date</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>ID Expiration Date</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>ID Issuer</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>% Ownership</label>
                                        </Col>
                                        <Col xs="6"><label className='lv-label'><strong>100.000 %</strong></label></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Serve in Military</label>
                                        </Col>
                                        <Col xs="6"></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>
                        <Collapse
                            title="Business References"
                            expanded={revCollFour}
                            onClick={() => setColFour(!revCollFour)}
                        >
                            <Row>
                                <Col xs="12">
                                    <h3 className='text-center'>Accountant</h3>
                                    <hr className="border-color-3 mt-s mb-s" />
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Name</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Email</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>City/State/Zip Code</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Phone Number</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs="12">
                                    <h3 className='text-center mt-l'>Attorney</h3>
                                    <hr className="border-color-3 mt-s mb-s" />
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Name</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Email</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>City/State/Zip Code</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Phone Number</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs="12">
                                    <h3 className='text-center mt-l'>Insurance Agent</h3>
                                    <hr className="border-color-3 mt-s mb-s" />
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Name</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Email</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Email</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs='6'>
                                    <Row>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>City/State/Zip Code</label>
                                        </Col>
                                        <Col xs='6'></Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Phone Number</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                        <Col xs="6">
                                            <label className='lv-label justify-content-end text-right'>Email</label>
                                        </Col>
                                        <Col xs='6'>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>
                        <Collapse
                            title="Business Address"
                            expanded={revCollFive}
                            onClick={() => setColFive(!revCollFive)}
                        >
                            <div>
                                Lorem ipsum
                            </div>
                        </Collapse>
                        <Collapse
                            title="Business Obligations"
                            expanded={revCollSix}
                            onClick={() => setColSix(!revCollSix)}
                        >
                            <div>
                                Lorem ipsum
                            </div>
                        </Collapse>
                        <Collapse
                            title="Guarantord"
                            expanded={revCollSeven}
                            onClick={() => setColSeven(!revCollSeven)}
                        >
                            <div>
                                Lorem ipsum
                            </div>
                        </Collapse>

                    </div>
                }

            </MainContainerScrollable>
        </ReactAux>
    );
}

export default DemoApplicationCIBA;