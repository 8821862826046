import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import { DrillDown, DrillDownContainer, Radio, Button } from '@jkhy/vsg-loanvantage-design-system';

class DrillDownPage extends React.Component {

    state = {
        drillDownsState: [
            { isAcitve: false, expanded: false },
            { isAcitve: true, expanded: true },
            { isAcitve: false, expanded: false },
            { isAcitve: false, expanded: false },
        ],
    }

    onControlClick(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState.map((c, i) => {
            if (i === index) return drillDownsState[i].isAcitve = true;
            return drillDownsState[i].isAcitve = false;
        });

        this.setState({ drillDownsState });
    }

    onArrowClick(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].expanded = !drillDownsState[index].expanded;

        this.setState({ drillDownsState });
    }

    toggleExpandAll() {
        const { drillDownsState } = this.state;

        if (this.isAllExpanded()) {
            drillDownsState.forEach((c, i) => {
                if (i < drillDownsState.length - 1)
                    return drillDownsState[i].expanded = false;
            });
        } else {
            drillDownsState.forEach((c, i) => {
                if (i < drillDownsState.length - 1 && drillDownsState[i].expanded === false)
                    return drillDownsState[i].expanded = true;
            });
        }


        this.setState({ drillDownsState });
    }

    isAllExpanded() {
        const { drillDownsState } = this.state;

        for (let i = 0; i < drillDownsState.length - 1; i++) {
            if (drillDownsState[i].expanded === false) return false;
        }

        return true;
    }

    render() {

        const { drillDownsState } = this.state;

        const drillDowns = [
            {
                cols: ['Joro Test', '19019', 'Joro Test', <strong>US $ 0.00</strong>],
                content: <div style={{ height: '300px', backgroundColor: 'var(--color-select)' }}>Content...</div>,
            },
            {
                cols: ['CRE Reports Joro', '26209', 'CRE Reports', <strong>US $ 960,000.00</strong>],
                content: <div>Content...</div>,
            },
            {
                cols: ['Joro Test', '19019', 'Joro Test', <strong>US $ 0.00</strong>],
                content: <div>Content...</div>,
            },
            {
                cols: ['Joro Test', '19019', 'Joro Test', <strong>US $ 0.00</strong>],
                content: <div>Content...</div>,
            },
        ];

        return (
            <div>
                <h1 className="mb-l">DrillDown</h1>

                <h2 className="mb-m">DrillDown module components:</h2>
                <p className="mb-s"><strong><a href="#card">DrillDownContainer</a></strong> - The main wrapper component</p>
                <p className="mb-l"><strong><a href="#card-section">DrillDown</a></strong> - This component displays data for its predefined titles.</p>

                <p className="mb-s">
                    Demo pages with DrillDown:
                </p>
                <ul className="mb-l">
                    <li><Link to="/demo/flood-determination/">Flood Determinations</Link></li>
                    <li><Link to="/demo/service-request/">Service Request</Link></li>
                    <li><Link to="/demo/file-collateral/">File Collateral</Link></li>
                </ul>

                <DrillDownContainer
                    actionBtnRender={() => <Button btnType="icon" icon={`fal fa-${this.isAllExpanded() ? 'compress' : 'expand'}-alt`} data-tip={this.isAllExpanded() ? 'Compress' : 'Expand'} onClick={() => this.toggleExpandAll()} />}
                    cols={[
                        { title: 'Relationship Name', percentage: 35 },
                        { title: 'File #', percentage: 15 },
                        { title: 'Primary Borrower', percentage: 30, textAlign: 'left' },
                        { title: 'Exposure', percentage: 20 },
                    ]}
                    footerCols={['Total', '', '', 'US $ 960,000.00']}
                >
                    {drillDowns.map((d, i) =>
                        <DrillDown
                            key={i}
                            cols={d.cols}
                            isActive={drillDownsState[i].isAcitve}
                            expanded={drillDownsState[i].expanded}
                            disabled={drillDownsState.length - 1 === i}
                            controlRender={() =>
                                <Radio
                                    name="radio"
                                    checked={drillDownsState[i].isAcitve}
                                    disabled={drillDownsState.length - 1 === i}
                                    onChange={() => this.onControlClick(i)}
                                />
                            }
                            onArrowClick={() => this.onArrowClick(i)}
                            onRowClick={() => this.onControlClick(i)}
                            dataUI={i + ''}
                        >
                            {d.content}
                        </DrillDown>
                    )}
                </DrillDownContainer>


                <Highlight className="React">
                    {
                        `
import React from 'react';
import { DrillDown, DrillDownContainer, Radio, Button } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    state = {
        drillDownsState: [
            { isAcitve: false, expanded: false },
            { isAcitve: true, expanded: true },
            { isAcitve: false, expanded: false },
            { isAcitve: false, expanded: false },
        ],
    }

    onControlClick(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState.map((c, i) => {
            if (i === index) return drillDownsState[i].isAcitve = true;
            return drillDownsState[i].isAcitve = false;
        });

        this.setState({ drillDownsState });
    }

    onArrowClick(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].expanded = !drillDownsState[index].expanded;

        this.setState({ drillDownsState });
    }

    toggleExpandAll() {
        const { drillDownsState } = this.state;

        if (this.isAllExpanded()) {
            drillDownsState.forEach((c, i) => {
                if (i < drillDownsState.length - 1)
                    return drillDownsState[i].expanded = false;
            });
        } else {
            drillDownsState.forEach((c, i) => {
                if (i < drillDownsState.length - 1 && drillDownsState[i].expanded === false)
                    return drillDownsState[i].expanded = true;
            });
        }


        this.setState({ drillDownsState });
    }

    isAllExpanded() {
        const { drillDownsState } = this.state;

        for (let i = 0; i < drillDownsState.length - 1; i++) {
            if (drillDownsState[i].expanded === false) return false;
        }

        return true;
    }
    
    render() {

        const { drillDownsState } = this.state;

        const drillDowns = [
            {
                cols: ['Joro Test', '19019', 'Joro Test', <strong>US $ 0.00</strong>],
                content: <div style={{ height: '300px', backgroundColor: 'var(--color-select)' }}>Content...</div>,
            },
            {
                cols: ['CRE Reports Joro', '26209', 'CRE Reports', <strong>US $ 960,000.00</strong>],
                content: <div>Content...</div>,
            },
            {
                cols: ['Joro Test', '19019', 'Joro Test', <strong>US $ 0.00</strong>],
                content: <div>Content...</div>,
            },
            {
                cols: ['Joro Test', '19019', 'Joro Test', <strong>US $ 0.00</strong>],
                content: <div>Content...</div>,
            },
        ];

        return (
            <DrillDownContainer
                actionBtnRender={() => <Button btnType="icon" icon={\`fal fa-\${this.isAllExpanded() ? 'compress' : 'expand'}-alt\`} data-tip={this.isAllExpanded() ? 'Compress' : 'Expand'} onClick={() => this.toggleExpandAll()} />}
                cols={[
                    { title: 'Relationship Name', percentage: 35 },
                    { title: 'File #', percentage: 15 },
                    { title: 'Primary Borrower', percentage: 30, textAlign: 'left' },
                    { title: 'Exposure', percentage: 20 },
                ]}
                footerCols={['Total', '', '', 'US $ 960,000.00']}
            >
                {drillDowns.map((d, i) =>
                    <DrillDown
                        key={i}
                        cols={d.cols}
                        isActive={drillDownsState[i].isAcitve}
                        expanded={drillDownsState[i].expanded}
                        disabled={drillDownsState.length - 1 === i}
                        controlRender={() =>
                            <Radio
                                name="radio"
                                checked={drillDownsState[i].isAcitve}
                                disabled={drillDownsState.length - 1 === i}
                                onChange={() => this.onControlClick(i)}
                            />
                        }
                        onArrowClick={() => this.onArrowClick(i)}
                        onRowClick={() => this.onControlClick(i)}
                        dataUI={i + ''}
                    >
                        {d.content}
                    </DrillDown>
                )}
            </DrillDownContainer>
        );
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <p className="mb-xl">DrillDown component in DrillDown module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

                <h3 id="card">DrillDownContainer</h3>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>cols</td>
                            <td>
                                array of objects with props: <br /><br />
                                "title": string or JSX.Element, "percentage": number, "textAlign": 'right' | 'left'
                            </td>
                            <td>"textAlign": 'right'</td>
                            <td>Set the cols with title, width percentage and textAlign.</td>
                        </tr>
                        <tr>
                            <td>footerCols</td>
                            <td>array of string or JSX.Element</td>
                            <td className="text-center">-</td>
                            <td>Set the cols with text or element. Show table footer.</td>
                        </tr>
                        <tr>
                            <td>actionBtnRender</td>
                            <td>Function</td>
                            <td className="text-center">-</td>
                            <td>Render element for action button. Will be placed on the left.</td>
                        </tr>
                    </tbody>
                </table>

                <h3 id="card-section">DrillDown</h3>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>cols</td>
                            <td>array of string or JSX.Element</td>
                            <td className="text-center">-</td>
                            <td>Set the cols with text or element.</td>
                        </tr>
                        <tr>
                            <td>expanded</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Shows and render in DOM the expanded content.</td>
                        </tr>
                        <tr>
                            <td>controlRender</td>
                            <td>Function</td>
                            <td className="text-center">-</td>
                            <td>Render control. Will be placed on the left.</td>
                        </tr>
                        <tr>
                            <td>isActive</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Shows the active style.</td>
                        </tr>
                        <tr>
                            <td>disabled</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Disables the component.</td>
                        </tr>
                        <tr>
                            <td>onArrowClick</td>
                            <td>() {'=>'} void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the arrow for collapse is clicked.</td>
                        </tr>
                        <tr>
                            <td>onRowClick</td>
                            <td>() {'=>'} void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the row is clicked.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default DrillDownPage;
