import React from 'react';
import Highlight from 'react-highlight';


const TooltipPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">Tooltip</h1>

            <p className="mb-s">Tooltips are small info boxes with additional information, usually
            displayed after hovering a given element. </p>
            <p className="mb-xl">The main Tooltip component has to be included only once for the whole view. Then all
            elements, that contain tooltip configuration data attributes will serve as tooltip hosts. All generic
            configuration options can also be set to the Tooltip component.</p>

            <div className="mb-xl">
                <span className="mr-l" data-tip="Left: Lorem ipsum" data-place="left">Tooltip left</span>
                <span className="mr-l" data-tip="Top: Lorem ipsum" data-place="top">Tooltip top</span>
                <span className="mr-l" data-tip="Bottom: Lorem ipsum" data-place="bottom">Tooltip bottom</span>
                <span className="mr-l" data-tip="Right: Lorem ipsum" data-place="right">Tooltip right</span>
                <span className="mr-l" data-html={true} data-tip="<p>Some <i>custom</i> <strong>content</strong></p>">Tooltip with custom content</span>
                <span className="mr-l" data-event="click" data-tip="This is toggled on click">Tooltip with click event</span>
                <span className="mr-l" data-offset="{'top': 0, 'left': 50}" data-tip="Some custom offset">Tooltip with custom offset</span>
            </div>
            <Highlight className="React">
                {
                    `
import Reactfrom 'react';
import { Tooltip } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    render() {
        return (
            <span 
                data-tip="Left: Lorem ipsum" 
                data-place="left"
            >
                Tooltip left
            </span>

            <span
                data-tip="Top: Lorem ipsum"
                data-place="top"
            >
                Tooltip top
            </span>

            <span
                data-tip="Bottom: Lorem ipsum" 
                data-place="bottom"
            >
                Tooltip bottom
            </span>

            <span
                data-tip="Right: Lorem ipsum" 
                data-place="right"
            >
                Tooltip right
            </span>

            <span
                data-html={true} 
                data-tip="<p>Some <i>custom</i> <strong>content</strong></p>"
            >
                Tooltip with custom content
            </span>

            <span
                data-event="click" 
                data-tip="This is toggled on click"
            >
                Tooltip with click event
            </span>

            <span
                data-offset="{'top': 0, 'left': 50}" 
                data-tip="Some custom offset"
            >
                Tooltip with custom offset
            </span>

            // The main Tooltip component - here it sets generic place for
            // all tooltips on elements, which do not have explicit data-place attribute
            <Tooltip place="top" />
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <p className="mb-m">All the props in the table below can either be passed to the main Tooltip component or
            to the tooltip hosts as data attributes.</p>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>place</td>
                        <td>string: 'top', 'right', 'bottom', 'left'</td>
                        <td>'top'</td>
                        <td>Defines the tooltip placement.</td>
                    </tr>
                    <tr>
                        <td>event</td>
                        <td>string</td>
                        <td>'hover'</td>
                        <td>Assigns the DOM event, that will toggle the tooltip.</td>
                    </tr>
                    <tr>
                        <td>html</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Allows passing HTML string as tooltip content, like: <br />
                            <i>&lt;p data-tip="&lt;p&gt;HTML tooltip&lt;/p&gt;" data-html={true}&gt;&lt;/p&gt; </i>
                            <br />
                            or
                            <br />
                            <i>&lt;ReactTooltip html=&#123;true&#125; /&gt;</i>
                        </td>
                    </tr>
                    <tr>
                        <td>offset</td>
                        <td>object: &#123;top:number, right:number, bottom:number, left:number&#125;</td>
                        <td className="text-center">-</td>
                        <td>Sets custom offset to the target tooltip.</td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Methods</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Params</strong>
                        </td>
                        <td>
                            <strong>Return type</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>rebuild (static)</td>
                        <td className="text-center">-</td>
                        <td>void</td>
                        <td>Notifies the tooltip service about all elements on the page,
                        that are tooltip hosts via the "data-tip" attribute. This method should
                        be used when the tooltip host elements are rendered asynchronously and
                        their tooltips are therefore not shown. This method should be used
                            with <strong>caution</strong>, since it runs an additional initialization
                            cycle of the tooltip service.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default TooltipPage;
