import React from 'react';
import Highlight from 'react-highlight';

import { Icon } from '@jkhy/vsg-loanvantage-design-system';


const IconPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Icon</h1>

            <p className="mb-l">This component renders <a href="https://fontawesome.com/icons?d=gallery" title="fa" target="_blank" rel="noopener noreferrer">Font awesome</a> icon
             by the passed icon name. Icons have two sizes 'S', 'M' & 'L'.</p>

            <div>
                <Icon icon="fal fa-angle-down" size="S" />
                <Icon icon="fal fa-angle-up" size="S" />
                <Icon icon="fal fa-angle-right" size="S" />
                <Icon icon="fal fa-bars" size="S" />
                <Icon icon="fal fa-home" size="S" />
                <Icon icon="fas fa-exclamation-circle" size="S" style={{ color: '#E33E3C' }} />
            </div>
            <div>
                <Icon icon="fal fa-angle-down" size="M" />
                <Icon icon="fal fa-angle-up" size="M" />
                <Icon icon="fal fa-angle-right" size="M" />
                <Icon icon="fal fa-bars" size="M" />
                <Icon icon="fal fa-home" size="M" />
                <Icon icon="fas fa-exclamation-circle" size="M" style={{ color: '#E33E3C' }} />
            </div>
            <div  className="mb-s">
                <Icon icon="fal fa-angle-down" size="L" />
                <Icon icon="fal fa-angle-up" size="L" />
                <Icon icon="fal fa-angle-right" size="L" />
                <Icon icon="fal fa-bars" size="L" />
                <Icon icon="fal fa-home" size="L" />
                <Icon icon="fas fa-exclamation-circle" size="L" style={{ color: '#E33E3C' }} />
            </div>

            <h2 className="mb-s">Custom Icons</h2>
            <div className="mb-l">
                <Icon icon="custom-icon-application-call-s" size='M' />
                <Icon icon="custom-icon-calculator-locked-s" size='M' />
                <Icon icon="custom-icon-calculator-unlocked-s" size='M' />
                <Icon icon="custom-icon-application-call-s" size='S' />
                <Icon icon="custom-icon-calculator-locked-s" size='S' />
                <Icon icon="custom-icon-calculator-unlocked-s" size='S' />
            </div>
            <h3 className="mb-s">Steps for adding custom icons in project.</h3>
            <div>
                <p>1. On your PC create folder to hold all custom SVG icons. The name of the SVG file will be the second part of the custom icon name. File name should not contain "custom-icon-" in it's name.</p>
                <p>2. Create a folder for custom icons in your project. In this folder create subfolders "css" and "font".</p>
                <p>3. Go to <a target="_blank" rel="noopener noreferrer" href="https://fontello.com/">https://fontello.com/</a></p>
                <p>4. Click on settings (<Icon icon="fas fa-wrench" size='S' />). From dropdown menu edit "CSS prefix" to "custom-icon-".</p>
                <p>5. Set font name to "loanvantage_v#"(input after settings btn).</p>
                <p>6. Select all custom SVG icons from the folder, created in step 1, and drag and drop them in the field Custom Icons in fontello.</p>
                <p>6.1. <strong>Add new Icon to existing </strong>- add it in folder created in step 1 and drag and drop all icons in fontello.</p>
                <p>6.2. <strong>Remove Icon </strong>- remove the icon from the folder created in step 1 and drag and drop all icons in fontello.</p>
                <p>7. Select all custom icons in fontello by clicking on them.</p>
                <p>8. Download webfont.</p>
                <p>9. Unzip the downloaded folder with webfont.</p>
                <p>9.1 Open the folder and copy the folder "font". In your project find the folder for custom icons and replace the "font" folder.</p>
                <p>9.2 Open the folder. There is folder "css", delete all files exept "loanvantage_v#.css"(example: loanvantage_v1.css, loanvantage_v2.css ...). In your project find the folder for custom icons and replace the "css" file.</p>
                <p>10. Refer to your project "loanvantage_v#.css.</p>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Icon } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    return (
      <div>
        <Icon icon="fal fa-angle-down" size="L"/>
        <Icon icon="fal fa-angle-up" size="L" />
        <Icon icon="fal fa-angle-right" size="L" />
        <Icon icon="fal fa-bars" size="L" />
        <Icon icon="fal fa-home" size="L" />
        <Icon icon="fas fa-exclamation-circle" size="L" style={{color:'#E33E3C'}}/>

        <Icon icon="fal fa-angle-down" size="M"/>
        <Icon icon="fal fa-angle-up" size="M" />
        <Icon icon="fal fa-angle-right" size="M" />
        <Icon icon="fal fa-bars" size="M" />
        <Icon icon="fal fa-home" size="M" />
        <Icon icon="fas fa-exclamation-circle" size="M" style={{color:'#E33E3C'}}/>
       
        <Icon icon="fal fa-angle-down" size="S"/>
        <Icon icon="fal fa-angle-up" size="S"/>
        <Icon icon="fal fa-angle-right" size="S" />
        <Icon icon="fal fa-bars" size="S"/>
        <Icon icon="fal fa-home" size="S" />
        <Icon icon="fas fa-exclamation-circle" size="S" style={{color:'#E33E3C'}}/>

        //Custom Icons
        <Icon icon="custom-icon-application-call-s" size='M' />
        <Icon icon="custom-icon-calculator-locked-s" size='M' />
        <Icon icon="custom-icon-calculator-unlocked-s" size='M' />
        <Icon icon="custom-icon-application-call-s" size='S' />
        <Icon icon="custom-icon-calculator-locked-s" size='S' />
        <Icon icon="custom-icon-calculator-unlocked-s" size='S' />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon. Check from font awesome doc.</td>
                    </tr>
                    <tr>
                        <td>size</td>
                        <td>string: 'S', 'M' or 'L'</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon size.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon wrapper class.</td>
                    </tr>
                    <tr>
                        <td>style</td>
                        <td>React.CSSProperties</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon wrapper style.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default IconPage;
