import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import { Multiselect } from '@jkhy/vsg-loanvantage-design-system';


const multiselectOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const MultiselectPage: React.FC = () => {
    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState([{ ...multiselectOptions[1] }]);

    const [value3, setValue3] = useState([]);
    const [
        loadedOptions, setLoadedOptions
    ] = useState<{ label: string, value: string, [key: string]: any }[]>([]);
    const [loading, setLoading] = useState(false);

    const [value4, setValue4] = useState([]);

    return (
        <div>
            <h1 className="mb-s">Multiselect</h1>
            <p className="mb-l">
                A form control, that allows selection of multiple options from a given list. It is similar
                to the <Link to="./autocomplete">Autocomplete</Link> by providing input filtering of
                the given options.
            </p>

            <Multiselect
                className="mb-s"
                options={multiselectOptions}
                value={value1}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue1(obj);
                }}
                onInput={(val: string) => console.log(val)}
            />

            <Multiselect
                className="mb-s"
                options={multiselectOptions}
                value={value2}
                onChange={(obj: any) => {
                    setValue2(obj);
                }}
                noOptionsMessage="No data found."
                placeholder="Custom placeholder"
            />

            <Multiselect
                className="mb-m"
                options={multiselectOptions}
                value={[{ ...multiselectOptions[0] }]}
                disabled
            />

            <h3 className="mb-s">Multiselect with loading options</h3>
            <Multiselect
                className="mb-s"
                options={loadedOptions}
                value={value3}
                onChange={(obj: any) => {
                    setValue3(obj !== null ? obj.value : '');
                }}
                noOptionsMessage="No data found."
                placeholder="Initially unavailable options"
                onFocus={() => {
                    if (!loading && loadedOptions.length === 0) {
                        setLoading(true);
                        setTimeout(() => {
                            setLoading(false);
                            setLoadedOptions(multiselectOptions);
                        }, 2000);
                    }
                }}
                isLoading={loading}
            />

            <h3 className="mb-s">Multiselect custom filter configuration</h3>
            <Multiselect
                options={multiselectOptions}
                value={value4}
                onChange={(obj: any) => {
                    setValue4(obj);
                }}
                additionalSearchKeys={['secondCode']}
                noOptionsMessage="No data found."
                placeholder="Multiselect with additional search keys"
            />

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { Multiselect } from '@jkhy/vsg-loanvantage-design-system';

const multiselectOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const Example: React.FC = (props) => {
    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState([{ ...multiselectOptions[1] }]);

    const [value3, setValue3] = useState([]);
    const [
        loadedOptions, setLoadedOptions
    ] = useState<{ label: string, value: string, [key: string]: any }[]>([]);
    const [loading, setLoading] = useState(false);

    const [value4, setValue4] = useState([]);

    return (
      <div>
        <Multiselect
            options={multiselectOptions}
            value={value1}
            onChange={(obj: any) => {
                console.log(obj);
                setValue1(obj);
            }}
            onInput={(val: string) => console.log(val)}
            noOptionsMessage="No data found."
        />

        <Multiselect
            options={multiselectOptions}
            value={value2}
            onChange={(obj: any) => {
                setValue2(obj);
            }}
            noOptionsMessage="No data found."
            placeholder="Custom placeholder"
        />

        <Multiselect
            options={multiselectOptions}
            value={[{ ...multiselectOptions[0] }]}
            disabled
        />

        <Multiselect
            options={loadedOptions}
            value={value3}
            onChange={(obj: any) => {
                setValue3(obj !== null ? obj.value : '');
            }}
            noOptionsMessage="No data found."
            placeholder="Initially unavailable options"
            onFocus={() => {
                if (!loading && loadedOptions.length === 0) {
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                        setLoadedOptions(multiselectOptions);
                    }, 2000);
                }
            }}
            isLoading={loading}
        />

        <Multiselect
            options={multiselectOptions}
            value={value4}
            onChange={(obj: any) => {
                setValue4(obj);
            }}
            additionalSearchKeys={['secondCode']}
            noOptionsMessage="No data found."
            placeholder="Multiselect with additional search keys"
        />
    </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>options</td>
                        <td>{'{label:string, value:string, [key:string]:string}[]'}</td>
                        <td className="text-center">-</td>
                        <td>The array of options, which are displayed in the Multiselect dropdown. The <i>label</i> and <i>value</i> keys
                            are required.
                        </td>
                    </tr>
                    <tr>
                        <td>value</td>
                        <td>{'{label:string, value:string, [key:string]:string}[]'}</td>
                        <td className="text-center">-</td>
                        <td>Sets the Multiselect value. Notice that it is actually an array with the already selected options.</td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Makes the Multiselect disabled.</td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the given text as a placeholder of the Multiselect.
                        </td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>The id to set on the Multiselect container.</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Name of the HTML Input, associated to the form control. If this property is left empty, no input will be rendered).</td>
                    </tr>
                    <tr>
                        <td>tabIndex</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Sets the tabIndex attribute on the input.</td>
                    </tr>
                    <tr>
                        <td>isLoading</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Indicates if the Multiselect should be displayed in loading state.</td>
                    </tr>
                    <tr>
                        <td>noOptionsMessage</td>
                        <td>string</td>
                        <td>"No options"</td>
                        <td>Text to display when there are no options.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>{'([{ label: string, value: string, [key: string]: any }]) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Returns the array of currently selected options, including the one, that has just been added or removed.</td>
                    </tr>
                    <tr>
                        <td>onFocus</td>
                        <td>{'(event: FocusEvent) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Callback when the Multiselect is focused.</td>
                    </tr>
                    <tr>
                        <td>onInput</td>
                        <td>{'(value: string) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Callback for input changes on the Multiselect.</td>
                    </tr>
                    <tr>
                        <td>additionalSearchKeys</td>
                        <td>string []</td>
                        <td className="text-center">-</td>
                        <td>An array with additional keys to be included in the options filtering.
                            The Multiselect component filters options by
                            their <i>label</i> and <i>value</i> keys by default.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MultiselectPage;
