import React from 'react';
import Highlight from 'react-highlight';

import {
    RadialGaugeResult, WidgetContainer, RadialGauge
} from '@jkhy/vsg-loanvantage-design-system';


const radialOptions = {
    shape: 'arrow',
    scale: {
        minorUnit: 20,
        majorUnit: 20,
        max: 800,
        min: 0,
        ranges: [
            { from: 0, to: 500, color: 'var(--color-danger)' },
            { from: 500, to: 660, color: 'var(--color-warning)' },
            { from: 660, to: 750, color: 'var(--color-success)' },
            { from: 750, to: 800, color: 'var(--color-excellent)'},
        ],
        rangeSize: 16,
        labels: {
            visible: false
        },
        majorTicks: { visible: true, color: "var(--color-text-muted)" },
        minorTicks: { visible: true, color: "var(--color-text-muted)" },
        startAngle: -50,
        endAngle: 230,
    },
    pointer: [{
        value: 703,
        color: 'var(--color-text)'
    }],
    transitions: true,
};

const RadialGaugeResultPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-l">RadialGauge Result</h1>

            <p className="mb-m">Information box for RadialGauge.</p>

            <WidgetContainer title="Credit Score Range">
                <RadialGauge {...radialOptions} style={{ height: "300px" }} />
                <RadialGaugeResult
                    pointerValue={radialOptions.pointer[0].value}
                    description="Good"
                    pointerValueType="success"
                />
            </WidgetContainer>

            <Highlight className="React">
                {
                    `
import React from 'react';

import {
    RadialGaugeResult, WidgetContainer, RadialGauge
} from '@jkhy/vsg-loanvantage-design-system';


const radialOptions = {
    shape: 'arrow',
    scale: {
        minorUnit: 20,
        majorUnit: 20,
        max: 800,
        min: 0,
        ranges: [
            { from: 0, to: 500, color: 'var(--color-danger)' },
            { from: 500, to: 660, color: 'var(--color-warning)' },
            { from: 660, to: 750, color: 'var(--color-success)' },
            { from: 750, to: 800, color: 'var(--color-excellent)'},
        ],
        rangeSize: 16,
        labels: {
            visible: false
        },
        majorTicks: { visible: true, color: "var(--color-text-muted)" },
        minorTicks: { visible: true, color: "var(--color-text-muted)" },
        startAngle: -50,
        endAngle: 230,
    },
    pointer: [{
        value: 703,
        color: 'var(--color-text)'
    }],
    transitions: true,
};

class Example extends React.Component {

    render() {
        return (
            <WidgetContainer title="Credit Score Range">
                <RadialGauge {...radialOptions} style={{ height: "300px" }} />
                <RadialGaugeResult
                    pointerValue={radialOptions.pointer[0].value}
                    description="Good"
                    pointerValueType="success"
                />
            </WidgetContainer>
        );
    }
}

export default Example;
        `}
            </Highlight>


            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>pointerValue</td>
                        <td>string | number</td>
                        <td className="text-center">-</td>
                        <td>Set the RadialGauge value.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set description.</td>
                    </tr>
                    <tr>
                        <td>pointerValueType</td>
                        <td>'excellent' | 'success' | 'warning' | 'danger'</td>
                        <td className="text-center">-</td>
                        <td>Set "description" text color. If not set the color will be Lv Design System "color-text" .</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set class to the wrapper.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default RadialGaugeResultPage;
