import React from 'react';
import Highlight from 'react-highlight';
import { Checkbox } from '@jkhy/vsg-loanvantage-design-system';


const CheckboxPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Checkbox</h1>
            <p className="mb-l">The Checkbox component extends the native <i>input type="checkbox"</i> element.</p>

            <h3 className="mb-s">Default</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }}>Checkbox</Checkbox>
                <Checkbox readOnly checked>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled checked onChange={() => { }}>Checkbox Disabled</Checkbox>
                <Checkbox>Checkbox</Checkbox>
                <Checkbox readOnly>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled >Checkbox Disabled</Checkbox>
            </div>

            <h3 className="mb-s">Default no text</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} />
                <Checkbox readOnly checked/>
                <Checkbox checked disabled onChange={() => { }} />
                <Checkbox />
                <Checkbox readOnly/>
                <Checkbox disabled />
            </div>

            <h3 className="mb-s">Block with text</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} className="d-block">Checkbox</Checkbox>
                <Checkbox className="d-block" readOnly checked>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled checked className="d-block">Checkbox Disabled</Checkbox>
                <Checkbox className="d-block">Checkbox</Checkbox>
                <Checkbox className="d-block" readOnly>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled className="d-block">Checkbox Disabled</Checkbox>
            </div>

            <h3 className="mb-s">Block no text</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} className="d-block" />
                <Checkbox readOnly checked className="d-block" />
                <Checkbox disabled checked onChange={() => { }} className="d-block" />
                <Checkbox className="d-block" />
                <Checkbox readOnly className="d-block" />
                <Checkbox disabled className="d-block" />
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Checkbox } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    return (
      <div>
        // Default
        <Checkbox checked onChange={() => { }}>Checkbox</Checkbox>
        <Checkbox readOnly checked>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled checked onChange={() => { }}>Checkbox Disabled</Checkbox>
        <Checkbox>Checkbox</Checkbox>
        <Checkbox readOnly>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled >Checkbox Disabled</Checkbox>

        // Default no text
        <Checkbox checked onChange={() => { }} />
        <Checkbox readOnly checked/>
        <Checkbox checked disabled onChange={() => { }} />
        <Checkbox />
        <Checkbox readOnly/>
        <Checkbox disabled />

         // Block with text
        <Checkbox checked onChange={() => { }} className="d-block">Checkbox</Checkbox>
        <Checkbox className="d-block" readOnly checked>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled checked className="d-block">Checkbox Disabled</Checkbox>
        <Checkbox className="d-block">Checkbox</Checkbox>
        <Checkbox className="d-block" readOnly>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled className="d-block">Checkbox Disabled</Checkbox>

        // Block no text
        <Checkbox checked onChange={() => { }} className="d-block" />
        <Checkbox readOnly checked className="d-block" />
        <Checkbox disabled checked onChange={() => { }} className="d-block" />
        <Checkbox className="d-block" />
        <Checkbox readOnly className="d-block" />
        <Checkbox disabled className="d-block" />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "disabled", "name", "checked"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default CheckboxPage;