import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { UploadedFileRow } from '@jkhy/vsg-loanvantage-design-system';


const UploadedFileRowPage: React.FC = () => {
    const examplePDF = new File(['example pdf'], 'file.pdf', { type: 'application/pdf' })
    const exampleJPG = new File(['example jpg image'], 'picture02.jpg', { type: 'image/jpeg' })
    const exampleDoc = new File([''], 'file.doc', { type: 'application/msword' })

    return (
        <div>
            <h1 className="mb-s">UploadedFileRow</h1>
            <p className="mb-l">A component that visually tracks the progress of an uploaded file, detached
            from <Link to="/docs/file-upload">FileUpload</Link> form control, which has started the process.</p>

            <div className="mb-m">
                <UploadedFileRow uploadedFile={{ file: examplePDF, uploadSuccessful: true }} />
                <UploadedFileRow uploadedFile={{ file: exampleJPG, progress: 90 }} />
                <UploadedFileRow uploadedFile={{ file: exampleDoc, error: 'Example error' }} />
            </div>

            <p className="mb-xl"><strong>Important: </strong>The API  of UploadedFileRow is heavily dependant on the API of the
            FileUpload component. The files, which the UploadedFileRow displays, are always expected to be the end result of processing
            in the FileUpload.</p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { UploadedFileRow } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    
    // Programmatically created files for demo purpose
    const examplePDF = new File(['example pdf'], 'file.pdf', { type: 'application/pdf'})
    const exampleJPG = new File(['example jpg image'], 'picture02.jpg', { type: 'image/jpeg'})
    const exampleDoc = new File([''], 'file.doc', { type: 'application/msword' })
 
    // The error text value of the last row serves no purpose, since the component does not display it.
    // The whole uploadedFile object is expected as the result of changes in the FileUpload component.
    return (
      <div>
        <UploadedFileRow uploadedFile={{ file: examplePDF, uploadSuccessful: true }} />
        <UploadedFileRow uploadedFile={{ file: exampleJPG, progress: 90 }} />    
        <UploadedFileRow uploadedFile={{ file: exampleDoc, error: 'Example error' }} />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>uploadedFile</td>
                        <td>{'{ file: File, error?: string, uploadSuccessful?: boolean, progress?: number }'}</td>
                        <td className="text-center">-</td>
                        <td>
                            Contains the data, necessary for the target uploaded file.
                                <br />
                            <br />
                               The 'error' property is inherited from the API of the <Link to="/docs/file-upload">FileUpload</Link> component.
                                Its text content is not reflected anywhere in the UploadedFileRow. It only works as a flag, that indicates
                                the error status of the uploading process.
                            </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default UploadedFileRowPage;
