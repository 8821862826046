import React from 'react';

import {
    ReactAux, MainContainerScrollable, Button, Modal, ModalScrollableContainer, Input,
    Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem, SimpleTableContainer,
    WizardStepsContainer, WizardStep, LoanOptionCardContainer, LoanOptionCard, InfoBox,
    Toolbar,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';

const loanOptionCards = [false, true, false, false];

class DemoModal extends React.Component {

    state = {
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false,
        modal6: false,
        modal7: false,
        modal8: false,
        modal9: false,
        modal10: false,
        modal11: false,
        modal12: false,
        modal13: false,
        modal14: false,
        text: <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce venenatis quam auctor tortor euismod, ut vehicula nisl iaculis. Cras dictum nisl vitae vulputate ultricies. Vivamus ac neque rutrum, tincidunt mi vitae, ultricies eros. In hac habitasse platea dictumst. Sed porttitor arcu eget condimentum vestibulum. Cras porttitor nibh in suscipit suscipit. Morbi suscipit nulla eget placerat hendrerit. Sed consectetur justo sit amet metus facilisis, nec bibendum mauris lacinia. Pellentesque dignissim pharetra turpis, non maximus quam consectetur nec. Aenean sit amet ligula ex. Vivamus quis iaculis urna. Praesent cursus nisi ut eleifend aliquam. Curabitur rhoncus nibh eget odio posuere, eu laoreet arcu lacinia. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc feugiat tellus eleifend magna iaculis, ut faucibus mauris euismod.</p>,
        rows: 5,
        tabIndex: 0,
        currentStepIndex: 0,
        loanOptionCards: loanOptionCards,
    }

    tableGenerator = () => {
        const array = Array.from(Array(this.state.rows > 0 ? this.state.rows : 0));

        return (
            <SimpleTableContainer>
                <table>
                    <thead>
                        <tr>
                            <th>Heading</th>
                            <th>Heading</th>
                            <th>Heading</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((m, i) => {
                            return <tr key={i}>
                                <td>Row Cell</td>
                                <td>Row Cell</td>
                                <td><Input /></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </SimpleTableContainer>
        );
    }

    onChangeLoanOption(index: number) {
        const { loanOptionCards } = this.state;

        loanOptionCards.map((c, i) => {
            if (i === index) return loanOptionCards[i] = true;
            return loanOptionCards[i] = false;
        });

        this.setState({ loanOptionCards });
    }

    render() {

        return (
            <ReactAux>
                <MainContainerScrollable>
                    <h1 className="mb-xl">Modals</h1>

                    <h3 className="mb-s">Modal sizes</h3>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal1: true })}>Modal size S</Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal2: true })}>Modal size M</Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal3: true })}>Modal size L</Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal4: true })}>Modal size XL</Button>


                    <Modal title="Modal size S" isVisible={this.state.modal1} size="S" onClose={() => this.setState({ modal1: false })}><div style={{ height: '200px', background: 'var(--color-select)' }} /></Modal>
                    <Modal title="Modal size M" isVisible={this.state.modal2} size="M" onClose={() => this.setState({ modal2: false })}><div style={{ height: '200px', background: 'var(--color-select)' }} /></Modal>
                    <Modal title="Modal size L" isVisible={this.state.modal3} size="L" onClose={() => this.setState({ modal3: false })}><div style={{ height: '200px', background: 'var(--color-select)' }} /></Modal>
                    <Modal title="Modal size XL" isVisible={this.state.modal4} size="XL" onClose={() => this.setState({ modal4: false })}><div style={{ height: '200px', background: 'var(--color-select)' }} /></Modal>

                    <h3 className="mb-s mt-xl">Modal with scrollable</h3>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal5: true })}>Modal</Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal6: true })}>Modal</Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal7: true })}>Modal with tabs</Button>

                    <Modal title="Modal" isVisible={this.state.modal5} size="L" onClose={() => this.setState({ modal5: false })}>
                        <ModalScrollableContainer>
                            <DemoLocationPage />
                            <DemoLocationPage />
                        </ModalScrollableContainer>
                    </Modal>
                    <Modal title="Modal" isVisible={this.state.modal6} size="XL" onClose={() => this.setState({ modal6: false })}>
                        <h1>Static</h1>
                        <h2 className="mb-xl">And more static</h2>
                        <div className="flex-full-height">
                            <>
                                <ModalScrollableContainer>
                                    <DemoLocationPage />
                                    <DemoLocationPage />
                                    <DemoLocationPage />
                                </ModalScrollableContainer>
                            </>
                        </div>
                    </Modal>
                    <Modal title="Modal with tabs" isVisible={this.state.modal7} size="M" onClose={() => this.setState({ modal7: false })}>
                        <Tabs onTabIndexChange={(index: number) => this.setState({ tabIndex: index })} currentlyActiveTabIndex={this.state.tabIndex}>
                            <TabList>
                                <TabItem>
                                    <TabItemTitle>Collateral</TabItemTitle>
                                </TabItem>
                                <TabItem>
                                    <TabItemTitle>Details</TabItemTitle>
                                </TabItem>
                                <TabItem>
                                    <TabItemTitle>Engagements</TabItemTitle>
                                </TabItem>
                            </TabList>
                            <TabContent>
                                <TabContentItem>
                                    <h3>Collateral</h3>
                                    <div style={{ height: '200px', background: 'var(--color-select)' }} />
                                </TabContentItem>
                                <TabContentItem>
                                    <ModalScrollableContainer>
                                        <DemoLocationPage />
                                        <DemoLocationPage />
                                    </ModalScrollableContainer>
                                </TabContentItem>
                                <TabContentItem>
                                    <h3 className="mb-m">Engagements</h3>
                                    <h4 className="mb-m">Lorem</h4>
                                    <ModalScrollableContainer>
                                        <div style={{ height: '1000px', background: 'var(--color-select)' }} />
                                    </ModalScrollableContainer>
                                </TabContentItem>
                            </TabContent>
                        </Tabs>
                    </Modal>

                    <h3 className="mb-s mt-xl">Modals with Expandable</h3>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal8: true })}>Modal size S</Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal9: true })}>Modal default size</Button>

                    <Modal title="Modal size S" isExpandable isVisible={this.state.modal8} size="S" onClose={() => this.setState({ modal8: false })}><div style={{ height: '200px', background: 'var(--color-select)' }} /></Modal>
                    <Modal title="Modal default size" isExpandable isVisible={this.state.modal9} onClose={() => this.setState({ modal9: false })}><div style={{ height: '200px', background: 'var(--color-select)' }} /></Modal>

                    <h3 className="mb-s mt-xl">Modals with dynamic content</h3>
                    <Button
                        className="mr-m"
                        icon="fal fa-pastafarianism"
                        onClick={() => {
                            this.setState({ modal10: true });
                            setTimeout(() => {
                                this.setState({
                                    text: (
                                        <>
                                            <p className="mb-m">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate iaculis ex vel mollis. Etiam hendrerit libero non arcu elementum, in tempus lacus sodales. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi feugiat vitae orci eu tempus. Praesent at aliquet sem. Fusce bibendum, felis eu facilisis condimentum, tortor neque molestie massa, eget faucibus lectus sem consectetur lacus. Proin sit amet leo iaculis, lacinia enim sit amet, mattis dolor. Quisque dignissim volutpat leo, sit amet convallis tellus. Suspendisse rhoncus eros sed quam tempus, vitae blandit leo bibendum. Nam lobortis vel mi vitae tempus. Vestibulum facilisis quis metus ut imperdiet. Curabitur eleifend augue ut urna pretium faucibus. Nullam tristique nulla et ipsum tincidunt sagittis. Morbi suscipit sapien sit amet sem congue, vel egestas augue convallis.</p>
                                            <p className="mb-m">Sed at magna neque. Duis et volutpat augue. Sed lobortis commodo enim, at pharetra elit dapibus ac. Maecenas interdum vitae nunc nec sodales. Duis egestas ante sed orci gravida tincidunt. Donec sit amet tellus vitae felis consectetur vestibulum in non sem. Etiam at massa in nibh fringilla tempor vitae eget mi. Suspendisse libero turpis, semper et placerat at, suscipit quis nibh. Mauris imperdiet volutpat tortor, eget scelerisque nulla hendrerit ac. Quisque molestie purus eu augue molestie hendrerit. Praesent ultrices, felis a volutpat porta, dolor diam dignissim magna, nec lacinia ante libero non risus. Donec id nisi urna.</p>
                                            <p className="mb-m">Mauris vehicula molestie enim, ac lobortis mauris consectetur eu. Cras tellus sapien, faucibus mollis malesuada ut, viverra blandit tortor. Aliquam ornare hendrerit tempor. Vestibulum interdum velit quam, at laoreet augue bibendum ac. Nunc sodales viverra sem, vitae condimentum lorem pretium a. Nunc pharetra urna porta lacus eleifend aliquam. Phasellus in nulla sollicitudin, tristique neque id, semper urna.</p>
                                            <p className="mb-m">Nullam placerat eleifend fringilla. Maecenas vel finibus augue. Nunc nec nisl faucibus, lacinia tortor non, ullamcorper est. Curabitur tristique justo a nunc porttitor, vitae convallis lectus vestibulum. Praesent sed ligula sed tortor interdum tempor. Nulla mollis sed felis et feugiat. Duis pretium finibus tortor at vulputate. Sed eleifend leo et metus tempus, eget porta tellus accumsan. Nam auctor eros et pretium bibendum.</p>
                                            <p className="mb-m">Donec sed ex eget orci hendrerit gravida vitae sit amet est. Proin at placerat ipsum, vel consequat nisl. Donec neque arcu, aliquam sit amet tristique ut, suscipit vel arcu. In pulvinar cursus massa, eget mattis libero tincidunt in. Donec eu nisi cursus, semper ipsum quis, pretium ante. Morbi ultrices vitae dolor quis faucibus. Nunc non accumsan lectus. Sed consectetur condimentum pulvinar. Aenean porta quis mauris nec pretium. Praesent commodo ante nibh, sit amet bibendum mi rutrum sed. Maecenas egestas arcu elit, ut convallis ex ornare sed. Pellentesque eget dui nulla. Donec eu facilisis ex, sed dignissim turpis. Suspendisse consequat, arcu nec iaculis fringilla, turpis metus feugiat lectus, vel tempor lacus odio sit amet lorem.</p>
                                            <p className="mb-m">Maecenas sodales, augue condimentum ultrices pretium, erat massa cursus magna, non porttitor lorem neque at enim. Fusce euismod ligula eu leo varius venenatis. Quisque at hendrerit felis. Integer laoreet ultricies felis, in mattis tortor laoreet mattis. Maecenas consectetur dictum dapibus. Nullam luctus nunc quis pretium aliquet. Vivamus ut elit elit. Proin ut tincidunt dui. Integer tempor malesuada diam sed dapibus. Nulla venenatis ac dui a fermentum. Ut at purus hendrerit, varius arcu et, lobortis enim.</p>
                                            <p className="mb-m">Donec id augue velit. Vestibulum dapibus risus in eros rhoncus aliquet. Vivamus varius sapien non sapien pretium commodo. Aliquam placerat mauris sit amet sapien scelerisque ultricies. Duis nec odio facilisis est finibus feugiat ut vehicula justo. Vestibulum porta vestibulum eros. Fusce placerat sapien at elit porta venenatis. Duis tristique, lectus tristique imperdiet pulvinar, odio ex auctor tortor, eu molestie massa mauris sed elit. Duis pretium viverra mi vitae pulvinar.</p>
                                        </>
                                    )
                                })
                            }, 3000)
                        }}
                    >
                        Modal
                    </Button>
                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal11: true })}>Modal</Button>

                    <Modal title="Modal" isExpandable isVisible={this.state.modal10} onClose={() => this.setState({ modal10: false })}>
                        <h1 className="mb-m">Static Title</h1>
                        <ModalScrollableContainer>
                            {this.state.text}
                        </ModalScrollableContainer>
                    </Modal>

                    <Modal title="Modal" isExpandable isVisible={this.state.modal11} onClose={() => this.setState({ modal11: false })}>
                        <div>
                            <Button className="mb-m mr-s" onClick={() => this.setState({ rows: this.state.rows + 1 })}>Add 1 row</Button>
                            <Button className="mb-m mr-s" onClick={() => this.setState({ rows: this.state.rows + 5 })}>Add 5 rows</Button>
                            <Button className="mb-m mr-s" onClick={() => this.setState({ rows: this.state.rows - 1 })}>Remove 1 row</Button>
                            <Button className="mb-m mr-s" onClick={() => this.setState({ rows: this.state.rows - 5 })}>Remove 5 rows</Button>
                        </div>
                        <ModalScrollableContainer>
                            {this.tableGenerator()}
                        </ModalScrollableContainer>
                    </Modal>

                    <Button className="mr-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal12: true })}>Modal with Wizard Steps</Button>

                    <Modal title="Loan Request Wizard" size="L" isExpandable isVisible={this.state.modal12} onClose={() => this.setState({ modal12: false })}>
                        <WizardStepsContainer
                            currentStepIndex={this.state.currentStepIndex}
                        >
                            <WizardStep title="Relationship" status="success" icon="fal fa-poll-people" onClick={() => this.setState({ currentStepIndex: 0 })} />
                            <WizardStep title="Parties" status="warning" icon="fal fa-users" onClick={() => this.setState({ currentStepIndex: 1 })} />
                            <WizardStep title="Collateral" status="warning" icon="fal fa-boxes" onClick={() => this.setState({ currentStepIndex: 2 })} disabled />
                            <WizardStep title="File" status="warning" icon="fal fa-file-alt" onClick={() => this.setState({ currentStepIndex: 3 })} disabled />
                        </WizardStepsContainer>
                        {this.state.currentStepIndex === 0 && <>
                            <ModalScrollableContainer>
                                <LoanOptionCardContainer
                                    cols={[
                                        { title: 'Description', percentage: 13 },
                                        { title: 'Loan Amount', percentage: 9 },
                                        { title: 'Base Payment', percentage: 9 },
                                        { title: 'Total Payment', percentage: 9 },
                                        { title: 'Term', percentage: 12 },
                                        { title: 'Payment Type', percentage: 13 },
                                        { title: 'Interest Rate', percentage: 13 },
                                        { title: 'APR', percentage: 6 },
                                        { title: 'Fees', percentage: 16 },
                                    ]}
                                >
                                    <LoanOptionCard
                                        onClick={() => this.onChangeLoanOption(0)}
                                        expanded={this.state.loanOptionCards[0]}
                                        checked={this.state.loanOptionCards[0]}
                                        cols={[
                                            '3 years',
                                            'US$960,000.00',
                                            'US$16,644.00',
                                            '500',
                                            '12 months',
                                            'Interest Only',
                                            '0% (Fixed)',
                                            '0.00%',
                                            '0',
                                        ]}
                                    >
                                        <div>Content...</div>
                                    </LoanOptionCard>
                                    <LoanOptionCard
                                        onClick={() => this.onChangeLoanOption(1)}
                                        checked={this.state.loanOptionCards[1]}
                                        cols={[
                                            '3 years',
                                            'US$960,000.00',
                                            'US$16,644.00',
                                            '500',
                                            '36 months',
                                            'P & I Due at Maturity',
                                            '0% (Fixed)',
                                            '0.00%',
                                            '0',
                                        ]}
                                    >
                                        <div>Content...</div>
                                    </LoanOptionCard>
                                    <LoanOptionCard
                                        onClick={() => this.onChangeLoanOption(2)}
                                        expanded={this.state.loanOptionCards[2]}
                                        checked={this.state.loanOptionCards[2]}
                                        cols={[
                                            '4 Year Line of Credit',
                                            '$10,000.00',
                                            '$1,166.96',
                                            '$1,166.96',
                                            '12 months',
                                            '4.750% Variable (WSJ Prime + 0.500%) 6.000% (Ceiling) 4.000$ (Floor)',
                                            '0% (Fixed)',
                                            '4.700%',
                                            '$25.00 Application Fee $150.00 New Feed',
                                        ]}
                                    >
                                        <div style={{ height: '500px', backgroundColor: 'var(--color-select)' }}>Content...</div>
                                    </LoanOptionCard>
                                    <LoanOptionCard
                                        onClick={() => this.onChangeLoanOption(3)}
                                        expanded={this.state.loanOptionCards[3]}
                                        checked={this.state.loanOptionCards[3]}
                                        disabled
                                        cols={[
                                            '3 years',
                                            'US$960,000.00',
                                            'US$16,644.00',
                                            '-',
                                            '36 months',
                                            'P & I Due at Maturity',
                                            '0% (Fixed)',
                                            '0.00%',
                                            '0',
                                        ]}
                                    >
                                        <div>Content...</div>
                                    </LoanOptionCard>
                                </LoanOptionCardContainer>
                            </ModalScrollableContainer>
                        </>}
                        {this.state.currentStepIndex === 1 && <>
                            <h2 className="mt-l mb-m">Parties</h2>
                            <ModalScrollableContainer>
                                <DemoLocationPage />
                                <DemoLocationPage />
                            </ModalScrollableContainer>
                        </>}
                        {this.state.currentStepIndex === 2 && <h2 className="mt-m">Collateral</h2>}
                        {this.state.currentStepIndex === 3 && <h2 className="mt-m">File</h2>}
                    </Modal>

                    <hr className="mt-xl" />

                    <h3 className="mb-s mt-xl">Specific modals</h3>
                    <InfoBox className="mb-s" type="danger">
                        Here are some examples of specific modals. <br />
                        When you need a modal with short info inside or a modal for confirmation, here is how they should look.
                    </InfoBox>

                    <Modal title="Loan Request Approval" size="S" isVisible={this.state.modal13} onClose={() => this.setState({ modal13: false })}>
                        <InfoBox>
                            This File has been submitted for approval. Close the window to continue.
                        </InfoBox>
                    </Modal>

                    <Button className="mr-m" icon="fal fa-info" btnType="secondary" onClick={() => this.setState({ modal13: true })}>Modal with short info</Button>

                    <Modal title="Loan Request Approval" size="S" isVisible={this.state.modal14} onClose={() => this.setState({ modal14: false })}>
                        <Toolbar className="mb-m"
                            leftSide={
                                <>
                                    <Button className="mr-s" icon="fal fa-check" btnType="secondary">Yes</Button>
                                    <Button icon="fal fa-ban" btnType="secondary">No</Button>
                                </>
                            }
                        />
                        <p>This File has been submitted for approval. Close the window to continue.</p>
                    </Modal>

                    <Button className="mr-m" icon="fal fa-question" btnType="secondary" onClick={() => this.setState({ modal14: true })}>Modal with confirmation</Button>

                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoModal;