import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import { FileUpload } from '@jkhy/vsg-loanvantage-design-system';

const savedFile = {
    file: new File(["file-from-database"], "file-from-database.txt", {
        type: "text/plain",
    })
};

class FileUploadPage extends React.Component {

    private fileUploadRef: any;
    private fileUploadRef2: any;
    private fileUploadRef3: any;
    private fileUploadRef4: any;
    private fileUploadRef5: any;

    state = {
        uploadedFiles: [savedFile]
    }

    handleOnChange = (files: any, ref: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                ref.onError(file);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    ref.updateProgress(file, progress);
                }, time / 100)

                if (ref === this.fileUploadRef5) {
                    this.setState({ uploadedFiles: undefined })
                }

                setTimeout(() => {
                    clearInterval(interval);
                    ref.onSuccess(file, () => {
                        if (ref === this.fileUploadRef5) {
                            this.setState({ uploadedFiles: [file] })
                        }
                    });
                }, time)
            }
        }
    }

    render() {
        return (
            <div>
                <h1 className="mb-s">FileUpload</h1>

                <p className="mb-s">Custom FileUpload component, enables to file upload through browsing or drag and drop.</p>
                <p className="mb-l">For documentation of the component, which displays only the progress of the uploaded files
                    refer to <Link to="/docs/uploaded-file-row">UploadedFileRow</Link>.</p>

                <FileUpload
                    className="mb-m"
                    fileUploadRef={(ref: any) => this.fileUploadRef = ref}
                    isMultiple
                    maxFileSizeMB={2}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadRef);
                    }}
                />

                <FileUpload
                    className="mb-m"
                    fileUploadRef={(ref: any) => this.fileUploadRef2 = ref}
                    acceptedFiles=".pdf"
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadRef2);
                    }}
                />

                <FileUpload
                    className="mb-m"
                    fileUploadRef={(ref: any) => this.fileUploadRef5 = ref}
                    uploadedFiles={this.state.uploadedFiles}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadRef5);
                    }}
                />

                <FileUpload
                    className="mb-m"
                    fileUploadRef={(ref: any) => this.fileUploadRef4 = ref}
                    disabled
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadRef4);
                    }}
                />

                <FileUpload
                    fileUploadRef={(ref: any) => this.fileUploadRef3 = ref}
                    isMultiple
                    maxFileSizeMB={2}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadRef3);
                    }}
                >
                    <h2>Child elements.</h2>
                    <p>When there are child elements, a file can be dropped on the whole section.</p>
                </FileUpload>

                <Highlight className="React">
                    {
                        `
import React from 'react';
import { FileUpload } from '@jkhy/vsg-loanvantage-design-system';

const savedFile = {
    file: new File(["file-from-database"], "file-from-database.txt", {
        type: "text/plain",
    })
};

class Example extends React.Component {

    private fileUploadRef: any;
    private fileUploadRef2: any;
    private fileUploadRef3: any;
    private fileUploadRef4: any;
    private fileUploadRef5: any;

    state = {
        uploadedFiles: [savedFile]
    }

    handleOnChange = (files: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                this.fileUploadRef.onError(file);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    this.fileUploadRef.updateProgress(file, progress);
                }, time / 100)

                if (ref === this.fileUploadRef5) {
                    this.setState({ uploadedFiles: undefined })
                }

                setTimeout(() => {
                    clearInterval(interval);
                    ref.onSuccess(file, () => {
                        if (ref === this.fileUploadRef5) {
                            this.setState({ uploadedFiles: [file] })
                        }
                    });
                }, time)
            }
        }
    }

    render() {
        return (
            <FileUpload
                fileUploadRef={(ref: any) => this.fileUploadRef = ref}
                isMultiple
                maxFileSizeMB={2}
                onChange={(files: any) => {
                    console.log(files, ' onChange - files');
                    this.handleOnChange(files);
                }}
            />

            <FileUpload
                fileUploadRef={(ref: any) => this.fileUploadRef2 = ref}
                acceptedFiles=".pdf"
                onChange={(files: any) => {
                    console.log(files, ' onChange - files');
                    this.handleOnChange(files, this.fileUploadRef2);
                }}
            />

            <FileUpload
                className="mb-m"
                fileUploadRef={(ref: any) => this.fileUploadRef5 = ref}
                uploadedFiles={this.state.uploadedFiles}
                onChange={(files: any) => {
                    console.log(files, ' onChange - files');
                    this.handleOnChange(files, this.fileUploadRef5);
                }}
            />

            <FileUpload
                disabled
                fileUploadRef={(ref: any) => this.fileUploadRef4 = ref}
                onChange={(files: any) => {
                    console.log(files, ' onChange - files');
                    this.handleOnChange(files, this.fileUploadRef4);
                }}
            />

            <FileUpload
                fileUploadRef={(ref: any) => this.fileUploadRef3 = ref}
                isMultiple
                maxFileSizeMB={2}
                onChange={(files: any) => {
                    console.log(files, ' onChange - files');
                    this.handleOnChange(files, this.fileUploadRef3);
                }}
            >
                <h2>Children elements.</h2>
                <p>When have children element you can drop file on the whole section.</p>
            </FileUpload>
        );
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>acceptedFiles</td>
                            <td>string</td>
                            <td>"image/*,.xlsx,.xls,.pdf,.doc,.docx"</td>
                            <td>Specify what file types the user can upload. Types need to be separate with <strong>comma</strong>.</td>
                        </tr>
                        <tr>
                            <td>isMultiple</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Specifies that the user is allowed to enter more than one file.</td>
                        </tr>
                        <tr>
                            <td>maxFileSizeMB</td>
                            <td>number</td>
                            <td>50</td>
                            <td>Set maximum file size to upload in MB.</td>
                        </tr>
                        <tr>
                            <td>isInAttachments</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Set it only when this component is used in 'Attachments'. When is true element get automatic min height.</td>
                        </tr>
                        <tr>
                            <td>disabled</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Set disabled state.</td>
                        </tr>
                        <tr>
                            <td>tabIndex</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>Sets the tabIndex attribute on the button.</td>
                        </tr>
                        <tr>
                            <td>uploadedFiles</td>
                            <td>array of objects 'fileObj': {'{file: File, error: string}'}</td>
                            <td className="text-center">-</td>
                            <td>Display successful uploaded files.</td>
                        </tr>
                        <tr>
                            <td>fileUploadRef</td>
                            <td>{'(ref) => void'}</td>
                            <td className="text-center">-</td>
                            <td>Callback who return reference to 'this' FileUpload.</td>
                        </tr>
                        <tr>
                            <td>onChange</td>
                            <td>{'(files: { file: File, error: string }[]) => void'}</td>
                            <td className="text-center">-</td>
                            <td>
                                Callback when user upload file. Returns array with objects. Object contains 'file' and 'error'.
                                <br />
                                <br />
                                Errors: 'invalid name', 'exceeded maximum size', 'invalid type' or null
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Ref Methods</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Props</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>onSuccess</td>
                            <td>{"fileObj: {file: File, error: string}, callback: () => void"}</td>
                            <td>Method which set success styles and remove file. Execute when file is success uploaded.</td>
                        </tr>
                        <tr>
                            <td>onError</td>
                            <td>{"fileObj: {file: File, error: string}"}</td>
                            <td>Method which set error styles and remove file. Execute when file contains error or upload is failing.</td>
                        </tr>
                        <tr>
                            <td>updateProgress</td>
                            <td>{"fileObj: {file: File, error: string}, progress: number"}</td>
                            <td>Update the progress bar for given file.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default FileUploadPage;
