import React from 'react';

import {
    MainContainerScrollable, VerticalTabs, VerticalTabList, VerticalTabItem,
    VerticalTabContent, VerticalTabContentItem,
    Button, Input, DragNDropSection, Droparea, DraggableItem, DragNDropContext,
    DraggableItemActions, DraggableItemTitle, DragNDropUtils,
    Modal, DragNDropScrollable, DragNDropSectionHeader,
    Row, Col, Toolbar,
} from '@jkhy/vsg-loanvantage-design-system';

type DnDColumnSelectionProps = {
    asyncData: boolean
};

type DnDColumnSelectionState = {
    isModalVisible: boolean,
    currentTabIndex: number,
    availableGeneralItems: { title: string, id: string, type: string }[],
    availableCollateralItems: { title: string, id: string, type: string }[],
    assignedItems: { title: string, id: string, type: string }[],
    dragStartEvent: Event | null,
    dragUpdateEvent: Event | null,
    availableGeneralSearch: string,
    availableCollateralSearch: string,
    assignedSearch: string,
}

const defaultGeneralAvailable = [
    { title: 'Analysis', id: 'item-1' }, { title: 'Branch', id: 'item-2' }, { title: 'CD Completed', id: 'item-3' }, { title: 'CD Required', id: 'item-4' },
    { title: 'CBA Completed', id: 'item-5' }, { title: 'CBA Required', id: 'item-6' }, { title: 'HDMA Reportable Closing Percent', id: 'item-7' }, { title: 'Competition', id: 'item-8' },
    { title: 'Create Date', id: 'item-9' }, { title: 'Days in Decisioning', id: 'item-10' }, { title: 'Days in Last Review', id: 'item-11' }, { title: 'General item 1', id: 'item-12' },
    { title: 'General item 2', id: 'item-13' }, { title: 'General item 3', id: 'item-14' }, { title: 'General item 4', id: 'item-15' }, { title: 'General item 5', id: 'item-16' }, { title: 'General item 6', id: 'item-17' }
];

const defaultCollateralAvailable = [
    { title: 'Collateral item 1', id: 'item-18' }, { title: 'Collateral item 2', id: 'item-19' }, { title: 'Collateral item 3', id: 'item-20' },
    { title: 'Collateral item 4', id: 'item-21' }, { title: 'Collateral item 5', id: 'item-12' }
];

class DnDColumnSelectionTabs extends React.Component<DnDColumnSelectionProps> {

    private static readonly AVAILABLE_GENERAL_SECTION_NAME = 'available-general';
    private static readonly AVAILABLE_COLLATERAL_SECTION_NAME = 'available-collateral';
    private static readonly ASSIGNED_SECTION_NAME = 'assigned';

    state: DnDColumnSelectionState = {
        isModalVisible: false,
        currentTabIndex: 0,
        availableGeneralItems: this.props.asyncData ? [] : defaultGeneralAvailable.map(e => { return { ...e, type: DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME } }),
        availableCollateralItems: this.props.asyncData ? [] : defaultCollateralAvailable.map(e => { return { ...e, type: DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME } }),
        assignedItems: [],
        dragStartEvent: null,
        dragUpdateEvent: null,
        availableGeneralSearch: '',
        availableCollateralSearch: '',
        assignedSearch: '',
    };

    constructor(props: DnDColumnSelectionProps) {
        super(props);
        if (this.props.asyncData) {
            setTimeout(() => {
                this.initializeState();
            }, 5000);
        }
    }

    // This method is invoked after some delay, when the asyncData flag is on,
    // to simulate asynchrounous data loading.
    private initializeState() {
        this.setState({
            availableGeneralItems: defaultGeneralAvailable.map(e => { return { ...e, type: DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME } }),
            availableCollateralItems: defaultCollateralAvailable.map(e => { return { ...e, type: DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME } }),
        });
    }

    updateTabIndex(newIndex: number) {
        if (this.state.currentTabIndex !== newIndex) {
            this.setState({ currentTabIndex: newIndex })
        }
    }

    moveAvailableItemToAssigned(type: string, index: number) {
        const { availableGeneralItems, availableCollateralItems, assignedItems } = this.state;
        const assignedClone = Array.from(assignedItems);
        let availableClone: { title: string, id: string, type: string }[] = [];
        let statePropName = '';
        if (type === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
            availableClone = Array.from(availableGeneralItems);
            statePropName = 'availableGeneralItems';
        } else if (type === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
            availableClone = Array.from(availableCollateralItems);
            statePropName = 'availableCollateralItems';
        }
        const [removed] = availableClone.splice(index, 1);
        assignedClone.push(removed);
        this.setState({ [statePropName]: availableClone, assignedItems: assignedClone });
    }

    returnItemToAvailable(type: string, index: number) {
        const { availableGeneralItems, availableCollateralItems, assignedItems } = this.state;
        const assignedClone = Array.from(assignedItems);
        let availableClone: { title: string, id: string, type: string }[] = [];
        let statePropName = '';
        if (type === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
            availableClone = Array.from(availableGeneralItems);
            statePropName = 'availableGeneralItems';
        } else if (type === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
            availableClone = Array.from(availableCollateralItems);
            statePropName = 'availableCollateralItems';
        }
        const [removed] = assignedClone.splice(index, 1);
        availableClone.push(removed);
        this.setState({ [statePropName]: availableClone, assignedItems: assignedClone });
    }

    addAllToAssigned(type: string) {
        const { availableGeneralItems, availableCollateralItems, assignedItems } = this.state;
        let assignedClone = Array.from(assignedItems);
        let availableClone: { title: string, id: string, type: string }[] = [];
        let statePropName = '';
        if (type === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
            availableClone = Array.from(availableGeneralItems);
            statePropName = 'availableGeneralItems';
        } else if (type === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
            availableClone = Array.from(availableCollateralItems);
            statePropName = 'availableCollateralItems';
        }
        assignedClone = [...assignedClone, ...availableClone];
        availableClone = [];

        this.setState({ [statePropName]: availableClone, assignedItems: assignedClone });
    }

    removeAllAssigned() {
        const { availableGeneralItems, availableCollateralItems, assignedItems } = this.state;
        let availableGeneralClone = Array.from(availableGeneralItems);
        let availableCollateralClone = Array.from(availableCollateralItems);
        let assignedClone = Array.from(assignedItems);
        for (const item of assignedClone) {
            if (item.type === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
                availableGeneralClone.push(item);
            } else if (item.type === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
                availableCollateralClone.push(item);
            }
        }
        this.setState({
            availableGeneralItems: availableGeneralClone,
            availableCollateralItems: availableCollateralClone,
            assignedItems: []
        });
    }

    getList(listId: string) {
        if (listId === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
            return this.state.availableGeneralItems;
        } else if (listId === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
            return this.state.availableCollateralItems;
        } else if (listId === DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME) {
            return this.state.assignedItems;
        }
        return null;
    }

    onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            this.setState({
                dragStartEvent: null,
                dragUpdateEvent: null,
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.availableGeneralItems,
                    source.index,
                    destination.index
                );
                this.setState({ availableGeneralItems: items });
            } else if (source.droppableId === DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.assignedItems,
                    source.index,
                    destination.index
                );
                this.setState({ assignedItems: items });
            } else if (source.droppableId === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.availableCollateralItems,
                    source.index,
                    destination.index
                );
                this.setState({ availableCollateralItems: items });
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                this.getList(source.droppableId) || [],
                this.getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME) {
                this.setState({ availableGeneralItems: result[0], assignedItems: result[1], })
            } else if (source.droppableId === DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME) {
                this.setState({ availableCollateralItems: result[0], assignedItems: result[1], })
            } else if (source.droppableId === DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME) {
                this.setState({ assignedItems: result[0], availableGeneralItems: result[1], })
            }
        }

        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: null
        });
    }

    handleDragStart(event: any) {
        this.setState({
            dragStartEvent: event,
            dragUpdateEvent: null,
        });
    }

    handleDragUpdate(event: any) {
        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: event,
        });
    }

    setSearchValue(sectionName: string, value: string) {
        let statePropName = '';
        switch (sectionName) {
            case DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME:
                statePropName = 'availableGeneralSearch';
                break;
            case DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME:
                statePropName = 'availableCollateralSearch';
                break;
            case DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME:
                statePropName = 'assignedSearch';
                break;
            default:
                return;
        }
        this.setState({ [statePropName]: value })
    }

    render() {

        return (
            <MainContainerScrollable>
                <Button className="mb-m" icon="fal fa-grip-vertical" onClick={() => this.setState({ isModalVisible: true })}>Drag-n-Drop Selection Modal</Button>
                <Modal title="Select columns" isVisible={this.state.isModalVisible} size="L" onClose={() => this.setState({ isModalVisible: false })}>
                    <Toolbar
                        className="mb-m"
                        leftSide={
                            <>
                                <Button icon="fal fa-save">Save</Button>
                            </>
                        }
                    />
                    <DragNDropContext onDragStart={this.handleDragStart.bind(this)} onDragUpdate={this.handleDragUpdate.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                        <Row>
                            <Col xs={7}>
                                <DragNDropScrollable>
                                    <VerticalTabs
                                        currentlyActiveTabIndex={this.state.currentTabIndex}
                                        onTabIndexChange={index => this.updateTabIndex(index)}
                                    >
                                        <VerticalTabList>
                                            <VerticalTabItem>General</VerticalTabItem>
                                            <VerticalTabItem>Collateral IUDF</VerticalTabItem>
                                            <VerticalTabItem>Complience</VerticalTabItem>
                                            <VerticalTabItem>Criticized Asset Data</VerticalTabItem>
                                            <VerticalTabItem>Facility</VerticalTabItem>
                                            <VerticalTabItem>Facility Funds</VerticalTabItem>
                                        </VerticalTabList>
                                        <VerticalTabContent>
                                            <VerticalTabContentItem>
                                                <DragNDropSection>
                                                    <DragNDropSectionHeader>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Available ({this.state.availableGeneralItems.length})</h2>
                                                            <Button onClick={() => this.addAllToAssigned(DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Add all to assigned</Button>
                                                        </div>
                                                        <Input
                                                            name="input-icon"
                                                            value={this.state.availableGeneralSearch}
                                                            onChange={(ev) => this.setSearchValue(DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <Droparea dropareaID={DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME} allowedItems={[DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                    >

                                                        {this.state.availableGeneralItems.map((item, index) => {
                                                            return (
                                                                !this.state.availableGeneralSearch || (this.state.availableGeneralSearch && item.title.includes(this.state.availableGeneralSearch)) ?
                                                                    <DraggableItem key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions>
                                                                            <DraggableItemTitle>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions>
                                                                            <Button onClick={() => this.moveAvailableItemToAssigned(item.type, index)} btnType="icon" icon='fal fa-arrow-right' data-tip="Move" />
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                    :
                                                                    ''
                                                            )
                                                        })}

                                                    </Droparea>

                                                </DragNDropSection>

                                            </VerticalTabContentItem>
                                            <VerticalTabContentItem>
                                                <DragNDropSection>
                                                    <DragNDropSectionHeader>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Available ({this.state.availableCollateralItems.length})</h2>
                                                            <Button onClick={() => this.addAllToAssigned(DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Add all to assigned</Button>
                                                        </div>
                                                        <Input
                                                            name="input-icon"
                                                            value={this.state.availableCollateralSearch}
                                                            onChange={(ev) => this.setSearchValue(DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <Droparea dropareaID={DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME} allowedItems={[DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                    >
                                                        {this.state.availableCollateralItems.map((item, index) => {
                                                            return (
                                                                !this.state.availableCollateralSearch || (this.state.availableCollateralSearch && item.title.includes(this.state.availableCollateralSearch)) ?
                                                                    <DraggableItem key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions>
                                                                            <DraggableItemTitle>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions>
                                                                            <Button onClick={() => this.moveAvailableItemToAssigned(item.type, index)} btnType="icon" icon='fal fa-arrow-right' data-tip="Move" />
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                    :
                                                                    ''
                                                            )
                                                        })}
                                                    </Droparea>
                                                </DragNDropSection>
                                            </VerticalTabContentItem>
                                            <VerticalTabContentItem>Complience content</VerticalTabContentItem>
                                            <VerticalTabContentItem>Criticized Asset Data content</VerticalTabContentItem>
                                            <VerticalTabContentItem>Facility content</VerticalTabContentItem>
                                            <VerticalTabContentItem>Facility Funds content</VerticalTabContentItem>
                                        </VerticalTabContent>
                                    </VerticalTabs>
                                </DragNDropScrollable>
                            </Col>
                            <Col xs={5}>
                                {/* It is important to provide the additional paddings,
                                in order to achieve exactly the design of this section */}
                                <DragNDropScrollable className="pt-s pb-s">
                                    <DragNDropSection>
                                        <DragNDropSectionHeader>
                                            <div className="d-flex justify-content-between align-items-center mb-m">
                                                <h2>Assigned ({this.state.assignedItems.length})</h2>
                                                <Button onClick={() => this.removeAllAssigned()} btnType="secondary" icon="fal fa-file-import">Remove all to assigned</Button>
                                            </div>
                                            <Input
                                                name="input-icon"
                                                value={this.state.assignedSearch}
                                                onChange={(ev) => this.setSearchValue(DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME, ev.target.value)}
                                                icon='fal fa-search'
                                            />
                                        </DragNDropSectionHeader>
                                        <Droparea
                                            dropareaID={DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME}
                                            allowedItems={[DnDColumnSelectionTabs.ASSIGNED_SECTION_NAME, DnDColumnSelectionTabs.AVAILABLE_GENERAL_SECTION_NAME, DnDColumnSelectionTabs.AVAILABLE_COLLATERAL_SECTION_NAME]}
                                            callToActionText="Drop your files here."
                                            dragStartEvent={this.state.dragStartEvent}
                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                        >
                                            {this.state.assignedItems.map((item, index) => {
                                                return (
                                                    !this.state.assignedSearch || (this.state.assignedSearch && item.title.includes(this.state.assignedSearch)) ?
                                                        <DraggableItem key={item.title} index={index} draggableID={item.id}>
                                                            <DraggableItemActions>
                                                                <DraggableItemTitle>{item.title}</DraggableItemTitle>
                                                            </DraggableItemActions>
                                                            <DraggableItemActions>
                                                                <Button onClick={() => this.returnItemToAvailable(item.type, index)} btnType="icon" icon='fal fa-times' data-tip="Remove" />
                                                            </DraggableItemActions>
                                                        </DraggableItem>
                                                        :
                                                        ''
                                                )
                                            })}
                                        </Droparea>
                                    </DragNDropSection>
                                </DragNDropScrollable>
                            </Col>
                        </Row>
                    </DragNDropContext>
                </Modal>
            </MainContainerScrollable>
        );
    }
}

export default DnDColumnSelectionTabs;