import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader,
    ActionContainerCrumbs, Button, MainContainerScrollable, Modal, ModalScrollableContainer, Toolbar, TabContent, TabContentItem, TabItem, TabItemTitle, TabList, Tabs,
} from '@jkhy/vsg-loanvantage-design-system';

import KendoTable from './KendoTable/KendoTable';


class DemoScroll7 extends React.Component {

    state = {
        modal: false,
        modalTabIndex: 0,
        pageTabIndex: 0,
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader className="mb-m">
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                        </ActionContainerHeader>
                        <Button icon="fal fa-pen" onClick={() => this.setState({ modal: true })}>
                            Open Modal with KendoTalbe
                        </Button>
                    </ActionContainer>
                </MainContainerHeader>

                <Tabs onTabIndexChange={(index: number) => this.setState({ pageTabIndex: index })} currentlyActiveTabIndex={this.state.pageTabIndex}>
                    <TabList>
                        <TabItem>
                            <TabItemTitle>KendoTable</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Notes</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent>
                        <TabContentItem>
                            <MainContainerScrollable>
                                <KendoTable />
                            </MainContainerScrollable>
                        </TabContentItem>
                        <TabContentItem>
                            <Toolbar className="mb-m"
                                leftSide={
                                    <Button icon="fal fa-plus">Add note</Button>
                                }
                            />
                            <MainContainerScrollable>
                                <h1>Notes tab</h1>
                            </MainContainerScrollable>
                        </TabContentItem>
                    </TabContent>
                </Tabs>

                <Modal
                    title="Modal with KendoTable"
                    size="M"
                    isVisible={this.state.modal}
                    onClose={() => this.setState({ modal: false })}
                >
                    <Toolbar className="mb-m"
                        leftSide={
                            <>
                                <Button className="mr-s" icon="fal fa-save">Save</Button>
                                <Button className="mr-s" icon="fal fa-save">Save and Close</Button>
                                <Button icon="fal fa-times-circle" btnType="secondary">Cancel</Button>
                            </>
                        }
                    />
                    <Tabs onTabIndexChange={(index: number) => this.setState({ modalTabIndex: index })} currentlyActiveTabIndex={this.state.modalTabIndex}>
                        <TabList>
                            <TabItem>
                                <TabItemTitle>KendoTable</TabItemTitle>
                            </TabItem>
                            <TabItem>
                                <TabItemTitle>Notes</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent>
                            <TabContentItem>
                                <ModalScrollableContainer>
                                    <KendoTable />
                                </ModalScrollableContainer>
                            </TabContentItem>
                            <TabContentItem>
                                <Toolbar className="mb-m"
                                    leftSide={
                                        <Button icon="fal fa-plus">Add note</Button>
                                    }
                                />
                                <ModalScrollableContainer>
                                    <h1>Notes tab</h1>
                                </ModalScrollableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>
            </ReactAux>
        );
    }
}

export default DemoScroll7;