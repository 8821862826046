import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, MainContainerScrollable, DrillDownContainer, DrillDown, Card, CardSection,
    Row, Col, Label, Textarea,
} from '@jkhy/vsg-loanvantage-design-system';


const cardSection = <CardSection title="Account">
    <Row>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label className="mr-s">Account Number</Label>
                <Label className="mr-s"><a className="lv-link-styles" href="/">Criticized Indy</a></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Account Type</Label>
                <Label><strong>LN</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Account Description</Label>
                <Label className="mr-s"><a className="lv-link-styles" href="/">Debt Service Ration</a></Label>
            </div>
        </Col>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label className="mr-s">Account Name</Label>
                <Label><strong>Test Owner</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Loan date</Label>
                <Label><strong>12/02/12</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Current balance</Label>
                <Label><strong>46200</strong></Label>
            </div>
        </Col>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label className="mr-s">Available balance</Label>
                <Label><strong>45000</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Other Liens</Label>
                <Label><strong>0</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Exposed amount</Label>
                <Label><strong>91700</strong></Label>
            </div>
        </Col>
    </Row>
</CardSection>;

const drillDowns = [
    {
        cols: ['Flood Test Collateral', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                className="mb-s"
                actionButton={<Button data-tip="View documents" btnType="icon" icon="fal fa-file-alt" />}
                fullWidth
            >
                {cardSection}
            </Card>,
    },
    {
        cols: ['Expanded Flood Test', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                className="mb-s"
                actionButton={<Button data-tip="View documents" btnType="icon" icon="fal fa-file-alt" />}
                fullWidth
            >
                {cardSection}
            </Card>
    },
    {
        cols: ['White House', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                className="mb-s"
                actionButton={<Button data-tip="View documents" btnType="icon" icon="fal fa-file-alt" />}
                fullWidth
            >
                {cardSection}
            </Card>
    },
    {
        cols: ['Flood Test', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                className="mb-s"
                actionButton={<Button data-tip="View documents" btnType="icon" icon="fal fa-file-alt" />}
                fullWidth
            >
                {cardSection}
            </Card>
    },
];

class DemoFileCollateral extends React.Component {

    state = {
        drillDownsState: [
            { isAcitve: false, expanded: false },
            { isAcitve: true, expanded: true },
            { isAcitve: false, expanded: false },
            { isAcitve: false, expanded: false },
        ],
        textareaDisabled: true,
        texareaValue: 'You can add your note here',
    }

    onExpand(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].isAcitve = !drillDownsState[index].isAcitve;
        drillDownsState[index].expanded = !drillDownsState[index].expanded;

        this.setState({ drillDownsState });
    }

    toggleExpandAll() {
        const { drillDownsState } = this.state;

        if (this.isAllExpanded()) {
            drillDownsState.forEach((c, i) => {
                drillDownsState[i].isAcitve = false;
                drillDownsState[i].expanded = false;
            });
        } else {
            drillDownsState.forEach((c, i) => {
                drillDownsState[i].isAcitve = true;
                drillDownsState[i].expanded = true;
            });
        }

        this.setState({ drillDownsState });
    }

    isAllExpanded() {
        const { drillDownsState } = this.state;

        for (let i = 0; i < drillDownsState.length; i++) {
            if (drillDownsState[i].expanded === false) return false;
        }

        return true;
    }

    render() {

        const { drillDownsState, textareaDisabled, texareaValue } = this.state;

        return (
            <ReactAux>
                <MainContainerHeader>
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={['FileCollateral']} />
                        </ActionContainerHeader>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <DrillDownContainer
                        className="mt-m mb-m"
                        actionBtnRender={() => <Button btnType="icon" icon={`fal fa-${this.isAllExpanded() ? 'compress' : 'expand'}-alt`} data-tip={this.isAllExpanded() ? 'Compress' : 'Expand'} onClick={() => this.toggleExpandAll()} />}
                        cols={[
                            { title: 'Collateral descriotion', percentage: 13, textAlign: 'left' },
                            { title: 'Collateral ID', percentage: 8, textAlign: 'left' },
                            { title: 'Valuation Type', percentage: 11, textAlign: 'left' },
                            { title: 'Value', percentage: 8, textAlign: 'right' },
                            { title: 'Date of Valuation', percentage: 11, textAlign: 'left' },
                            { title: 'Max Allowable Security', percentage: 13, textAlign: 'right' },
                            { title: 'Current Balance', percentage: 11, textAlign: 'right' },
                            { title: 'Available Balance', percentage: 11, textAlign: 'right' },
                            { title: 'Current Loans', percentage: 11, textAlign: 'right' },
                        ]}
                        footerCols={['Totals', '', '', '', '', '', '1,820,000', '1,848,000', '3,880,000']}
                    >
                        {drillDowns.map((d, i) =>
                            <DrillDown
                                key={i}
                                cols={d.cols}
                                isActive={drillDownsState[i].isAcitve}
                                expanded={drillDownsState[i].expanded}
                                onArrowClick={() => this.onExpand(i)}
                                dataUI={i + ''}
                            >
                                {d.content}
                            </DrillDown>
                        )}
                    </DrillDownContainer>

                    <div className="d-flex mb-s">
                        <Label className="mr-s"><strong>Notes</strong></Label>
                        {textareaDisabled && <Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => this.setState({ textareaDisabled: false })} />}
                        {!textareaDisabled && <>
                            <Button className="mr-s" icon="fal fa-save" onClick={() => this.setState({ textareaDisabled: true })}>Save</Button>
                            <Button btnType="secondary" icon="fal fa-times-circle" onClick={() => this.setState({ textareaDisabled: true })}>Cancel</Button>
                        </>}
                    </div>
                    <Textarea disabled={textareaDisabled} value={texareaValue} onChange={(e) => this.setState({ texareaValue: e.target.value })} />
                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoFileCollateral;