import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import {
    Button, ShowImage,
} from '@jkhy/vsg-loanvantage-design-system';


class ShowImagePage extends React.Component {

    render() {
        return (
            <div>
                <h1 className="mb-s">ShowImage</h1>

                <p className="mb-l">Helper for showing image with some actions. See demo <Link className="lv-link-styles" to="/demo/show-image">here</Link>.</p>

                <div className="d-flex mb-m">
                    <ShowImage
                        onClickAddBtn={() => alert('Add buttons is clicked')}
                    />
                </div>

                <div className="d-flex mb-m">
                    <ShowImage
                        image={{ src: process.env.PUBLIC_URL + '/img/show-image.jpeg' }}
                        helperBtns={<>
                            <Button className="mb-s" btnType="icon" icon="fal fa-search-plus" data-tip="Zoom" />
                            <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" />
                        </>}
                    />
                </div>

                <Highlight className="React">
                    {
                        `
import React from 'react';

import { Button, ShowImage, } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    render() {
        return (
            <div>
                <div className="d-flex mb-m">
                    <ShowImage
                        onClickAddBtn={() => alert('Add buttons is clicked')}
                    />
                </div>

                <div className="d-flex mb-m">
                    <ShowImage
                        image={{ src: process.env.PUBLIC_URL + '/img/show-image.jpeg' }}
                        helperBtns={<>
                            <Button className="mb-s" btnType="icon" icon="fal fa-search-plus" data-tip="Zoom" />
                            <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" />
                        </>}
                    />
                </div>
            </div>
        );
    }
}

export default Example;
            `}
                </Highlight>


                <h2 className="mb-m mt-l">API</h2>

                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>onClickAddBtn</td>
                            <td>() {'=>'} void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the add button is clicked.</td>
                        </tr>
                        <tr>
                            <td>image</td>
                            <td>object with: "img": string, "alt": string</td>
                            <td className="text-center">-</td>
                            <td>Sets the image. If have image will hide 'add button' and show 'helperBtns'.</td>
                        </tr>
                        <tr>
                            <td>helperBtns</td>
                            <td>JSX.Element</td>
                            <td className="text-center">-</td>
                            <td>Sets helper buttons for image actions.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
};

export default ShowImagePage;
