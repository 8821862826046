import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';
import { Button, Popover } from '@jkhy/vsg-loanvantage-design-system';


const PopoverPage: React.FC = () => {

    const [popover1, setPopover1] = useState(false);
    const [popover2, setPopover2] = useState(false);
    const [popover3, setPopover3] = useState(false);
    const [popover4, setPopover4] = useState(false);
    const [popover5, setPopover5] = useState(false);
    const [popover6, setPopover6] = useState(false);

    return (
        <div>
            <h1 className="mb-s">Popover</h1>
            <p className="mb-s">
                The Popover is a pop-up element, which contains additional text information and is toggled on click. It supports
                vertical and horizontal positions, that would be further adjusted depending on the available space. For example, if
                the explicit position is <i>"top"</i> and there is not enough space above the host element, the Popover will be rendered
                with bottom positioning.
            </p>
            <p className="mb-l">
                Compared to other components, the Popover wraps more content than the <Link to="/docs/tooltip">Tooltip</Link>, but if that content is large or if some actions
                 are required, the <Link to="/docs/modal">Modal</Link> would be a better component for the case.
            </p>


            <Popover
                title="Popover with position: top"
                show={popover1}
                position="top"
                render={() => (
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                )}
                onClose={() => setPopover1(false)}
            >
                <Button onClick={() => setPopover1(true)} btnType="secondary" icon="fal fa-info" className="mr-s">Position top</Button>
            </Popover>

            <Popover
                title="Popover with position: bottom"
                show={popover2}
                position="bottom"
                render={() => (
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                )}
                onClose={() => setPopover2(false)}
            >
                <Button onClick={() => setPopover2(true)} btnType="secondary" icon="fal fa-info" className="mr-s">Position bottom</Button>
            </Popover>

            <Popover
                title="Popover with position: left"
                show={popover3}
                position="left"
                render={() => (
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                )}
                onClose={() => setPopover3(false)}
            >
                <Button onClick={() => setPopover3(true)} btnType="secondary" icon="fal fa-info" className="mr-s">Position left</Button>
            </Popover>

            <Popover
                title="Popover with position: right"
                show={popover4}
                position="right"
                render={() => (
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                )}
                onClose={() => setPopover4(false)}
            >
                <Button onClick={() => setPopover4(true)} btnType="secondary" icon="fal fa-info" className="mr-s">Position right</Button>
            </Popover>

            <Popover
                show={popover5}
                render={() => (
                    <p>
                        This Popover does not have a title.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                )}
                onClose={() => setPopover5(false)}
            >
                <Button onClick={() => setPopover5(true)} btnType="secondary" icon="fal fa-info" className="mr-s">Without title</Button>
            </Popover>

            <Popover
                title="Long text"
                show={popover6}
                render={() => (
                    <p>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that
                        it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like
                        readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many
                        web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </p>
                )}
                onClose={() => setPopover6(false)}
            >
                <Button onClick={() => setPopover6(true)} btnType="secondary" icon="fal fa-info">With long text</Button>
            </Popover>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { Popover, Button } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {

    const [popover1, setPopover1] = useState(false);
    const [popover2, setPopover2] = useState(false);
    const [popover3, setPopover3] = useState(false);
    const [popover4, setPopover4] = useState(false);
    const [popover5, setPopover5] = useState(false);
    const [popover6, setPopover6] = useState(false);

    return (
      <div>
        <Popover
            title="Popover with position: top"
            show={popover1}
            position="top"
            render={() => <p>Lorem ipsum dolor...</p>}
            onClose={() => setPopover1(false)}
        >
            <Button onClick={() => setPopover1(true)} btnType="secondary" icon="fal fa-info" className="mr-s">
                Position top
            </Button>
        </Popover>

        <Popover
            title="Popover with position: bottom"
            show={popover2}
            position="bottom"
            render={() => <p>Lorem ipsum dolor...</p>}
            onClose={() => setPopover2(false)}
        >
            <Button onClick={() => setPopover2(true)} btnType="secondary" icon="fal fa-info" className="mr-s">
                Position bottom
            </Button>
        </Popover>

        <Popover
            title="Popover with position: left"
            show={popover3}
            position="left"
            render={() => <p>Lorem ipsum dolor...</p>}
            onClose={() => setPopover3(false)}
        >
            <Button onClick={() => setPopover3(true)} btnType="secondary" icon="fal fa-info" className="mr-s">
                Position left
            </Button>
        </Popover>

        <Popover
            title="Popover with position: right"
            show={popover4}
            position="right"
            render={() => <p>Lorem ipsum dolor...</p>}
            onClose={() => setPopover4(false)}
        >
            <Button onClick={() => setPopover4(true)} btnType="secondary" icon="fal fa-info" className="mr-s">
                Position right
            </Button>
        </Popover>

        <Popover
            show={popover5}
            render={() => (
                <p>
                    This Popover does not have a title.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
            )}
            onClose={() => setPopover5(false)}
        >
            <Button onClick={() => setPopover5(true)} btnType="secondary" icon="fal fa-info" className="mr-s">
                Without title
            </Button>
        </Popover>

        <Popover
            title="Long text"
            show={popover6}
            render={() => <p>It is a long established fact that...</p>}
            onClose={() => setPopover6(false)}
        >
            <Button onClick={() => setPopover6(true)} btnType="secondary" icon="fal fa-info">
                With long text
            </Button>
        </Popover>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title of the Popover. The close button is rendered, depending on whether a title is provided.</td>
                    </tr>
                    <tr>
                        <td>show</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Toggles the Popover.</td>
                    </tr>
                    <tr>
                        <td>position</td>
                        <td>'top' | 'bottom' | 'left' | 'right'</td>
                        <td>'top'</td>
                        <td>Sets the positioning of the Popover. If there is not enough space in the given direction,
                        the opposite one will be set automatically.
                        </td>
                    </tr>
                    <tr>
                        <td>render</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>The content of the Popover.</td>
                    </tr>
                    <tr>
                        <td>onClose</td>
                        <td>{'() => void'}</td>
                        <td className="text-center">-</td>
                        <td>Callback when the close button or the overlay is clicked.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PopoverPage;
