import React from 'react';
import Highlight from 'react-highlight';
import { Button, Textarea, ReactAux } from '@jkhy/vsg-loanvantage-design-system';

class FormExamples extends React.Component {

    state: { subFormEditable: boolean };
    private subFormTextareaWrapperRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            subFormEditable: false
        };
        this.subFormTextareaWrapperRef = React.createRef();
    }

    setSubFormEditable(isEditable: boolean) {
        this.setState({
            subFormEditable: isEditable
        }, () => {
            if (this.state.subFormEditable) {
                const textarea = this.subFormTextareaWrapperRef.current.querySelector('textarea');
                textarea.focus();
            }
        });
    }


    render() {
        return (
            <div>
                <h1 className="mb-s">Form examples</h1>
                <p className="mb-l">Showcasing configuration for specific form/form controls scenarios.</p>

                <h2 className="mb-m">Editable sub-form:</h2>
                <div className="d-flex align-items-center mb-s">
                    <h3 className="mr-s">Notes</h3>
                    <div>
                        {this.state.subFormEditable ?
                            (
                                <ReactAux>
                                    <Button className="mr-s" icon='fal fa-save' onClick={() => this.setSubFormEditable(false)}>Save</Button>
                                    <Button btnType="secondary" icon='fal fa-times' onClick={() => this.setSubFormEditable(false)}>Cancel</Button>
                                </ReactAux>
                            ) : <Button btnType="icon" icon='fal fa-pen' data-tip="Edit" onClick={() => this.setSubFormEditable(true)} />
                        }
                    </div>
                </div>
                <div ref={this.subFormTextareaWrapperRef}>
                    <Textarea disabled={!this.state.subFormEditable} />
                </div>

                <Highlight className="React">
                    {
                        `
import React from 'react';
import { Button, Textarea, ReactAux } from '@jkhy/vsg-loanvantage-design-system';

class EditableSubForm extends React.Component {

    state: { subFormEditable: boolean };
    private subFormTextareaWrapperRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            subFormEditable: false
        };
        this.subFormTextareaWrapperRef = React.createRef();
    }
    return (
        <ReactAux>
            <div className="d-flex align-items-center mb-s">
                <h3 className="mr-s">Notes</h3>
                <div>
                    {this.state.subFormEditable ?
                        (
                            <ReactAux>
                                <Button className="mr-s" icon='fal fa-save' 
                                    onClick={() => this.setSubFormEditable(false)}>Save</Button>
                                <Button btnType="secondary" icon='fal fa-times' 
                                    onClick={() => this.setSubFormEditable(false)}>Cancel</Button>
                            </ReactAux>
                        ) : <Button btnType="icon" icon='fal fa-pen' data-tip="Edit" onClick={() => this.setSubFormEditable(true)} />
                    }
                </div>
            </div>

            // The Textarea component is a function component and cannot host a 'ref' prop
            <div ref={this.subFormTextareaWrapperRef}>
                <Textarea disabled={!this.state.subFormEditable} />
            </div>
        </ReactAux>
    );
}

export default EditableSubForm;
    `}
                </Highlight>
            </div>
        )
    }
}

export default FormExamples;
