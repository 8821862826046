import React from 'react';
import {
    TopSection, LeftBarCrumbs, ScrollableContainer, Collapse, BottomSection, Tabs,
    TabList, TabItem, TabItemTitle, TabContent, TabContentItem, Checkbox, Button,
    ReactAux, PartyNavigationItem, ChecklistActions, ChecklistContent, ChecklistItem, AttachmentsContent,
    Attachment, Icon, FileUpload,
} from '@jkhy/vsg-loanvantage-design-system';

const collapseItems = [
    {
        title: 'BFT 15977', expanded: false, content: <ReactAux>
            <PartyNavigationItem render={() => <a href="/">Profile</a>} />
            <PartyNavigationItem isActive render={() => <a href="/">Relationships</a>} />
            <PartyNavigationItem render={() => <a href="/">Engagements</a>} />
        </ReactAux>
    },
    {
        title: 'First Southeast Insurance Services, Inc', expanded: false, secondLevel: [
            {
                title: 'Financial Spreading', expanded: false, content:
                    <ReactAux>
                        <PartyNavigationItem render={() => <a href="/">RMA Ratios</a>} />
                        <PartyNavigationItem render={() => <a href="/">Proforma Debts</a>} />
                        <PartyNavigationItem render={() => <a href="/">Debt Service Overlays</a>} />
                    </ReactAux>
            },
            {
                title: 'Tax Returns', expanded: false, content:
                    <ReactAux>
                        <PartyNavigationItem render={() => <a href="/">1040</a>} />
                        <PartyNavigationItem render={() => <a href="/">Tax Return Analysis</a>} />
                    </ReactAux>
            },
            {
                title: 'Financial Spreading', expanded: false, content:
                    <ReactAux>
                        <PartyNavigationItem render={() => <a href="/">Personal Cash Flow</a>} />
                        <PartyNavigationItem render={() => <a href="/">Financial Covenants (Personal)</a>} />
                    </ReactAux>
            },
        ]
    },
    {
        title: 'BANKATLANTIC TEST', expanded: false, content:
            <ReactAux>
                <PartyNavigationItem render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem render={() => <a href="/">Engagements</a>} />
            </ReactAux>
    },
    {
        title: 'New Businss Party', expanded: false, content:
            <ReactAux>
                <PartyNavigationItem render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem render={() => <a href="/">Engagements</a>} />
                <PartyNavigationItem render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem render={() => <a href="/">Engagements</a>} />
                <PartyNavigationItem render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem render={() => <a href="/">Engagements</a>} />
            </ReactAux>
    },
    {
        title: 'ALVIN BYRNE', expanded: false, content:
            <ReactAux>
                <PartyNavigationItem render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem render={() => <a href="/">Engagements</a>} />
            </ReactAux>
    },
];

const attachments = [
    { animation: true, title: <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }} >picture4.jpg</a>, renderBtn: <Button btnType="icon" icon='fal fa-times' data-tip="Delete" data-place="top" /> },
    { animation: false, title: <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>picture3.jpg</a>, renderBtn: <Button btnType="icon" icon='fal fa-times' data-tip="Delete" data-place="top" /> },
    { animation: false, title: <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>picture2.jpg</a>, renderBtn: <Button btnType="icon" icon='fal fa-times' data-tip="Delete" data-place="top" /> },
    { animation: false, title: <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }} >picture1.jpg</a>, renderBtn: <Button btnType="icon" icon='fal fa-times' data-tip="Delete" data-place="top" /> }
]

const getActivityChecklistDataTip = (isChecked: any) => {
    return isChecked ? 'Completed' : 'Opened'
}

export default class DemoSectionsForLeftSideBar extends React.Component {
    private fileUploadRef: any;

    state = {
        collapseItems: collapseItems,
        activityChecklistTabIndex: 0,
        attachments: attachments.map((at: any, i: any) => { at['key'] = i; return at }),
        activityChecklistStatuses: [
            { isChecked: false }, { isChecked: true }, { isChecked: false }, { isChecked: false }, { isChecked: false }
        ]
    };


    onCollapseItemClick = (i: any) => {
        const collapseItems = this.state.collapseItems;
        const currentItem = collapseItems[i];

        if (currentItem.expanded === true) {
            collapseItems[i].expanded = false;
        } else {

            collapseItems.forEach((c: any) => c.expanded = false);

            collapseItems[i].expanded = true;
        }

        this.setState({
            collapseItems
        });
    }

    onCollapseSecondItemClick = (i: any, j: any) => {
        const collapseItems = this.state.collapseItems;
        const currentItemParent = collapseItems[i].secondLevel;

        if (currentItemParent) {
            const currentItem = currentItemParent[j];

            if (currentItem.expanded === true) {
                currentItemParent[j].expanded = false;
            } else {
                currentItemParent.forEach((c: any) => c.expanded = false);

                currentItemParent[j].expanded = true;
            }
        }

        this.setState({
            collapseItems
        });
    }

    onUpdateAnimation = (possition: any) => {
        const attachmentsCopy = this.state.attachments;

        if (attachmentsCopy[possition].animation) {
            attachmentsCopy[possition].animation = false;

            this.setState({
                attachments: attachmentsCopy
            });
        }
    }

    onAddNewAttachment = (fileName: string) => {
        const attachmentsCopy = this.state.attachments.map((at: any, i: any) => { at['key'] = i; return at });
        const element = {
            key: [attachmentsCopy.length + 1],
            animation: true,
            title: <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }} >{fileName}</a>,
            renderBtn: <Button btnType="icon" icon='fal fa-times' data-tip="Delete" data-place="top" />
        };

        this.setState({
            attachments: [
                element,
                ...attachmentsCopy,
            ]
        })
    }

    handleFileUpload = (files: any, ref: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                ref.onError(file);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    ref.updateProgress(file, progress);
                }, time / 100)

                setTimeout(() => {
                    clearInterval(interval);
                    ref.onSuccess(file);
                    this.onAddNewAttachment(file.file.name);
                }, time)
            }
        }
    }

    onChangeActivitiChecklistStatus = (index: any) => {
        const copyStatuses = [...this.state.activityChecklistStatuses];
        copyStatuses[index].isChecked = !copyStatuses[index].isChecked;
        this.setState({
            activityChecklistStatuses: copyStatuses
        });
    }

    render() {
        const loaction = window.location;

        return (
            <ReactAux>
                <TopSection>
                    <LeftBarCrumbs
                        className="mb-m lv-side-bar-neggative-margin"
                        links={[<a href="/">Fallow Fruits</a>, <a href="/">36465: Loan Request</a>]} />
                    <ScrollableContainer>
                        {this.state.collapseItems.map((item: any, i: any) =>
                            <Collapse
                                key={i}
                                title={item.title}
                                expanded={item.expanded}
                                onClick={() => this.onCollapseItemClick(i)}
                                rightIcon="fal fa-user"
                            >
                                {item.content || item.secondLevel?.map((secondItem: any, j: any) =>
                                    <Collapse
                                        key={j}
                                        title={secondItem.title}
                                        expanded={secondItem.expanded}
                                        onClick={() => this.onCollapseSecondItemClick(i, j)}
                                    >
                                        {secondItem.content}
                                    </Collapse>
                                )}
                            </Collapse>
                        )}
                    </ScrollableContainer>
                </TopSection >
                {loaction.search !== '?only-top' && <BottomSection>
                    <Tabs currentlyActiveTabIndex={this.state.activityChecklistTabIndex} onTabIndexChange={(index: number) => this.setState({ activityChecklistTabIndex: index })}>
                        <TabList>
                            <TabItem>
                                <TabItemTitle>Checklist</TabItemTitle>
                            </TabItem>
                            <TabItem>
                                <TabItemTitle>Attachments</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent className={`${this.state.activityChecklistTabIndex === 1 && 'p-0'}`}>
                            <TabContentItem>
                                <ChecklistActions title="Show only:">
                                    <Checkbox name="parents-check">Parents</Checkbox>
                                    <Checkbox name="unfinished-tasks-check">Unfinished tasks</Checkbox>
                                </ChecklistActions>
                            </TabContentItem>
                            <TabContentItem>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                    <ScrollableContainer>
                        {this.state.activityChecklistTabIndex === 0 &&
                            <ChecklistContent>
                                <ChecklistItem
                                    title="Group task name"
                                    assignedTo="BL Approval Team 3"
                                    renderBtn={<Button btnType="icon" icon='fal fa-file-alt' data-tip="Some Action" data-place="top" />}
                                    renderStatus={<Checkbox
                                        data-tip={getActivityChecklistDataTip(this.state.activityChecklistStatuses[0].isChecked)}
                                        data-place="top"
                                        checked={this.state.activityChecklistStatuses[0].isChecked}
                                        onChange={() => this.onChangeActivitiChecklistStatus(0)}
                                    />}
                                    onClick={() => console.log('click span')}
                                    contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                >
                                    <ChecklistItem
                                        title="Subtask name 1"
                                        assignedTo="BL Approval Team 3"
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        renderStatus={<Checkbox
                                            data-tip={getActivityChecklistDataTip(this.state.activityChecklistStatuses[1].isChecked)}
                                            data-place="top"
                                            checked={this.state.activityChecklistStatuses[1].isChecked}
                                            onChange={() => this.onChangeActivitiChecklistStatus(1)}
                                        />}
                                        onClick={() => console.log('click span 2')}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        title="Subtask name 2"
                                        assignedTo="BL Approval Team 3"
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        renderStatus={<Checkbox disabled checked data-tip="Winged" data-place="top" />}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem title="Subtask name 3" assignedTo="BL Approval Team 3"
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        renderStatus={<Checkbox disabled data-tip="Opened" data-place="top" />}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />

                                    <ChecklistItem
                                        title={<a href='/' onClick={(e) => { e.preventDefault(); console.log('click href 1') }}>Subtask Link</a>}
                                        assignedTo={<a href='/' onClick={(e) => { e.preventDefault(); console.log('click href 2') }}>Approval Team 2</a>}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        renderStatus={<Icon size="S" icon="far fa-ban" data-tip="Cancelled" data-place="top" />}
                                    />
                                </ChecklistItem>

                                <ChecklistItem
                                    title={<a href="/" onClick={(e) => { e.preventDefault(); console.log('link parent') }}>Group task name</a>}
                                    assignedTo="BL Approval Team 3"
                                    renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                    renderStatus={<Icon size="S" icon="far fa-check-double" data-tip="N/A" data-place="top" />}
                                    contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                >
                                    <ChecklistItem
                                        title="Subtask name 1"
                                        assignedTo="BL Approval Team 3"
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        renderStatus={<Checkbox
                                            data-tip={getActivityChecklistDataTip(this.state.activityChecklistStatuses[2].isChecked)}
                                            data-place="top"
                                            onChange={() => this.onChangeActivitiChecklistStatus(2)}
                                            checked={this.state.activityChecklistStatuses[2].isChecked}
                                        />
                                        }
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        title="Subtask name 2"
                                        assignedTo="BL Approval Team 3"
                                        renderStatus={<Checkbox
                                            data-tip={getActivityChecklistDataTip(this.state.activityChecklistStatuses[3].isChecked)}
                                            data-place="top"
                                            onChange={() => this.onChangeActivitiChecklistStatus(3)}
                                            checked={this.state.activityChecklistStatuses[3].isChecked}
                                        />}
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        title="Subtask name 3"
                                        assignedTo="BL Approval Team 3"
                                        renderStatus={<Checkbox
                                            data-tip={getActivityChecklistDataTip(this.state.activityChecklistStatuses[4].isChecked)}
                                            data-place="top"
                                            onChange={() => this.onChangeActivitiChecklistStatus(4)}
                                            checked={this.state.activityChecklistStatuses[4].isChecked}
                                        />}
                                        renderBtn={<Button btnType="icon" data-tip="Some Action" data-place="top" icon='fal fa-file-alt' />}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                </ChecklistItem>

                            </ChecklistContent>
                        }

                        {this.state.activityChecklistTabIndex === 1 &&
                            <>
                                <FileUpload
                                    fileUploadRef={(ref: any) => this.fileUploadRef = ref}
                                    maxFileSizeMB={2}
                                    isMultiple
                                    isInAttachments
                                    onChange={(files: any) => {
                                        this.handleFileUpload(files, this.fileUploadRef);
                                    }}
                                >
                                    <AttachmentsContent
                                    >
                                        {this.state.attachments.map((at, i) => <Attachment key={at.key}  {...at} onUpdateAnimation={() => this.onUpdateAnimation(i)} />)}
                                    </AttachmentsContent>
                                </FileUpload>
                            </>
                        }
                    </ScrollableContainer>
                </BottomSection>}
            </ReactAux>
        )
    }
}