import React from 'react';
import { useState, useRef } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Modal, Badge, SimpleTableContainer, MainContainerScrollable, ModalScrollableContainer,
    Toolbar, TabContent, TabContentItem, TabItem, TabItemTitle, TabList, Tabs, Label, Col, Row,
    Radio, Select, Input, Checkbox, ConfirmationDialog, Icon, Textarea, Attachment,
    AttachmentsContent, FileUpload, InfoBox,
} from '@jkhy/vsg-loanvantage-design-system';

const DemoCovenant = () => {

    const [editCovenantModal, setEditCovenantModal] = useState(false);
    const [addCovenantModal, setAddCovenantModal] = useState(false);
    const [editMeasurementModal, setEditMeasurementModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndexMeasurement, setTabIndexMeasurement] = useState(0);
    const [confirmationDialogShow1, setConfirmationDialogShow1] = useState(false);
    const [confirmationDialogShow2, setConfirmationDialogShow2] = useState(false);
    const [confirmationDialogShow3, setConfirmationDialogShow3] = useState(false);
    const [confirmationDialogShow4, setConfirmationDialogShow4] = useState(false);
    const [confirmationDialogShow5, setConfirmationDialogShow5] = useState(false);
    const [confirmationDialogShow6, setConfirmationDialogShow6] = useState(false);
    const [confirmationDialogShow7, setConfirmationDialogShow7] = useState(false);
    const [confirmationDialogShow8, setConfirmationDialogShow8] = useState(false);
    const [select1, setSelect1] = useState('1');
    const [select2, setSelect2] = useState('1');
    const [select3, setSelect3] = useState('1');
    const [select4, setSelect4] = useState('1');
    const [select5, setSelect5] = useState('1');
    const [select6, setSelect6] = useState('1');
    const [select7, setSelect7] = useState('1');
    const [attachments, setAttachments] = useState([
        {
            key: '0-picture.jpg',
            animation: false,
            title: <a href="/">picture.jpg</a>,
            renderBtn: <Button btnType="icon" icon="fal fa-times" data-tip="Delete" />
        }
    ]);
    const [sourceRadio, setSourceRadio] = useState('1');
    const fileUploadRef = useRef<any>();

    const handleFileUpload = (files: any, ref: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                ref.onError(file);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    ref.updateProgress(file, progress);
                }, time / 100)

                setTimeout(() => {
                    clearInterval(interval);
                    ref.onSuccess(file);
                    onAddNewAttachment(file.file.name);
                }, time)
            }
        }
    };

    const onAddNewAttachment = (fileName: any) => {
        const newAttachment = {
            key: `${attachments.length + 1}-${fileName}`,
            animation: true,
            title: <a href="/">{fileName}</a>,
            renderBtn: <Button btnType="icon" icon="fal fa-times" data-tip="Delete" />
        };

        setAttachments(oldAttachments => [
            newAttachment,
            ...oldAttachments,
        ]);
    };

    const onUpdateAnimation = (index: any) => {
        const attachmentsCopy = [...attachments];

        if (attachmentsCopy[index].animation) {
            attachmentsCopy[index].animation = false;

            setAttachments(attachmentsCopy);
        }
    };

    return (
        <ReactAux>
            <MainContainerHeader>
                <ActionContainer>
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={['Covenant']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Button className="mt-l mr-m" icon="fal fa-plus" onClick={() => setAddCovenantModal(true)}>Add Covenant Modal</Button>
                <Button className="mt-l" icon="fal fa-pen" onClick={() => setEditCovenantModal(true)}>Edit Covenant Modal</Button>
            </MainContainerScrollable>

            <Modal title="Add Covenant" size="M" isVisible={addCovenantModal} onClose={() => setAddCovenantModal(false)}>
                <Toolbar className="mb-m"
                    leftSide={
                        <>
                            <Button className="mr-s" icon="fal fa-save" onClick={() => setAddCovenantModal(false)}>Save</Button>
                            <Button className="mr-s" icon="fal fa-save" onClick={() => setAddCovenantModal(false)}>Save and Close</Button>
                            <Button icon="fal fa-times-circle" btnType="secondary" onClick={() => setAddCovenantModal(false)}>Cancel</Button>
                        </>
                    }
                />

                <ModalScrollableContainer>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Covenant Type</Label>
                        </Col>
                        <Col xs={6}>
                            <Radio className="mr-s" name="covenant-type" defaultChecked>Party</Radio>
                            <Radio className="mr-s" name="covenant-type">Loan</Radio>
                            <Radio name="covenant-type">Collateral</Radio>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Entities</Label>
                        </Col>
                        <Col xs={6}>
                            <Button btnType="icon" icon="fal fa-plus" data-tip="Add" />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Covenant Status</Label>
                        </Col>
                        <Col xs={6}>
                            <Radio className="mr-s" name="covenant-status" defaultChecked>Open</Radio>
                            <Radio name="covenant-status">Closed</Radio>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Correspondent</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={[
                                    { value: '1', label: 'SHAHZAD QADIR' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select1}
                                onChange={(obj: any) => {
                                    setSelect1(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Category</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select1}
                                onChange={(obj: any) => {
                                    setSelect1(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Covenant</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select1}
                                onChange={(obj: any) => {
                                    setSelect1(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Covenant Designation</Label>
                        </Col>
                        <Col xs={6}>
                            <Radio className="mr-s" name="covenant-standard" defaultChecked>Standard</Radio>
                            <Radio name="covenant-standard">Non-Standard</Radio>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Default Description</Label>
                        </Col>
                        <Col xs={6}>
                            <Label><strong>Imaging test</strong></Label>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Required Value</Label>
                        </Col>
                        <Col xs={6}>
                            <Input />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Statement Date</Label>
                        </Col>
                        <Col xs={6}>
                            <Input />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Grace Days</Label>
                        </Col>
                        <Col xs={6}>
                            <Input />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Frequency</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={[
                                    { value: '1', label: 'Semi Annually' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select4}
                                onChange={(obj: any) => {
                                    setSelect4(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Recurring (Auto-Create)</Label>
                        </Col>
                        <Col xs={6}>
                            <Checkbox defaultChecked />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Primary Officer</Label>
                        </Col>
                        <Col xs={6}>
                            <div className="d-flex">
                                <Input className="width-full" />
                                <Icon className="ml-s" icon="fal fa-sync" data-tip="Updated from core" size="M" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Secondary Officer</Label>
                        </Col>
                        <Col xs={6}>
                            <div className="d-flex">
                                <Input className="width-full" />
                                <Icon className="ml-s" icon="fal fa-sync" data-tip="Updated from core" size="M" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Branch</Label>
                        </Col>
                        <Col xs={6}>
                            <div className="d-flex">
                                <Input className="width-full" />
                                <Icon className="ml-s" icon="fal fa-sync" data-tip="Updated from core" size="M" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Region</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={[
                                    { value: '1', label: 'Semi Annually' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select5}
                                onChange={(obj: any) => {
                                    setSelect5(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Monitored by</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={[
                                    { value: '1', label: 'Jak Test' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select6}
                                onChange={(obj: any) => {
                                    setSelect6(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Comments</Label>
                        </Col>
                        <Col xs={6}>
                            <Textarea name="textarea-comments" />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Last Status Change</Label>
                        </Col>
                        <Col xs={6}>
                            <Label></Label>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">FYE</Label>
                        </Col>
                        <Col xs={6}>
                            <Label><strong>12/31</strong></Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <Label className="justify-content-end text-right">Subject To</Label>
                        </Col>
                        <Col xs={6}>
                            <Checkbox defaultChecked />
                        </Col>
                    </Row>
                </ModalScrollableContainer>
            </Modal>


            <Modal title="Covenant 53891" size="L" isVisible={editCovenantModal} onClose={() => setEditCovenantModal(false)}>
                <Toolbar className="mb-m"
                    leftSide={
                        <>
                            <Button className="mr-s" icon="fal fa-envelope" btnType="secondary">Email</Button>
                            <Button className="mr-s" icon="fal fa-envelope-open-text" btnType="secondary">Letter</Button>
                            <Button icon="fal fa-phone" btnType="secondary">Call</Button>
                        </>
                    }
                    rightSide={
                        <>
                            {isEditMode ? <>
                                <Button className="mr-s" icon="fal fa-save" onClick={() => setIsEditMode(false)}>Save</Button>
                                <Button className="mr-s" icon="fal fa-save" onClick={() => { setEditCovenantModal(false); setIsEditMode(false); }}>Save and Close</Button>
                                <ConfirmationDialog
                                    show={confirmationDialogShow1}
                                    render={() => <>
                                        <p className="text-center mb-m">Are you sure?</p>
                                        <Toolbar leftSide={<>
                                            <Button onClick={() => setConfirmationDialogShow1(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                            <Button onClick={() => setConfirmationDialogShow1(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                        } />
                                    </>}
                                    onClose={() => setConfirmationDialogShow1(false)}
                                >
                                    <Button className="mr-s" icon="fal fa-trash" btnType="secondary" onClick={() => setConfirmationDialogShow1(true)}>Delete</Button>
                                </ConfirmationDialog>
                                <Button icon="fal fa-times-circle" btnType="secondary" onClick={() => setIsEditMode(false)}>Cancel</Button>
                            </>
                                :
                                <>
                                    <Button className="mr-s" icon="fal fa-pen" onClick={() => setIsEditMode(true)}>Edit</Button>
                                    <Button icon="fal fa-times-circle" btnType="secondary" onClick={() => setEditCovenantModal(false)}>Close</Button>
                                </>}
                        </>
                    }
                />

                <Tabs onTabIndexChange={(index: number) => setTabIndex(index)} currentlyActiveTabIndex={tabIndex}>
                    <TabList>
                        <TabItem>
                            <TabItemTitle>Details</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Measurements</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Notes</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Communication</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent>
                        <TabContentItem>
                            <ModalScrollableContainer>
                                <div className="d-flex">
                                    <div className="section-container flex-1 mr-m" style={{ maxWidth: '50%' }}>
                                        <div className="d-flex align-items-center mb-m">
                                            <h2 className="mr-s">Covenant Info</h2>
                                            <Badge type="danger" icon="fal fa-exclamation-triangle">Past Due</Badge>
                                        </div>
                                        <hr className="mb-m" />
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Covenant Type</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Radio className="mr-s" readOnly={!isEditMode} name="covenant-type" defaultChecked>Party</Radio>
                                                <Radio className="mr-s" readOnly={!isEditMode} name="covenant-type">Loan</Radio>
                                                <Radio readOnly={!isEditMode} name="covenant-type">Collateral</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Entities</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label data-tip="BFT 15977, SHAHZAD QADIR, Another Entity’s Name">
                                                    <strong className="white-space-nowrap text-overflow-ellipsis overflow-hidden">BFT 15977, SHAHZAD QADIR, Another Entity’s Name</strong>
                                                    {isEditMode && <Button btnType="icon" icon="fal fa-pen" className="ml-s" data-tip="Edit" />}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Covenant Status</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Radio className="mr-s" readOnly={!isEditMode} name="covenant-status" defaultChecked>Open</Radio>
                                                <Radio readOnly={!isEditMode} name="covenant-status">Closed</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Correspondent</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex align-items-center">
                                                    <Select
                                                        className="width-full"
                                                        options={[
                                                            { value: '1', label: 'SHAHZAD QADIR' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select1}
                                                        onChange={(obj: any) => {
                                                            setSelect1(obj.value);
                                                        }}
                                                    />
                                                    {isEditMode && <Button btnType="icon" icon="fal fa-pen" className="ml-s" data-tip="Edit" />}
                                                </div> :
                                                    <Label><strong>SHAHZAD QADIR</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Category</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label><strong>NonFinancial Covenant</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Covenant</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label><strong>Available for Det PCF {'<'} 1800,000</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Covenant Designation</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Radio className="mr-s" readOnly={!isEditMode} name="covenant-standard" defaultChecked>Standard</Radio>
                                                <Radio readOnly={!isEditMode} name="covenant-standard">Non-Standard</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Default Description</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Required Value</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Select
                                                        className="mr-s width-full"
                                                        options={[
                                                            { value: '1', label: '<' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select2}
                                                        onChange={(obj: any) => {
                                                            setSelect2(obj.value);
                                                        }}
                                                    />
                                                    <Select
                                                        className="width-full"
                                                        options={[
                                                            { value: '1', label: '1800000' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select3}
                                                        onChange={(obj: any) => {
                                                            setSelect3(obj.value);
                                                        }}
                                                    />
                                                </div> :
                                                    <Label><strong>{'<'} 1800000</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Grace Days</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <Input /> :
                                                    <Label></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Frequency</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ?
                                                    <Select
                                                        options={[
                                                            { value: '1', label: 'Semi Annually' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select4}
                                                        onChange={(obj: any) => {
                                                            setSelect4(obj.value);
                                                        }}
                                                    /> :
                                                    <Label><strong>Semi Annually</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Recurring (Auto-Create)</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Checkbox readOnly={!isEditMode} defaultChecked />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Primary Officer</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Input className="width-full" />
                                                    <Icon className="ml-s" icon="fal fa-sync" data-tip="Updated from core" size="M" />
                                                </div> :
                                                    <Label><strong>Robin Tuffs</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Secondary Officer</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Input className="width-full" />
                                                    <Icon className="ml-s" icon="fal fa-sync" data-tip="Updated from core" size="M" />
                                                </div> :
                                                    <Label></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Branch</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Input className="width-full" />
                                                    <Icon className="ml-s" icon="fal fa-sync" data-tip="Updated from core" size="M" />
                                                </div> :
                                                    <Label></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Region</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ?
                                                    <Select
                                                        options={[
                                                            { value: '1', label: 'Semi Annually' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select5}
                                                        onChange={(obj: any) => {
                                                            setSelect5(obj.value);
                                                        }}
                                                    /> :
                                                    <Label></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Monitored by</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ?
                                                    <Select
                                                        options={[
                                                            { value: '1', label: 'Jak Test' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select6}
                                                        onChange={(obj: any) => {
                                                            setSelect6(obj.value);
                                                        }}
                                                    /> :
                                                    <Label><strong>Jak Test</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Comments</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <Textarea name="textarea-comments" /> :
                                                    <Label></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Created</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label><strong>2/24/2017 By Joro II</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Last Updated</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label><strong>8/11/2021 ByJoro II</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Last Status Change</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">FYE</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label><strong>12/31</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Label className="justify-content-end text-right">Subject To</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Checkbox readOnly={!isEditMode} defaultChecked />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="flex-1">
                                        <div className="section-container mb-m">
                                            <div className="d-flex align-items-center mb-m">
                                                <h2 className="mr-s">Current Period Info</h2>
                                                <Badge type="danger" icon="fal fa-exclamation-triangle">Past Due</Badge>
                                            </div>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Statement Date</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>2/25/2021</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Value</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label></Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Status</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>Pending</strong></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container mb-m">
                                            <div className="d-flex align-items-center mb-m">
                                                <h2 className="mr-s">Last Measured Period Info</h2>
                                                <Badge type="danger" noMaxWidth icon="fal fa-times-circle">Non-Compliant</Badge>
                                            </div>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Statement Date</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>2/25/2021</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Value</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>1,900,000.0000</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Status</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>Measured</strong></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container">
                                            <h2 className="mb-m">Action Summary</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">One-Time Waivers</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>0</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label className="justify-content-end text-right">Due Date Extentions</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label><strong>0</strong></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem>
                            <Toolbar className="mb-m"
                                leftSide={
                                    <Button icon="fal fa-plus">Add Measurement</Button>
                                }
                            />
                            <ModalScrollableContainer>
                                <SimpleTableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th>Measurement</th>
                                                <th className="text-right">Meas. Date</th>
                                                <th className="text-right">Stmt. Date</th>
                                                <th className="text-right">Due Date</th>
                                                <th className="text-right">Value</th>
                                                <th className="text-right">Req. Value</th>
                                                <th>Status</th>
                                                <th style={{ width: '59px' }}>Past Due</th>
                                                <th style={{ width: '59px' }}>In Cmplnc</th>
                                                <th style={{ width: '59px' }}>Doc Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Future</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Current</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><Icon data-tip="Pending" icon="fal fa-clock" size="M" /></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right">06/17/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">1,900,000.0000</td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Measured</td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Non-Compliant" icon="fal fa-times-circle" size="M" /></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"><Icon className="text-success" data-tip="Compliant" icon="fal fa-check" size="M" /></td>
                                                <td className="text-center"><Icon className="text-success" data-tip="Received" icon="fal fa-check-circle" size="M" /></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td><Button btnType="icon" icon="fal fa-pen" data-tip="Edit" onClick={() => setEditMeasurementModal(true)} /></td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"><Icon className="text-danger" data-tip="Past-Due" icon="fal fa-exclamation-triangle" size="M" /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><Icon className="text-warning" data-tip="Caution" icon="fal fa-exclamation-circle" size="M" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem>
                            <Toolbar className="mb-m"
                                leftSide={
                                    <Button icon="fal fa-plus">Аdd Note</Button>
                                }
                            />
                            <ModalScrollableContainer>
                                <SimpleTableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th className="text-right">Date</th>
                                                <th>Type</th>
                                                <th>User</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        show={confirmationDialogShow2}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar leftSide={<>
                                                                <Button onClick={() => setConfirmationDialogShow2(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button onClick={() => setConfirmationDialogShow2(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow2(false)}
                                                    >
                                                        <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => setConfirmationDialogShow2(true)} />
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        show={confirmationDialogShow3}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar leftSide={<>
                                                                <Button onClick={() => setConfirmationDialogShow3(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button onClick={() => setConfirmationDialogShow3(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow3(false)}
                                                    >
                                                        <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => setConfirmationDialogShow3(true)} />
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td><a href="/" className="lv-link-styles">okok</a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        show={confirmationDialogShow4}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar leftSide={<>
                                                                <Button onClick={() => setConfirmationDialogShow4(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button onClick={() => setConfirmationDialogShow4(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow4(false)}
                                                    >
                                                        <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => setConfirmationDialogShow4(true)} />
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td><a href="/" className="lv-link-styles">Measurement Note</a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        show={confirmationDialogShow5}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar leftSide={<>
                                                                <Button onClick={() => setConfirmationDialogShow5(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button onClick={() => setConfirmationDialogShow5(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow5(false)}
                                                    >
                                                        <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => setConfirmationDialogShow5(true)} />
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem>
                            <ModalScrollableContainer>
                                <SimpleTableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th className="text-right">Date</th>
                                                <th>User</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        show={confirmationDialogShow6}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar leftSide={<>
                                                                <Button onClick={() => setConfirmationDialogShow6(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button onClick={() => setConfirmationDialogShow6(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow6(false)}
                                                    >
                                                        <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => setConfirmationDialogShow6(true)} />
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td>Joro ||</td>
                                                <td>Phone</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        show={confirmationDialogShow7}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar leftSide={<>
                                                                <Button onClick={() => setConfirmationDialogShow7(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button onClick={() => setConfirmationDialogShow7(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow7(false)}
                                                    >
                                                        <Button btnType="icon" icon="fal fa-trash" data-tip="Delete" onClick={() => setConfirmationDialogShow7(true)} />
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td>Joro ||</td>
                                                <td><a href="/" className="lv-link-styles">_Keywords Covenant</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>


            <Modal title="Covenant Measurement 53891" size="L" isVisible={editMeasurementModal} onClose={() => setEditMeasurementModal(false)}>
                <Toolbar className="mb-m"
                    leftSide={
                        <>
                            <Button className="mr-s" icon="fal fa-save">Save</Button>
                            <Button className="mr-s" icon="fal fa-save" onClick={() => setEditMeasurementModal(false)}>Save and Close</Button>
                            <ConfirmationDialog
                                show={confirmationDialogShow8}
                                render={() => <>
                                    <p className="text-center mb-m">Are you sure?</p>
                                    <Toolbar leftSide={<>
                                        <Button onClick={() => { setConfirmationDialogShow8(false); setEditMeasurementModal(false); }} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                        <Button onClick={() => setConfirmationDialogShow8(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                    } />
                                </>}
                                onClose={() => setConfirmationDialogShow8(false)}
                            >
                                <Button className="mr-s" icon="fal fa-trash" btnType="secondary" onClick={() => setConfirmationDialogShow8(true)}>Delete</Button>
                            </ConfirmationDialog>
                            <Button icon="fal fa-times-circle" btnType="secondary" onClick={() => setEditMeasurementModal(false)}>Cancel</Button>
                        </>
                    }
                />

                <Tabs onTabIndexChange={(index: number) => setTabIndexMeasurement(index)} currentlyActiveTabIndex={tabIndexMeasurement}>
                    <TabList>
                        <TabItem>
                            <TabItemTitle>Details</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Required Docs</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent>
                        <TabContentItem>
                            <ModalScrollableContainer>
                                <Toolbar
                                    className="mb-m"
                                    leftSide={
                                        <>
                                            <Label className="mr-s"><strong>Source:</strong></Label>
                                            <Radio className="mr-s" name="source-2" value="1" checked={sourceRadio === '1'} onChange={() => setSourceRadio('1')}>Loan Vantage Spread</Radio>
                                            <Radio className="mr-s" name="source-2" value="2" checked={sourceRadio === '2'} onChange={() => setSourceRadio('2')}>Manual</Radio>
                                            <Radio className="mr-s" name="source-2" value="3" checked={sourceRadio === '3'} onChange={() => setSourceRadio('3')}>Certificate</Radio>
                                            {sourceRadio === '3' && <Select
                                                className="width-m"
                                                options={[
                                                    { value: '1', label: 'Option 1' },
                                                    { value: '2', label: 'Option 2' },
                                                ]}
                                                value="1"
                                            />}
                                        </>
                                    }
                                />
                                <div className="d-flex">
                                    <div className="flex-1 mr-m">
                                        <div className="section-container mb-m">
                                            <h2 className="mb-m">Statement Date</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Statement Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Due Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container mb-m">
                                            <h2 className="mb-m">Required Documents Data</h2>
                                            <hr className="mb-m" />
                                            <Row>
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Received and Verified Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Label></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container">
                                            <h2 className="mb-m">Measured Data</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Calculated Measurement Due Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Measurement Due Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Measurement Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Label className="justify-content-end text-right">Measurement Status</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Select
                                                        options={[
                                                            { value: '1', label: 'Option 1' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select7}
                                                        onChange={(obj: any) => {
                                                            setSelect7(obj.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="section-container mb-m">
                                            <h2 className="mb-m">Value Data</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={3}>
                                                    <Label className="justify-content-end text-right">Required Value</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Label><strong>{'<'} 1800000</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={3}>
                                                    <Label className="justify-content-end text-right">Current Value</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Input />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={3}>
                                                    <Label className="justify-content-end text-right">Notes</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Textarea name="notes" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>
                                                    <Label className="justify-content-end text-right">Last Status Change</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Label></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container">
                                            <h2 className="mb-m">Attachments</h2>
                                            <hr className="mb-m" />
                                            <FileUpload
                                                fileUploadRef={(ref: any) => fileUploadRef.current = ref}
                                                maxFileSizeMB={2}
                                                isMultiple
                                                onChange={(files: any) => {
                                                    handleFileUpload(files, fileUploadRef.current);
                                                }}
                                            >
                                                <AttachmentsContent
                                                >
                                                    {attachments.map((attachment, index) => <Attachment
                                                        {...attachment}
                                                        onUpdateAnimation={() => {
                                                            console.log('updateAnimation index: ', index);
                                                            onUpdateAnimation(index);
                                                        }}
                                                    />
                                                    )}
                                                </AttachmentsContent>
                                            </FileUpload>
                                        </div>
                                    </div>
                                </div>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem>
                            <ModalScrollableContainer>
                                <InfoBox className="mb-m">
                                    <p>Please select those Reporting Requirements that you wish to use to perform this Measurement.</p>
                                    <p><strong>Note:</strong> If the Document does not exist for the current Measurement, click "+" to add it.</p>
                                </InfoBox>
                                <SimpleTableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th style={{ width: '26px' }}></th>
                                                <th>Doc Type</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th className="text-right">Statement Date</th>
                                                <th className="text-right">Received and Verified On</th>
                                                <th>Type</th>
                                                <th>Entities</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Checkbox /></td>
                                                <td>
                                                    <Button btnType="icon" icon="fal fa-pen" data-tip="Edit Document" />
                                                </td>
                                                <td>123 Frequency Test Name</td>
                                                <td></td>
                                                <td>Open</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td>Party</td>
                                                <td>ANDREW BYRNE</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox /></td>
                                                <td>
                                                    <Button btnType="icon" icon="fal fa-pen" data-tip="Edit Document" />
                                                </td>
                                                <td>AUG PREREQ DOC</td>
                                                <td>AUG PREREQ DOC</td>
                                                <td>Pending</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td>Party</td>
                                                <td>COLLAT CALCS</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox /></td>
                                                <td>
                                                    <Button btnType="icon" icon="fal fa-plus" data-tip="Document does not exist. Click to add it." />
                                                </td>
                                                <td>B Core NonAuto-Create Acc</td>
                                                <td>B Core NonAuto-Create Acc</td>
                                                <td></td>
                                                <td className="text-right"></td>
                                                <td className="text-right"></td>
                                                <td>Party</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>
        </ReactAux>
    );
}

export default DemoCovenant;