import React from 'react';

import {
    MainContainerHeader, ReactAux, Collapse,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, ActionSettingsForm, LinkWithIcon, Button, Select,
    MainContainerScrollable, Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';

class DemoScroll2 extends React.Component {

    state = {
        currentlyActiveTab: 1,
        collapseDemo2: true,
        collapseDemo3: true,
        collapseDemo4: true,
        actionContainerActiveFormIndex: -1,
        isFirstActionDisabled: false,
        isActionContainerLocked: false,
        isLockDisabled: false,
        selectValue1: '1',
        selectValue2: '1',
    }

    openActionContainerForm(formIndex: number) {
        this.setState({ actionContainerActiveFormIndex: formIndex });
    }

    toggleActiveActionSetting() {
        this.setState({ isFirstActionDisabled: !this.state.isFirstActionDisabled });
        this.openActionContainerForm(-1);
    }

    toggleActionContainerLocked() {
        this.setState({ isActionContainerLocked: !this.state.isActionContainerLocked });
    }

    toggleLockDisabled() {
        this.setState({ isLockDisabled: !this.state.isLockDisabled });
        this.openActionContainerForm(-1);
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader>
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                            <ActionSettings>
                                <LinkWithIcon disabled={this.state.isFirstActionDisabled} icon="fal fa-cog"><a data-tip="Click to edit process definitions" onClick={(e) => { e.preventDefault(); this.openActionContainerForm(1) }} href="/">Commercial loan request</a></LinkWithIcon>
                                <LinkWithIcon icon="fal fa-eye"><a onClick={(e) => { e.preventDefault(); this.openActionContainerForm(2) }} href="/">Collateral Fields mode</a></LinkWithIcon>
                                <Button disabled={this.state.isLockDisabled} data-tip="All changes are allowed" onClick={() => this.toggleActionContainerLocked()} btnType="icon" icon={this.state.isActionContainerLocked ? 'fal fa-lock' : 'fal fa-lock-open'}></Button>
                            </ActionSettings>
                            <ActionSettingsForm isActive={this.state.actionContainerActiveFormIndex === 1}>
                                <p>Process definitions:</p>
                                <Select
                                    className="width-m"
                                    value={this.state.selectValue1}
                                    onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                                    options={[{ value: '1', label: 'Commercial loan request' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button onClick={() => this.toggleLockDisabled()} icon="fal fa-save">Update</Button>
                                <Button onClick={() => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                            <ActionSettingsForm isActive={this.state.actionContainerActiveFormIndex === 2}>
                                <p>Process definitions:</p>
                                <Select
                                    className="width-m"
                                    value={this.state.selectValue2}
                                    onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                                    options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button icon="fal fa-save" onClick={() => this.toggleActiveActionSetting()}>Update</Button>
                                <Button onClick={() => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                        </ActionContainerHeader>
                        <Button icon="fal fa-pen">Edit</Button>
                        <Button btnType="secondary" icon="fal fa-percent">LTV</Button>
                        <Button btnType="secondary" icon="fal fa-expand">Spreading</Button>
                        <Button btnType="secondary" icon="fal fa-coins">Appraisal</Button>
                        <Button btnType="secondary" icon="fal fa-map-marker-alt">Visit</Button>
                    </ActionContainer>
                </MainContainerHeader>

                <Tabs className="mt-m" currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setState({ currentlyActiveTab: i })}>
                    <TabList>
                        <TabItem>
                            <TabItemTitle>Overview</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Location</TabItemTitle>
                        </TabItem>
                        <TabItem>
                            <TabItemTitle>Miscellaneous</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent>
                        <TabContentItem>

                            <MainContainerScrollable>
                                <div style={{ height: '1200px' }}>
                                    <h2>1200px Div Start</h2>
                                </div>
                                <h2>END</h2>
                            </MainContainerScrollable>

                        </TabContentItem>
                        <TabContentItem>

                            <MainContainerScrollable>
                                <DemoLocationPage />
                                <Collapse
                                    title="Colapse 2"
                                    expanded={this.state.collapseDemo2}
                                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                                >
                                    <p>Colapse 2 Content...</p>
                                </Collapse>
                                <Collapse
                                    title="Colapse 3"
                                    expanded={this.state.collapseDemo3}
                                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                                >
                                    <p>Colapse 3 Content...</p>
                                </Collapse>
                                <Collapse
                                    title="Colapse 4"
                                    expanded={this.state.collapseDemo4}
                                    onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                                >
                                    <p>Colapse 4 Content...</p>
                                </Collapse>
                            </MainContainerScrollable>

                        </TabContentItem>
                        <TabContentItem>Miscellaneous tab content</TabContentItem>
                    </TabContent>
                </Tabs>
            </ReactAux>
        );
    }
}

export default DemoScroll2;