/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import {
    ReactAux, Main, MainContainer, WorkQueue, WorkQueueItem,
    MainContainerScrollable, Button, WidgetContainer, PortalHeader, themeChangerHelpers,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import DemoChatComponent from './DemoChatComponent';

type WorkQueueItemColor = 'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink';

const Homepage = () => {
    const [workQueueItems, setWorkQueueItems] = useState([
        { title: 'Logan photography', details: '2290', color: 'default' as WorkQueueItemColor },
        { title: 'Invitation WQ', details: '23232', color: 'blue' as WorkQueueItemColor },
        { title: 'Allan A Morgan', details: '2290', color: 'green' as WorkQueueItemColor },
        { title: 'Logan photography', details: '2290', color: 'yellow' as WorkQueueItemColor },
        { title: 'Invitation WQ', details: '23232', color: 'orange' as WorkQueueItemColor },
        { title: 'Allan A Morgan', details: '23232', color: 'pink' as WorkQueueItemColor },
        { title: 'Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan', details: '2290', color: 'default' as WorkQueueItemColor },
        { title: 'Logan photography', details: '2290', color: 'blue' as WorkQueueItemColor },
        { title: 'Invitation WQ', details: '23232', color: 'green' as WorkQueueItemColor },
        { title: 'Allan A Morgan', details: '23232', color: 'yellow' as WorkQueueItemColor },
        { title: 'Logan photography', details: '2290', color: 'orange' as WorkQueueItemColor },
        { title: 'Logan photography', details: '2290', color: 'pink' as WorkQueueItemColor },
    ]);
    const [isDark, setIsDark] = useState(false);

    const setColorForItem = (itemIndex: number, color: WorkQueueItemColor) => {
        const currentItems = [...workQueueItems];
        currentItems[itemIndex].color = color;

        setWorkQueueItems(currentItems);
    };

    const workQueueItemsElement = workQueueItems.map((item, index) => {
        return (
            <WorkQueueItem
                key={`${item.title}-${index}`}
                onColorChange={(color: WorkQueueItemColor) => setColorForItem(index, color)}
                title={item.title}
                details={item.details}
                color={item.color}
                onClick={() => console.log(`Click on WorkQueueItem ${index}`)}
            />
        )
    })

    const addNewItem = () => {
        const item = {
            title: `New item ${workQueueItems.length}`,
            details: '1234',
            color: 'default' as WorkQueueItemColor
        };

        const currentItems = [...workQueueItems];
        currentItems.push(item);

        setWorkQueueItems(currentItems);
    };

    return (
        <ReactAux>
            <PortalHeader
                loanVantageLogoLight={{ img: './img/JackHenryLending_LoanVantage_Logo_navy.png', alt: 'JackHenry Logo' }}
                loanVantageLogoDark={{ img: './img/JackHenryLending_LoanVantage_Logo_white.png', alt: 'JackHenry Logo' }}
                bankLogoLight={{ img: './img/first-state-com-bank-logo.png', alt: 'Bank logo' }}
                // bankLogoDark={{ img: './img/first-state-com-bank-logo-dark.png', alt: 'Bank logo' }}
                isDark={isDark}
            />
            <DemoHeader onThemeChange={(theme) => setIsDark(theme.base === themeChangerHelpers.THEME_BASE_DARK ? true : false)} />
            <Main>
                <MainContainer>
                    <MainContainerScrollable>
                        <div>
                            <WidgetContainer title="Work Queues">
                                <WorkQueue>
                                    {workQueueItemsElement}
                                </WorkQueue>
                            </WidgetContainer>
                        </div>
                        <Button className="mt-m" icon="fal fa-plus" onClick={() => addNewItem()}>Add new Work Queue item</Button>
                    </MainContainerScrollable>
                </MainContainer>
                <DemoChatComponent />
            </Main>
        </ReactAux>
    );
}

export default Homepage;