import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import './sidebar.scss';

import LinkItem from './LinkItem/LinkItem';

const Sidebar: React.FC = () => {

    return <div className="doc-sidebar">
        <div className="space"></div>
        <div className="menu-header">
            <Link to="/">
                <img src={logo} alt="VSG" />
            </Link>
            <h1>Design System</h1>
            <span>v. 4.3</span>
        </div>
        <div className="menu-links">
            <LinkItem title="Getting started" links={[
                { title: 'Introduction', path: '/docs/introduction' },
                { title: 'Git Flow', path: '/docs/git-flow' },
            ]} />
            <LinkItem title="Typography" links={[
                { title: 'Basic', path: '/docs/typography' }
            ]} />
            <LinkItem title="Layout" links={[
                { title: 'Layout', path: '/docs/layout' },
                { title: 'Chat', path: '/docs/chat' },
                { title: 'Grid', path: '/docs/grid' },
                { title: 'Header', path: '/docs/header' },
                { title: 'LeftSideBar', path: '/docs/left-side-bar' },
                { title: 'PortalHeader', path: '/docs/portal-header' },
                { title: 'ReactAux', path: '/docs/react-aux' },
            ]} />
            <LinkItem title="Components" links={[
                { title: 'ActionContainer', path: '/docs/action-container' },
                { title: 'ActivityChecklist', path: '/docs/activity-checklist' },
                { title: 'Badge', path: '/docs/badge' },
                { title: 'Card', path: '/docs/card' },
                { title: 'Collapse', path: '/docs/collapse' },
                { title: 'ConfirmationDialog', path: '/docs/confirmation-dialog' },
                { title: 'ContextMenu', path: '/docs/context-menu' },
                { title: 'Diagram', path: '/docs/diagram' },
                { title: 'Drag-n-Drop', path: '/docs/drag-n-drop' },
                { title: 'DrillDown', path: '/docs/drill-down' },
                { title: 'HelpBox', path: '/docs/help-box' },
                { title: 'Icon', path: '/docs/icon' },
                { title: 'InfoBox', path: '/docs/info-box' },
                { title: 'Label', path: '/docs/label' },
                { title: 'LinkWithIcon', path: '/docs/link-with-icon' },
                { title: 'Loader', path: '/docs/loader' },
                { title: 'LoanOptionCard', path: '/docs/loan-option-card' },
                { title: 'Modal', path: '/docs/modal' },
                { title: 'Popover', path: '/docs/popover' },
                { title: 'ProgressBar', path: '/docs/progress-bar' },
                { title: 'RadialGaugeResult', path: '/docs/radialgauge-result' },
                { title: 'ShowImage', path: '/docs/show-image' },
                { title: 'SimpleTableContainer', path: '/docs/simple-table-container' },
                { title: 'SummaryBox', path: '/docs/summary-box' },
                // TODO Temporarily removed Kendo
                // { title: 'Table', path: '/docs/table' },
                { title: 'Tabs', path: '/docs/tabs' },
                { title: 'ThemeChanger', path: '/docs/theme-changer' },
                { title: 'Toast', path: '/docs/toast' },
                { title: 'Toolbar', path: '/docs/toolbar' },
                { title: 'Tooltip', path: '/docs/tooltip' },
                { title: 'UploadedFileRow', path: '/docs/uploaded-file-row' },
                { title: 'WidgetContainer', path: '/docs/widget-container' },
                { title: 'WizardStepsContainer', path: '/docs/wizard-steps-container' },
                { title: 'WorkQueue', path: '/docs/work-queue' },
            ]} />
            <LinkItem title="Forms" links={[
                { title: 'Autocomplete', path: '/docs/autocomplete' },
                { title: 'Button', path: '/docs/button' },
                { title: 'ButtonGroup', path: '/docs/button-group' },
                { title: 'Checkbox', path: '/docs/checkbox' },
                // TODO Temporarily removed Kendo
                // { title: 'Datepicker', path: '/docs/datepicker' },
                { title: 'Dropzone', path: '/docs/dropzone' },
                { title: 'FileUpload', path: '/docs/file-upload' },
                { title: 'Input', path: '/docs/input' },
                { title: 'MaskInput', path: '/docs/mask-input' },
                { title: 'MultiEmail', path: '/docs/multi-email' },
                { title: 'Multiselect', path: '/docs/multiselect' },
                { title: 'MultiselectBox', path: '/docs/multiselect-box' },
                { title: 'NumericInput', path: '/docs/numeric-input' },
                { title: 'Radio', path: '/docs/radio' },
                { title: 'Select', path: '/docs/select' },
                { title: 'Textarea', path: '/docs/textarea' },
                { title: 'ValidationMessage', path: '/docs/validation-message' },
                { title: 'Form examples', path: '/docs/form-examples' },
            ]} />
            <LinkItem title="Utilities" links={[
                { title: 'Styling', path: '/docs/style-utils' },
                { title: 'Testing', path: '/docs/testing' },
                { title: 'Widths', path: '/docs/widths' },
            ]} />
             <LinkItem title="Demo Pages" links={[
                { title: 'Demo Pages', path: '/components' },
            ]} />
        </div>
    </div>
}

export default Sidebar;