import React from 'react';

import {
    Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem, MainContainerScrollable
} from '@jkhy/vsg-loanvantage-design-system';

import Components from './Components';
import DemoLocationPage from './DemoLocationPage';
import DemoPagesLinks from './DemoPagesLinks';
import DemoChartsPage from './DemoChartsPage';
import DnD from './DnD';



class ComponentsTabs extends React.Component {
    state = {
        currentlyActiveTab: 0
    };

    setCurrentlyActiveTab = (newTabIndex: number) => {
        this.setState({ currentlyActiveTab: newTabIndex });
    }

    render() {

        return (
            <Tabs currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setCurrentlyActiveTab(i)}>
                <TabList>
                    <TabItem>
                        <TabItemTitle>Demo Pages/Components</TabItemTitle>
                    </TabItem>
                    <TabItem>
                        <TabItemTitle>Demo Location Page</TabItemTitle>
                    </TabItem>
                    <TabItem>
                        <TabItemTitle>Components</TabItemTitle>
                    </TabItem>
                    <TabItem>
                        <TabItemTitle>Charts</TabItemTitle>
                    </TabItem>
                    <TabItem>
                        <TabItemTitle>Drag-n-Drop examples</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent>
                    <TabContentItem>

                        <DemoPagesLinks />

                    </TabContentItem>
                    <TabContentItem>

                        <MainContainerScrollable>
                            <DemoLocationPage />
                        </MainContainerScrollable>

                    </TabContentItem>
                    <TabContentItem>

                        <Components />

                    </TabContentItem>
                    <TabContentItem>
                        <MainContainerScrollable>
                            <DemoChartsPage />
                        </MainContainerScrollable>
                    </TabContentItem>
                    <TabContentItem>
                        <DnD />
                    </TabContentItem>
                </TabContent>
            </Tabs>
        );
    }
}

export default ComponentsTabs;
