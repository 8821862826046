import React from 'react';
import Highlight from 'react-highlight';
import { ReactAux, Button, Input } from '@jkhy/vsg-loanvantage-design-system';

const Widths: React.FC = () => {
    return (
        <ReactAux>
            <h1 className="mb-s">Widths</h1>
            <p className="mb-m">Easily make an element as wide with our width utilities.</p>

            <div className="width-s mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-s: 96px</p></div>
            <div className="width-m mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-m: 192px</p></div>
            <div className="width-l mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-l: 400px</p></div>
            <div className="width-full mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-full: 100%</p></div>
            <Input className="width-s mb-s" placeholder="width-s" />
            <Button className="d-block width-m mb-s" icon="fal fa-pastafarianism">width-m</Button>
            <Input className="width-l mb-s" placeholder="width-l" />
            <Button className="d-block width-full mb-m" icon="fal fa-pastafarianism">width-full</Button>

            <Highlight className="mb-l">
                {`
<div>
    <div className="width-s mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-s: 96px</p></div>
    <div className="width-m mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-m: 192px</p></div>
    <div className="width-l mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-l: 400px</p></div>
    <div className="width-full mb-s pt-s pb-s" style={{ background: 'var(--color-select)' }}><p>width-full: 100%</p></div>
    <Input className="width-s mb-s" placeholder="width-s" />
    <Button className="d-block width-m mb-s" icon="fal fa-pastafarianism">width-m</Button>
    <Input className="width-l mb-s" placeholder="width-l" />
    <Button className="d-block width-full mb-m" icon="fal fa-pastafarianism">width-full</Button>
</div>
    `}
            </Highlight>

        </ReactAux>
    )
}

export default Widths;
