import React from 'react';
import Highlight from 'react-highlight';

const ThemeChangerPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">ThemeChanger</h1>
        <p className="mb-l">A component, that manages the system color themes. There are 16 themes (8 light and 8 dark). The ThemeChanger has to be instantiated only once - on root application level.</p>
        <p className="mb-s">There are helper classes for all themes that match the pattern: "lv-theme-{'{themeName}'}-primary". They are used to set the icon color in the dropdown items for theme changing in the Header.</p>
        <p className="mb-l">There are helper classes "lv-theme-menu-light" and "lv-theme-menu-dark" which are used to set background and border to the MenuItem elements in for theme changing in the Header.</p>


        <Highlight className="React">
            {
                `
import React from 'react';
import { ThemeChanger } from 'vsg-design-system/ThemeChanger';

const Example: React.FC = () => {
    return (
        <ThemeChanger theme="navy" />
    );
}
export default Example;
    `}
        </Highlight>

        <h2 className="mb-m">API</h2>

        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>theme</td>
                    <td>
                        <strong>light:</strong> 'navy' | 'indigo' | 'midnightBlue' | 'charcoalGrey' | 'teal' | 'aqua' | 'fuchsia' | 'mangoSorbet'
                        <br /><br />
                        <strong>dark:</strong> 'mint' | 'matrix' | 'orange' | 'neonWatermelon' | 'coralPeach' | 'sunnyLemon' | 'lime' | 'azureBlue'
                    </td>
                    <td>'navy'</td>
                    <td>Set the theme.</td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default ThemeChangerPage;
