import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Button, ConfirmationDialog, Toolbar } from '@jkhy/vsg-loanvantage-design-system';


const ConfirmationDialogPage: React.FC = () => {

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    return (
        <div>
            <h1 className="mb-s">ConfirmationDialog</h1>
            <p className="mb-l">ConfirmationDialog is a wrapper component.</p>


            <ConfirmationDialog
                show={show1}
                render={() => <>
                    <p className="mb-m">Are you sure you want to cancel the chanages you have made?</p>
                    <Toolbar leftSide={<><Button onClick={() => setShow1(false)} btnType="primary" icon='fal fa-check' className="mr-s" >Yes, cancel</Button><Button onClick={() => setShow1(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
                </>}
                onClose={() => setShow1(false)}
            >
                <Button onClick={() => setShow1(true)} btnType="secondary" icon='fal fa-times-circle' className="mr-s" >Cancel</Button>
            </ConfirmationDialog>

            <ConfirmationDialog
                title="Cancel changes"
                show={show2}
                position="bottom"
                render={() => <>
                    <p className="mb-m">Are you sure you want to cancel the chanages you have made?</p>
                    <Toolbar leftSide={<><Button onClick={() => setShow2(false)} btnType="primary" icon='fal fa-check' className="mr-s" >Yes, cancel</Button><Button onClick={() => setShow2(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
                </>}
                onClose={() => setShow2(false)}
            >
                <Button onClick={() => setShow2(true)} btnType="secondary" icon='fal fa-times-circle' className="mr-s" >Cancel</Button>
            </ConfirmationDialog>

            <ConfirmationDialog
                title="Cancel changes"
                show={show3}
                position="bottom"
                render={() => <>
                    <p className="mb-m">Are you sure you want to cancel the chanages you have made?</p>
                    <Toolbar leftSide={<><Button onClick={() => setShow3(false)} btnType="primary" icon='fal fa-check' className="mr-s" >Yes, cancel</Button><Button onClick={() => setShow3(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
                </>}
                onClose={() => setShow3(false)}
            >
                <Button style={{ float: 'right' }} onClick={() => setShow3(true)} btnType="secondary" icon='fal fa-times-circle' className="mr-s" >Cancel</Button>
            </ConfirmationDialog>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { ConfirmationDialog, Button } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    return (
      <div>
        <ConfirmationDialog
            show={show1}
            render={() => <>
                <p className="mb-m">Are you sure you want to cancel the chanages you have made?</p>
                <Toolbar leftSide={<><Button onClick={() => setShow1(false)} btnType="primary" icon='fal fa-check' className="mr-s" >Yes, cancel</Button><Button onClick={() => setShow1(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
            </>}
            onClose={() => setShow1(false)}
        >
            <Button onClick={() => setShow1(true)} btnType="secondary" icon='fal fa-times-circle' className="mr-s" >Cancel</Button>
        </ConfirmationDialog>

        <ConfirmationDialog
            title="Cancel changes"
            show={show2}
            position="bottom"
            render={() => <>
                <p className="mb-m">Are you sure you want to cancel the chanages you have made?</p>
                <Toolbar leftSide={<><Button onClick={() => setShow2(false)} btnType="primary" icon='fal fa-check' className="mr-s" >Yes, cancel</Button><Button onClick={() => setShow2(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
            </>}
            onClose={() => setShow2(false)}
        >
            <Button onClick={() => setShow2(true)} btnType="secondary" icon='fal fa-times-circle' className="mr-s" >Cancel</Button>
        </ConfirmationDialog>

        <ConfirmationDialog
            title="Cancel changes"
            show={show3}
            position="bottom"
            render={() => <>
                <p className="mb-m">Are you sure you want to cancel the chanages you have made?</p>
                <Toolbar leftSide={<><Button onClick={() => setShow3(false)} btnType="primary" icon='fal fa-check' className="mr-s" >Yes, cancel</Button><Button onClick={() => setShow3(false)} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button></>} />
            </>}
            onClose={() => setShow3(false)}
        >
            <Button style={{ float: 'right' }} onClick={() => setShow3(true)} btnType="secondary" icon='fal fa-times-circle' className="mr-s" >Cancel</Button>
        </ConfirmationDialog>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title. This will also show the close button.</td>
                    </tr>
                    <tr>
                        <td>show</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Mounted the ConfirmationDialog in DOM.</td>
                    </tr>
                    <tr>
                        <td>position</td>
                        <td>'top' | 'bottom'</td>
                        <td>'top'</td>
                        <td>Set where the ConfirmationDialog to be placed. If not enough space will be placed opposite position.</td>
                    </tr>
                    <tr>
                        <td>render</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Render the content of ConfirmationDialog.</td>
                    </tr>
                    <tr>
                        <td>onClose</td>
                        <td>{'() => void'}</td>
                        <td className="text-center">-</td>
                        <td>Callback when the close button or overlay is clicked.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ConfirmationDialogPage;
