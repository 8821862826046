import React from 'react';
import Highlight from 'react-highlight';

import {

} from '@jkhy/vsg-loanvantage-design-system';


const Modal: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">Modal</h1>
            <p className="mb-l">
                The Modal component is a dialog box/popup window that is displayed on top of the current page. See example <a href='/demo/modal' title="Modal example"><strong>here</strong></a>.
            </p>

            <h2 className="mb-m">Modal components:</h2>
            <p className="mb-s"><strong><a href="#modal">Modal</a></strong> - Wrapper for the modal content. .</p>
            <p className="mb-xl"><strong>ModalScrollableContainer</strong> - Wrapper component which enables scroll when content is longer than the viewport height. Should be placed at the bottom of <strong>Modal</strong>.</p>


            <Highlight className="React">
                {
                    `
import React from 'react';

import { Modal, Button } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {
     
    state = {
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false,
        modal6: false,
    }; 

    render() {
        return (
            <div>
                <Button icon="fal fa-pastafarianism" onClick={() => this.setState({ modal1: true })}>Modal</Button>
                <--->

                <Modal title="Modal size S" isVisible={this.state.modal1} size="S" onClose={() => this.setState({ modal1: false })}></Modal>
                <Modal title="Modal size M" isVisible={this.state.modal2} size="M" onClose={() => this.setState({ modal2: false })}></Modal>
                <Modal title="Modal size L" isVisible={this.state.modal3} size="L" onClose={() => this.setState({ modal3: false })}></Modal>
                <Modal title="Modal size XL" isVisible={this.state.modal4} size="XL" onClose={() => this.setState({ modal4: false })}></Modal>

                <Modal title="Modal" isVisible={this.state.modal5} size="L" onClose={() => this.setState({ modal5: false })}>
                    <ModalScrollableContainer>
                        Long content...
                    </ModalScrollableContainer>
                </Modal>

                <Modal title="Modal" isVisible={this.state.modal6} size="XL" onClose={() => this.setState({ modal6: false })}>
                    <h1>Static</h1>
                    <h2 className="mb-xl">And more static</h2>
                    <div className="flex-full-height">
                        <>
                            <ModalScrollableContainer>
                                Long content...
                            </ModalScrollableContainer>
                        </>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3 id="modal">Modal</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title for modal.</td>
                    </tr>
                    <tr>
                        <td>isVisible</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Mounted the modal in DOM.</td>
                    </tr>
                    <tr>
                        <td>isHidden</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Hidden the modal but stays in the DOM.</td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the id.</td>
                    </tr>
                    <tr>
                        <td>size</td>
                        <td>'S' | 'M' | 'L' | 'XL'</td>
                        <td>'M'</td>
                        <td>Set the size for the modal.</td>
                    </tr>
                    <tr>
                        <td>isExpandable</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Show button for expand.</td>
                    </tr>
                    <tr>
                        <td>onClose</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback to execute when the modal is closed.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default Modal;
