import React, { useState } from 'react';
import Highlight from 'react-highlight';

import { MultiselectBox } from '@jkhy/vsg-loanvantage-design-system';

const multiselectBoxOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const MultiselectBoxPage: React.FC = () => {
    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState([{ ...multiselectBoxOptions[1] }]);

    const [value3, setValue3] = useState([]);
    const [
        loadedOptions, setLoadedOptions
    ] = useState<{ label: string, value: string, [key: string]: any }[]>([]);
    const [loading, setLoading] = useState(false);

    const [value4, setValue4] = useState([{ value: '2', label: 'Option 2', secondCode: 'code-123' }, { value: '4', label: 'Option 4' }]);
    const [
        loadedOptions2, setLoadedOptions2
    ] = useState<{ label: string, value: string, [key: string]: any }[]>([]);
    const [loading2, setLoading2] = useState(false);

    return (
        <div>
            <h1 className="mb-s">MultiselectBox</h1>
            <p className="mb-l">A form control, that allows selection of multiple options from a given list by checkboxes.</p>

            <MultiselectBox
                className="mb-s"
                options={multiselectBoxOptions}
                value={value1}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue1(obj);
                }}
            />

            <MultiselectBox
                className="mb-s"
                options={multiselectBoxOptions}
                value={value2}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue2(obj);
                }}
            />

            <MultiselectBox
                className="mb-m"
                options={multiselectBoxOptions}
                value={[{ ...multiselectBoxOptions[2] }]}
                disabled
            />

            <h3 className="mb-s">MultiselectBox with loading options</h3>
            <MultiselectBox
                className="mb-s"
                options={loadedOptions}
                value={value3}
                isLoading={loading}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue3(obj);
                }}
                onFocus={() => {
                    if (loadedOptions.length === 0) {
                        setLoading(true);

                        setTimeout(() => {
                            setLoading(false);

                            setLoadedOptions(multiselectBoxOptions as any);
                        }, 3000);
                    }
                }}
            />

            <MultiselectBox
                className="mb-m"
                options={loadedOptions2}
                value={value4}
                isLoading={loading2}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue4(obj);
                }}
                onFocus={() => {
                    if (loadedOptions2.length === 0) {
                        setLoading2(true);

                        setTimeout(() => {
                            setLoading2(false);

                            setLoadedOptions2(multiselectBoxOptions as any);
                        }, 3000);
                    }
                }}
            />

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { MultiselectBox } from '@jkhy/vsg-loanvantage-design-system';

const multiselectBoxOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const Example: React.FC = (props) => {
    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState([{ ...multiselectBoxOptions[1] }]);

    const [value3, setValue3] = useState([]);
    const [
        loadedOptions, setLoadedOptions
    ] = useState<{ label: string, value: string, [key: string]: any }[]>([]);
    const [loading, setLoading] = useState(false);

    const [value4, setValue4] = useState([{ value: '2', label: 'Option 2', secondCode: 'code-123' }, { value: '4', label: 'Option 4' }]);
    const [
        loadedOptions2, setLoadedOptions2
    ] = useState<{ label: string, value: string, [key: string]: any }[]>([]);
    const [loading2, setLoading2] = useState(false);

    return (
        <div>
            <MultiselectBox
                options={multiselectBoxOptions}
                value={value1}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue1(obj);
                }}
            />

            <MultiselectBox
                options={multiselectBoxOptions}
                value={value2}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue2(obj);
                }}
            />

            <MultiselectBox
                options={multiselectBoxOptions}
                value={[{ ...multiselectBoxOptions[2] }]}
                disabled
            />

            <MultiselectBox
                options={loadedOptions}
                value={value3}
                isLoading={loading}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue3(obj);
                }}
                onFocus={() => {
                    if (loadedOptions.length === 0) {
                        setLoading(true);

                        setTimeout(() => {
                            setLoading(false);

                            setLoadedOptions(multiselectBoxOptions as any);
                        }, 3000);
                    }
                }}
            />

            <MultiselectBox
                options={loadedOptions2}
                value={value4}
                isLoading={loading2}
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue4(obj);
                }}
                onFocus={() => {
                    if (loadedOptions2.length === 0) {
                        setLoading2(true);

                        setTimeout(() => {
                            setLoading2(false);

                            setLoadedOptions2(multiselectBoxOptions as any);
                        }, 3000);
                    }
                }}
            />
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>options</td>
                        <td>{'{label:string, value:string, [key:string]:string}[]'}</td>
                        <td className="text-center">-</td>
                        <td>The array of options, which are displayed in the MultiselectBox dropdown. The <i>label</i> and <i>value</i> keys
                            are required.
                        </td>
                    </tr>
                    <tr>
                        <td>value</td>
                        <td>{'{label:string, value:string, [key:string]:string}[]'}</td>
                        <td className="text-center">-</td>
                        <td>Sets the MultiselectBox value. Notice that it is actually an array with the already selected options.</td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Makes the MultiselectBox disabled.</td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the given text as a placeholder of the MultiselectBox.</td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>The id to set on the MultiselectBox container.</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Name of the HTML Input, associated to the form control. If this property is left empty, no input will be rendered).</td>
                    </tr>
                    <tr>
                        <td>tabIndex</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Sets the tabIndex attribute on the input.</td>
                    </tr>
                    <tr>
                        <td>isLoading</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Indicates if the MultiselectBox should be displayed in loading state.</td>
                    </tr>
                    <tr>
                        <td>noOptionsMessage</td>
                        <td>string</td>
                        <td>"No options"</td>
                        <td>Text to display when there are no options.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>{'([{ label: string, value: string, [key: string]: any }]) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Returns the array of currently selected options, including the one, that has just been added or removed.</td>
                    </tr>
                    <tr>
                        <td>onFocus</td>
                        <td>{'(event: FocusEvent) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Callback when the MultiselectBox is focused.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MultiselectBoxPage;
