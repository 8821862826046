import React from 'react';

import {
    ReactAux, Main, MainContainer, MainContainerScrollable, LeftSideBar, TopSection, SimpleTableContainer,
    Input, ScrollableContainer,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import DemoLoanRequest from './DemoLoanRequest';

class DemoBar extends React.Component {

    render() {
        return (
            <ReactAux>
                <DemoHeader />
                <Main>
                    <LeftSideBar width={648}>
                        <TopSection>
                            <ScrollableContainer>
                                <h1 className="mb-l">Demo</h1>
                                <SimpleTableContainer className="mb-xl">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Heading</th>
                                                <th>Heading</th>
                                                <th>Heading</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td><Input /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">Row Cell</td>
                                                <td className="text-right text-bold">Row Cell right</td>
                                                <td className="text-right text-bold">Row Cell right</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td><Input /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">Row Cell</td>
                                                <td className="text-right text-bold">Row Cell right</td>
                                                <td className="text-right text-bold">Row Cell right</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                            </tr>
                                            <tr>
                                                <td>Row Cell</td>
                                                <td>Row Cell</td>
                                                <td><Input /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">Row Cell</td>
                                                <td className="text-right text-bold">Row Cell right</td>
                                                <td className="text-right text-bold">Row Cell right</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ScrollableContainer>
                        </TopSection>
                    </LeftSideBar>
                    <MainContainer>
                        <MainContainerScrollable>
                            <DemoLoanRequest />
                        </MainContainerScrollable>
                    </MainContainer>
                </Main>
            </ReactAux>
        );
    }
}

export default DemoBar;