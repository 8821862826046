/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
    ReactAux, Main, MainContainer, LeftSideBar, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import DemoSectionsForLeftSideBar from './DemoSectionsForLeftSideBar';
import DemoWizardNav from './DemoWizardNav';
import ComponentsTabs from './ComponentsTabs';
import DemoCreditCheck from './DemoCreditCheck';
import DemoLoanRequest from './DemoLoanRequest';
import DemoFloodDetermination from './DemoFloodDetermination';
import DemoScroll1 from './DemoScroll1';
import DemoScroll2 from './DemoScroll2';
import DemoScroll3 from './DemoScroll3';
import DemoScroll4 from './DemoScroll4';
import DemoScroll5 from './DemoScroll5';
import DemoScroll6 from './DemoScroll6';
import DemoScroll7 from './DemoScroll7';
import DemoModal from './DemoModal';
import DnDPresentationReport from './DnDPresentationReport';
import DnDColumnSelection from './DnDColumnSelection';
import DnDColumnSelectionTabs from './DnDColumnSelectionTabs';
import DnDSimple from './DnDSimple';
import DemoServiceRequest from './DemoServiceRequest';
import DemoFileCollateral from './DemoFileCollateral';
import DemoChatComponent from './DemoChatComponent';
import DemoEngagements from './DemoEngagements';
import DemoShowImage from './DemoShowImage';
import DemoSBA from './DemoSBA';
import DemoCovenant from './DemoCovenant';
import DemoRiskRating from './DemoRiskRating';
import DemoPolicyException from './DemoPolicyException';
import DemoApplicationCIBA from './DemoApplicationCIBA';
import DemoTicklerRecord from './DemoTicklerRecord';

const ComponentsLayout = () => {

    const loaction = window.location;
    const isOnTicklerPage = loaction.pathname.includes('/components/tickler-record');
    return <ReactAux>
        <DemoHeader />
        <Main>
            {!isOnTicklerPage && <LeftSideBar isToggleable={(loaction.search !== '?wizard-nav' && loaction.pathname !== '/demo/loan-request')}>
                {(loaction.search === '?wizard-nav' || loaction.pathname === '/demo/loan-request') ? <DemoWizardNav /> : <DemoSectionsForLeftSideBar />}
            </LeftSideBar>}
            <MainContainer>
                <Switch>
                    <Route exact path="/components" component={ComponentsTabs} />
                    <Route path="/demo/policy-exception" component={DemoPolicyException} />
                    <Route path="/demo/covenant" component={DemoCovenant} />
                    <Route path="/demo/sba" component={DemoSBA} />
                    <Route path="/demo/credit-check" component={DemoCreditCheck} />
                    <Route path="/demo/loan-request" component={DemoLoanRequest} />
                    <Route path="/demo/flood-determination" component={DemoFloodDetermination} />
                    <Route path="/demo/service-request" component={DemoServiceRequest} />
                    <Route path="/demo/file-collateral" component={DemoFileCollateral} />
                    <Route path="/demo/show-image" component={DemoShowImage} />
                    <Route path="/demo/engagements" component={DemoEngagements} />
                    <Route path="/demo/risk-rating" component={DemoRiskRating} />
                    <Route path="/demo/application-ciba" component={DemoApplicationCIBA} />
                    <Route path="/demo/scroll1" component={DemoScroll1} />
                    <Route path="/demo/scroll2" component={DemoScroll2} />
                    <Route path="/demo/scroll3" component={DemoScroll3} />
                    <Route path="/demo/scroll4" component={DemoScroll4} />
                    <Route path="/demo/scroll5" component={DemoScroll5} />
                    <Route path="/demo/scroll6" component={DemoScroll6} />
                    <Route path="/demo/scroll7" component={DemoScroll7} />
                    <Route path="/demo/modal" component={DemoModal} />
                    <Route path="/components/dnd/presentation-report-async" component={() => <DnDPresentationReport asyncData={true} />} />
                    <Route path="/components/dnd/presentation-report" component={() => <DnDPresentationReport asyncData={false} />} />
                    <Route path="/components/dnd/column-selection" component={DnDColumnSelection} />
                    <Route path="/components/dnd/column-selection-tabs-async" component={() => <DnDColumnSelectionTabs asyncData={true} />} />
                    <Route path="/components/dnd/column-selection-tabs" component={() => <DnDColumnSelectionTabs asyncData={false} />} />
                    <Route path="/components/dnd/" component={DnDSimple} />
                    <Route path="/components/tickler-record" component={DemoTicklerRecord} />
                </Switch>
            </MainContainer>
            {!isOnTicklerPage && <DemoChatComponent />}
        </Main>
        <Tooltip place="top" />
    </ReactAux>;
}

export default ComponentsLayout;