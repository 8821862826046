import React from 'react';
import Highlight from 'react-highlight';

import { ContextMenu } from '@jkhy/vsg-loanvantage-design-system';


const ContextMenuPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">ContextMenu</h1>

            <ContextMenu items={[
                { title: 'Default Item', onClick: () => console.log('Default Item Click') },
                { title: 'Second Item', onClick: () => console.log('Second Item Click') },
                { isActive: true, title: 'Acitve Item', onClick: () => console.log('Active Item Click') },
                { title: 'Default Item Inactive', disabled: true, onClick: () => console.log('Default Item Inactive Click') },
                { title: 'Item with Icon', icon: { name: 'fas fa-flag', color: 'text-danger' }, onClick: () => console.log('Item with Icon Click') },
                { title: 'Item with Icon Inactive', disabled: true, icon: { name: 'fas fa-flag' }, onClick: () => console.log('Item with Icon Inactive Click') },
            ]} />

            <Highlight className="React">
                {
                    `
import React from 'react';

import { ContextMenu } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {
    render() {
        return (
            <ContextMenu items={[
                { title: 'Default Item', onClick: () => console.log('Default Item Click') },
                { title: 'Second Item', onClick: () => console.log('Second Item Click') },
                { isActive: true, title: 'Acitve Item', onClick: () => console.log('Active Item Click') },
                { title: 'Default Item Inactive', disabled: true, onClick: () => console.log('Default Item Inactive Click') },
                { title: 'Item with Icon', icon: { name: 'fas fa-flag', color: 'text-danger' }, onClick: () => console.log('Item with Icon Click') },
                { title: 'Item with Icon Inactive', disabled: true, icon: { name: 'fas fa-flag' }, onClick: () => console.log('Item with Icon Inactive Click') },
            ]} />
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3>ContextMenu</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>items</td>
                        <td>
                            array of objects with props: <br />
                            title: string<br />
                            isActive: boolean<br />
                            icon: {'{ name: string, color: string }'} <br />
                            disabled: boolean <br />
                            render: Function <br />
                            onClick(e: any): void
                        </td>
                        <td className="text-center">-</td>
                        <td>Sets the items.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default ContextMenuPage;
