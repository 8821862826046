import React from 'react';
import Highlight from 'react-highlight';

import { LinkWithIcon } from '@jkhy/vsg-loanvantage-design-system';


const LinkWithIconPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">LinkWithIcon</h1>

            <p className="mb-l">This component renders link with icon. Icons can be placed on the left or right side.</p>

            <div className="mb-l">
                <LinkWithIcon className="mr-xl" icon="fal fa-angle-left" iconPlace="left"><a href="/">Link with icon</a></LinkWithIcon>
                <LinkWithIcon className="mr-xl" icon="fal fa-angle-right" iconPlace="right"><a href="/">Link with icon</a></LinkWithIcon>
                <LinkWithIcon disabled icon="fal fa-angle-right" iconPlace="right"><a href="/">Link with icon</a></LinkWithIcon>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { LinkWithIcon } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    return (
      <div>
        <LinkWithIcon className="mr-xl" icon="fal fa-angle-left" iconPlace="left">
            <a href="/">Link with icon</a>
        </LinkWithIcon>
        <LinkWithIcon className="mr-xl" icon="fal fa-angle-right" iconPlace="right">
            <a href="/">Link with icon</a>
        </LinkWithIcon>
        <LinkWithIcon disabled icon="fal fa-angle-right" iconPlace="right">
            <a href="/">Link with icon</a>
        </LinkWithIcon>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon. Check from <a href="https://fontawesome.com/icons?d=gallery" title="fa" target="_blank" rel="noopener noreferrer">font awesome</a> doc.</td>
                    </tr>
                    <tr>
                        <td>iconPlace</td>
                        <td>string: 'left' or 'right'</td>
                        <td>'left'</td>
                        <td>Sets the icon place.</td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets the disabled for link.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default LinkWithIconPage;
