import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import {
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs, ActionSettings,
    ActionSettingsForm, LinkWithIcon, Select, Button
} from '@jkhy/vsg-loanvantage-design-system';


const ActionContainerPage: React.FC = () => {
    const [currentForm, setCurrentForm] = useState(-1);
    const [lockDisabled, setLockDisabled] = useState(false);
    const [selectValue1, setSelectValue1] = useState('1');
    const [selectValue2, setSelectValue2] = useState('1');

    return (
        <div>
            <h1 className="mb-s">ActionContainer</h1>
            <p className="mb-s">
                A component that wraps actions, including breadcrumbs, links and buttons. Additionally,
                specific forms can be toggled for execution of specific operations.
            </p>

            <p className="mb-l">ActionContainer has a specific architecture, that should be strictly followed in
                order to achieve its visual and functional design.
            </p>

            <h2 className="mb-m">ActionContainer module components:</h2>
            <p className="mb-s"><strong>ActionContainer</strong> - The main wrapper component. Wraps the component header and unspecified amount of additional
                elements, that will be rendered in the bottom section of the container.</p>
            <p className="mb-s"><strong>ActionContainerHeader</strong> - Contains the essential components for the module - ActionContainerCrumbs,
                ActionSettings & ActionSettingsForm.</p>
            <p className="mb-s"><strong><a href="#api-action-container-crumbs">ActionContainerCrumbs</a></strong> - Breadcrumbs to the current location.</p>
            <p className="mb-s"><strong>ActionSettings</strong> - Wrapper for elements, that perform various tasks, mainly toggling ActionSettingsForm-s.</p>
            <p className="mb-xl"><strong><a href="#api-action-settings-form">ActionSettingsForm</a></strong> - Wrapper for simple horizontal forms, that, when active,
                are shown above the default ActionSettings.</p>

            <p className="mb-s"><strong>Important: </strong>It is required to wrap the whole ActionContainer
                with a <Link to="/docs/layout">MainContainerHeader</Link>. See the <a href="#action-container-guidelines">Design guidelines</a> for more
                details about the correct layout.</p>
            <p className="mb-xl"><strong>Important: </strong>This component uses <Link to="/docs/tooltip">Tooltip</Link> and therefore requires the
                Tooltip component to be rendered in the current view.</p>

            <div className="background-color-1 mt-m">
                <ActionContainer>
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                        <ActionSettings>
                            <LinkWithIcon icon="fal fa-cog"><a data-tip="Click to edit process definitions" onClick={(e) => { e.preventDefault(); setCurrentForm(1) }} href="/">Commercial loan request</a></LinkWithIcon>
                            <LinkWithIcon icon="fal fa-eye"><a onClick={(e) => { e.preventDefault(); setCurrentForm(2) }} href="/">Collateral Fields mode</a></LinkWithIcon>
                            <Button data-tip="All changes are allowed" onClick={() => setLockDisabled(!lockDisabled)} btnType="icon" icon={lockDisabled ? 'fal fa-lock' : 'fal fa-lock-open'}></Button>
                        </ActionSettings>
                        <ActionSettingsForm isActive={currentForm === 1}>
                            <p>Process definitions:</p>
                            <Select
                                className="width-m"
                                value={selectValue1}
                                onChange={(obj: any) => setSelectValue1(obj.value)}
                                options={[{ value: '1', label: 'Commercial loan request' }, { value: '2', label: 'test 2' }]}
                            />
                            <Button icon="fal fa-save">Update</Button>
                            <Button onClick={e => setCurrentForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                        </ActionSettingsForm>
                        <ActionSettingsForm isActive={currentForm === 2}>
                            <p>Process definitions:</p>
                            <Select
                                className="width-m"
                                value={selectValue2}
                                onChange={(obj: any) => setSelectValue2(obj.value)}
                                options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                            />
                            <Button icon="fal fa-save">Update</Button>
                            <Button onClick={e => setCurrentForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                        </ActionSettingsForm>
                    </ActionContainerHeader>
                    <Button icon="fal fa-pen">Edit</Button>
                    <Button btnType="secondary" icon="fal fa-percent">LTV</Button>
                    <Button btnType="secondary" icon="fal fa-expand">Spreading</Button>
                    <Button btnType="secondary" icon="fal fa-coins">Appraisal</Button>
                    <Button btnType="secondary" icon="fal fa-map-marker-alt">Visit</Button>
                </ActionContainer>

            </div>

            <div className="background-color-1 mt-m">
            <ActionContainer singleLine >
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={['Document Review']} />
                    </ActionContainerHeader>
                    <div className="d-flex align-items-center ml-l">
                        <Button disabled btnType="secondary" icon="fal fa-arrow-circle-left">Prev</Button>
                        <p className="mr-s"><strong>Attachment 1 of 2</strong></p>
                        <Button btnType="secondary" icon="fal fa-arrow-circle-right">Next</Button>
                        <p>2018-01-25-13h19_20.png</p>
                    </div>
                </ActionContainer>
            </div>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import {
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs, ActionSettings,
    ActionSettingsForm, LinkWithIcon, Select, Button
} from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    const [currentForm, setCurrentForm] = useState(-1);
    const [lockDisabled, setLockDisabled] = useState(false);
    const [selectValue1, setSelectValue1] = useState('1');
    const [selectValue2, setSelectValue2] = useState('1');

    render() {
        return (
            // The MainContainer has to be placed inside a MainContainer, which
            // is, on its hand, placed inside Main.
           <MainContainerHeader>
                <ActionContainer>
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                        <ActionSettings>
                            <LinkWithIcon icon="fal fa-cog">
                                <a 
                                    data-tip="Click to edit process definitions" 
                                    onClick={(e) => { e.preventDefault(); setCurrentForm(1) }} 
                                    href="/"
                                >Commercial loan request</a>
                            </LinkWithIcon>
                            <LinkWithIcon icon="fal fa-eye">
                                <a 
                                    onClick={(e) => { e.preventDefault(); setCurrentForm(2) }} 
                                    href="/"
                                >Collateral Fields mode</a>
                            </LinkWithIcon>
                            <Button 
                                data-tip="All changes are allowed" 
                                onClick={() => setLockDisabled(!lockDisabled)} 
                                btnType="icon" 
                                icon={lockDisabled ? 'fal fa-lock' : 'fal fa-lock-open'}></Button>
                        </ActionSettings>
                        <ActionSettingsForm isActive={currentForm === 1}>
                            <p>Process definitions:</p>
                            <Select
                                className="width-m"
                                value={selectValue1}
                                onChange={(obj: any) => setSelectValue1(obj.value)}
                                options={[{ value: '1', label: 'Commercial loan request' }, { value: '2', label: 'test 2' }]}
                            />
                            <Button icon="fal fa-save">Update</Button>
                            <Button onClick={e => setCurrentForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                        </ActionSettingsForm>
                        <ActionSettingsForm isActive={currentForm === 2}>
                            <p>Process definitions:</p>
                            <Select
                                className="width-m"
                                value={selectValue2}
                                onChange={(obj: any) => setSelectValue2(obj.value)}
                                options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                            />
                            <Button icon="fal fa-save">Update</Button>
                            <Button onClick={e => setCurrentForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                        </ActionSettingsForm>
                    </ActionContainerHeader>
                    <Button icon="fal fa-pen">Edit</Button>
                    <Button btnType="secondary" icon="fal fa-percent">LTV</Button>
                    <Button btnType="secondary" icon="fal fa-expand">Spreading</Button>
                    <Button btnType="secondary" icon="fal fa-coins">Appraisal</Button>
                    <Button btnType="secondary" icon="fal fa-map-marker-alt">Visit</Button>
                </ActionContainer>

                // Single line Example
                <ActionContainer singleLine >
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={['Document Review']} />
                    </ActionContainerHeader>
                    <div className="d-flex align-items-center ml-l">
                        <Button disabled btnType="secondary" icon="fal fa-arrow-circle-left">Prev</Button>
                        <p className="mr-s"><strong>Attachment 1 of 2</strong></p>
                        <Button btnType="secondary" icon="fal fa-arrow-circle-right">Next</Button>
                        <p>2018-01-25-13h19_20.png</p>
                    </div>
            </ActionContainer>
           </MainContainerHeader>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m mt-l" id="action-container-guidelines">Design guidelines</h2>
            <p className="mb-s">1. Breadcrumbs can contain only title, if required.</p>
            <p className="mb-s">2. Buttons below the ActionContainerHeader should start with a primary button. There should be only one primary button there.</p>
            <p className="mb-s">3. ActionSettings controls should not break to new line.</p>
            <p className="mb-s">4. ActionSettingsForm should always contain label, form control and two buttons - Update(primary) & Cancel(secondary) in that order.</p>
            <p className="mb-s">5. Clicking on LinkWithIcon should always activate an ActionSettingsForm.</p>
            <p className="mb-s">6. ActionSettings controls are recommented to have tooltips.</p>

            <h2 className="mb-m mt-l">API</h2>

            <h3 id="api-action-container-crumbs">ActionContainer</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>singleLine</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set ActionContainerHeader and action container buttons on one row (used in Work pages). Example: <a target="_blank" href="/work-page-2" title="example Work page">here</a></td>
                    </tr>
                </tbody>
            </table>


            <h3 id="api-action-container-crumbs">ActionContainerCrumbs</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>crumbs</td>
                        <td>(JSX.Element | string)[]</td>
                        <td className="text-center">-</td>
                        <td>The elements that show the current path for the breadcrumbs.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-action-settings-form">ActionSettingsForm</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>isActive</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Flags whether the form should be visible above the ActionSettings elements.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default ActionContainerPage;
