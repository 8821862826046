import React from 'react';

import {
    WizardStepsContainer, WizardStep, Button, MainContainerHeader, MainContainerScrollable,
    ReactAux, Select, InfoBox, Row, Col, SummaryBox,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';
import DemoChartsPage from './DemoChartsPage';

class DemoCreditCheck extends React.Component {

    state = {
        currentStepIndex: 1,
        selectValue: '1'
    }

    render() {

        const currentStepIndex = this.state.currentStepIndex;

        const previousStep = currentStepIndex > 0 ?
            <Button icon="fal fa-arrow-circle-left" btnType="secondary" onClick={() => this.setState({ currentStepIndex: currentStepIndex - 1 })}>Previous step</Button>
            : undefined;

        const nextStep = currentStepIndex === 2 ?
            undefined
            : <Button className="ml-s" icon="fal fa-arrow-circle-right" onClick={() => this.setState({ currentStepIndex: currentStepIndex + 1 })}  >Next step</Button>;

        return (
            <ReactAux>
                <MainContainerHeader>
                    <WizardStepsContainer
                        title="Credit Check"
                        previousStep={previousStep}
                        nextStep={nextStep}
                        currentStepIndex={currentStepIndex}>
                        <WizardStep title="1. Credit History" status="success" icon="fal fa-history" onClick={() => this.setState({ currentStepIndex: 0 })} />
                        <WizardStep title="2. Debt Schedule" status="warning" icon="fal fa-calendar-alt" onClick={() => this.setState({ currentStepIndex: 1 })} />
                        <WizardStep title="3. Loan Options" status="success" icon="fal fa-tasks" onClick={() => this.setState({ currentStepIndex: 2 })} disabled />
                        <WizardStep title="4. Underwriting Ratio" icon="fal fa-tachometer-alt" onClick={() => this.setState({ currentStepIndex: 3 })} />
                    </WizardStepsContainer>
                </MainContainerHeader>
                {currentStepIndex === 0 && <h2 className="mt-m">1. Credit History Content...</h2>}
                {currentStepIndex === 1 && (
                    <MainContainerScrollable>
                        <InfoBox className="mb-s mt-m">
                            By clicking this button, you confirm that you have a Permissible Purpose for obtaining this credit report.Unauthorized access is prohibited under the Fair Credit Reporting Act (FCRA) and punishable by $2500 and/or 1 year in Federal prison per occurrence. Commercial Customers in this File.
                        </InfoBox>
                        <div className="d-flex justify-content-end mb-l">
                            <Select
                                className="mr-s width-m"
                                options={[{ value: "1", label: "Credit request" }, { value: "2", label: "Option 2" }]}
                                value={this.state.selectValue}
                                onChange={(obj: any) => this.setState({ selectValue: obj.value })}
                            />
                            <Button icon="fal fa-share-square" btnType="secondary">Send request</Button>
                        </div>
                        <DemoLocationPage />
                    </MainContainerScrollable>
                )}
                {currentStepIndex === 2 && <h2 className="mt-m">3. Loan Options Content...</h2>}
                {currentStepIndex === 3 &&
                    <MainContainerScrollable>
                        <Row className="mt-m">
                            <Col>
                                <SummaryBox className="mb-s mr-s" title="Product:" content={<a href='/'>*BB BL CONSSUMER PRODUCT</a>} />
                                <SummaryBox className="mb-s mr-s" title="Loan amount:" content="$960,000" />
                                <SummaryBox className="mb-s mr-s" title="Payment:" content="0" />
                                <SummaryBox className="mb-s mr-s" title="Interest Rate:" content="4.25%" />
                                <SummaryBox className="mb-s mr-s" title="Term:" content="36 months" />
                            </Col>
                        </Row>

                        <Button icon="fal fa-eye" className="mr-s">Show Secondary Borrower Ratios</Button>
                        <Button icon="fal fa-file-excel" btnType="secondary">Download in Excel</Button>

                        <DemoChartsPage />

                    </MainContainerScrollable>
                }
            </ReactAux>
        );
    }
}

export default DemoCreditCheck;