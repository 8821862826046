import React from 'react';

import {
    Row, Col, Input, Textarea, Select, Collapse,
    Autocomplete, Multiselect, MultiselectBox, Icon, NumericInput,
} from '@jkhy/vsg-loanvantage-design-system';

const selectOptions = [{ value: "1", label: "AK" }, { value: "2", label: "Option 2" }, { value: "3", label: "Option 3" }];
const extendedSelectOptions = [{ value: "1", label: "AK" }, { value: "2", label: "Option 2" }, { value: "3", label: "Option 3" }];

const autocompleteOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', },
    { value: '4', label: 'Option 4' },
    { value: '5', label: 'Option 5' },
    { value: '6', label: 'Option 6' },
    { value: '7', label: 'Option 7' },
];

const multiselectOptions = [
    { value: 'excellent-credit', label: 'Excellent credit' },
    { value: 'high-income', label: 'High income', secondCode: 'code-123' },
    { value: 'value-1', label: 'Value 1', },
    { value: 'value-2', label: 'Value 2', },
    { value: 'value-3', label: 'Value 3', },
];

const multiselectBoxOptions = [
    { value: '1', label: 'John Smith' },
    { value: '2', label: 'Julia King' },
    { value: '3', label: 'Bernard R. Winters Jr.', },
    { value: '4', label: 'Demo Option' },
    { value: '5', label: 'Option 5' },
    { value: '6', label: 'Option 6' },
    { value: '7', label: 'Option 7' },
];


for (let i = 0; i < 50; i++) {
    extendedSelectOptions.push({ value: `test-${i}`, label: `test ${i}` });
}

type DemoLocationPageProps = {
    className?: string
};

class DemoLocationPage extends React.Component<DemoLocationPageProps> {

    state = {
        expanded: true,
        selectValue1: '1',
        selectValue2: '1',
        selectValue3: '1',
        selectValue4: '1',
        autocompleteVal: '',
        multiselectVal: [],
        multiselectBoxVal: [],
    }

    render() {
        return (
            <Collapse
                className={this.props.className}
                title="Address"
                expanded={this.state.expanded}
                onClick={() => this.setState({ expanded: !this.state.expanded })}
            >
                {/* TODO This structure is only for demo/test. A real structure must be devised. */}
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">Location</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m"><strong>CBSA</strong></p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">Address</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">Census tract</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">City</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">County ID</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">Grantor</p>
                    </Col>
                    <Col xs="2">
                        <MultiselectBox
                            className="mb-m"
                            options={multiselectBoxOptions}
                            value={this.state.multiselectBoxVal}
                            onChange={(obj: any) => this.setState({ multiselectBoxVal: obj.value })}
                        />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">State ID</p>
                    </Col>
                    <Col xs="2">
                        <Select
                            value={this.state.selectValue1}
                            onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                            options={selectOptions}
                            className="mb-m"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">State</p>
                    </Col>
                    <Col xs="2">
                        <Select
                            value={this.state.selectValue2}
                            onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                            options={selectOptions}
                            className="mb-m"
                        />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">First autocomplete</p>
                    </Col>
                    <Col xs="2">
                        <Autocomplete
                            options={autocompleteOptions}
                            value={this.state.autocompleteVal}
                            onChange={(obj: any) => {
                                this.setState({ autocompleteVal: obj !== null && obj.value ? obj.value : '' })
                            }}
                            onInput={(val) => console.log(`Input: ${val}`)}
                            noOptionsMessage="No data found."
                            placeholder="Autocomplete"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">Zip code</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">Parcel ID</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">County</p>
                    </Col>
                    <Col xs="2">
                        <Select
                            value={this.state.selectValue3}
                            onChange={(obj: any) => this.setState({ selectValue3: obj.value })}
                            options={selectOptions}
                            className="mb-m"
                            placeholder="Select county"
                        />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">Section</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">Region</p>
                    </Col>
                    <Col xs="2">
                        <Select
                            value={this.state.selectValue4}
                            onChange={(obj: any) => this.setState({ selectValue4: obj.value })}
                            options={extendedSelectOptions}
                            className="mb-m"
                            placeholder="Select region"
                        />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">Block</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-center">
                        <p className="text-right mb-m">Reasons</p>
                    </Col>
                    <Col xs="2">
                        <Multiselect
                            value={this.state.multiselectVal}
                            onChange={(newValue: any) => this.setState({ multiselectVal: newValue })}
                            options={multiselectOptions}
                            className="mb-m"
                            placeholder="Reasons..."
                            additionalSearchKeys={['secondCode']}
                        />
                    </Col>
                    <Col xs={{ size: 2, offset: 2 }} className="align-self-center">
                        <p className="text-right mb-m">Lot</p>
                    </Col>
                    <Col xs="2">
                        <Input className="mb-m" type="text" />
                    </Col>
                </Row>
                <Row className="mb-m">
                    <Col xs={{ size: 2, offset: 6 }} className="align-self-center">
                        <p className="text-right">Second autocomplete</p>
                    </Col>
                    <Col xs="2">
                        <Autocomplete
                            options={autocompleteOptions}
                            value={this.state.autocompleteVal}
                            onChange={(obj: any) => {
                                this.setState({ autocompleteVal: obj !== null && obj.value ? obj.value : '' })
                            }}
                            noOptionsMessage="No data found."
                            placeholder="Autocomplete"
                        ></Autocomplete>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 2, offset: 6 }} className="align-self-center">
                        <p className="text-right mb-m d-flex justify-content-end align-content-center">
                            GAP Incurance
                            <Icon size="S" className="text-small ml-xs" icon="fas fa-plus" />
                        </p>
                    </Col>
                    <Col xs="2" className="align-self-center">
                        <NumericInput type="currency" className="mb-m" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 2, offset: 6 }}>
                        <p className="text-right mb-m">
                            Vendor Single Interest Incurance <br/>
                           (VSI) <Icon size="S" className="text-small ml-xs" icon="fas fa-minus" />
                        </p>
                    </Col>
                    <Col xs="2">
                        <NumericInput type="currency" className="mb-m" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="align-self-start">
                        <p className="text-right">Address Memo</p>
                    </Col>
                    <Col xs="8">
                        <Textarea />
                    </Col>
                </Row>
            </Collapse>
        );
    }
}

export default DemoLocationPage;