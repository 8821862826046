import React from 'react';
import Highlight from 'react-highlight';

import { MultiEmail } from '@jkhy/vsg-loanvantage-design-system';

// eslint-disable-next-line
const customRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MultiEmailPage: React.FC = () => {
    const [value1, setValue1] = React.useState<Array<string>>([]);
    const [value2, setValue2] = React.useState<Array<string>>(['example@gmail.com', 'example@gmail.com']);

    return (
        <div>
            <h1 className="mb-s">MultiEmail</h1>
            <p className="mb-l">Component to format multiple email as the user types.</p>
            <p className="mb-l">
                <strong>Note:</strong><br />
                - When you type in the field, after pressing key "enter", "comma", "semicolon", "space" or "tab", email is validated and transformed in to chip. <br />
                - When you copy and paste string of emails, they are splited by: "space", 'semicolon' and 'comma'.
            </p>

            <MultiEmail
                className="mb-m"
                value={value1}
                placeholder="Send to..."
                onChange={(emails) => {
                    console.log(emails);
                    setValue1(emails)
                }}
                onInvalidEmail={(email) => console.log('Invalid or duplicate email!', email)}
            />

            <MultiEmail
                className="mb-m"
                value={value2}
                onChange={(emails) => {
                    console.log(emails);
                    setValue2(emails)
                }}
                dataUI="multi-ui"
                customRegExp={new RegExp(customRegex)}
                onInvalidEmail={(email) => console.log('Invalid email type!', email)}
                allowDuplicateEmails
            />

            <MultiEmail
                className="mb-m"
                value={['example@gmail.com']}
                disabled
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { MultiEmail } from '@jkhy/vsg-loanvantage-design-system';


const Example: React.FC = (props) => {
    const [value1, setValue1] = React.useState<Array<string>>([]);
    const [value2, setValue2] = React.useState<Array<string>>([]);

    return (
         <MultiEmail
                className="mb-m"
                value={value1}
                placeholder="Send to..."
                onChange={(emails) => { 
                    console.log(emails); 
                    setValue1(emails) 
                }}
                onInvalidEmail={(email) => console.log('Invalid or duplicate email!', email)}
            />

            <MultiEmail
                className="mb-m"
                value={value2}
                onChange={(emails) => { 
                    console.log(emails); 
                    setValue2(emails) 
                }}
                dataUI="multi-ui"
                customRegExp={new RegExp(${customRegex})}
                onInvalidEmail={(email) => console.log('Invalid email type!', email)}
                allowDuplicateEmails
            />

            <MultiEmail
                className="mb-m"
                value={['example@gmail.com']}
                disabled
            />
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>value</td>
                        <td>string[]</td>
                        <td className="text-center">-</td>
                        <td>Sets the MultiEmail value.</td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the given text as a placeholder of the MultiEmail.</td>
                    </tr>
                    <tr>
                        <td>customRegExp</td>
                        <td>RegExp</td>
                        <td className="text-center">-</td>
                        <td>Sets custom RegExp for email validation.</td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Makes the MultiEmail disabled.</td>
                    </tr>
                    <tr>
                        <td>tabIndex</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Sets the tabIndex attribute on the input.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>{'(string[]) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Returns the array of emails, including the one, that has just been added or removed.</td>
                    </tr>
                    <tr>
                        <td>onInvalidEmail</td>
                        <td>{'(string) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Callback when the email is invalid. Returns the invalid email value.</td>
                    </tr>
                    <tr>
                        <td>allowDuplicateEmails</td>
                        <td>boolean</td>
                        <td className="text-center">false</td>
                        <td>Allow to write duplcate emails.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MultiEmailPage;
