import React, { useState } from 'react';

import {
    MainContainerHeader, ReactAux, Collapse,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, LinkWithIcon, Button, Select,
    MainContainerScrollable, InfoBox, SimpleTableContainer,
    Checkbox, ActionSettingsForm
} from '@jkhy/vsg-loanvantage-design-system';


const DemoTable = (props: any) => {
    return (
        <SimpleTableContainer>
            <table>
                <thead>
                    <tr>
                        <th><Checkbox /></th>
                        <th>Party</th>
                        <th>Prior Period</th>
                        <th>Projected</th>
                        <th>Stressed</th>
                        <th>Unpaid RE Taxes</th>
                        <th>Unpaid Payroll</th>
                        <th>Times PD 30</th>
                        <th>Times PD 60</th>
                    </tr>

                </thead>
                <tbody>
                    <tr>
                        <td><Checkbox /></td>
                        <td className="white-space-nowrap">Elizabeth K. Adams</td>
                        <td className="text-right white-space-nowrap">12/31/2016 - Tax Return</td>
                        <td className="text-right">12/31/2020</td>
                        <td className="text-right">12/31/2020</td>
                        <td></td>
                        <td>Yes</td>
                        <td></td>
                        <td className="text-right">63.21</td>
                    </tr>
                    <tr>
                        <td><Checkbox /></td>
                        <td className="white-space-nowrap">Sprinkles Holdings LLC</td>
                        <td className="text-right white-space-nowrap">12/31/2016 - Tax Return</td>
                        <td className="text-right">12/31/2020</td>
                        <td className="text-right">12/31/2020</td>
                        <td>No</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Checkbox /></td>
                        <td className="white-space-nowrap">Carlos Testcase</td>
                        <td className="text-right white-space-nowrap">12/31/2016 - Tax Return</td>
                        <td className="text-right">12/31/2020</td>
                        <td className="text-right">12/31/2020</td>
                        <td></td>
                        <td>Yes</td>
                        <td></td>
                        <td className="text-right">63.21</td>
                    </tr>
                </tbody>
            </table>
        </SimpleTableContainer>
    )
}

const DemoRiskRating = (props: any) => {

    const [collapsOne, setCollapsOne] = useState(true);
    const [collapsTwo, setCollapsTwo] = useState(true);
    const [collapsThree, setCollapsThree] = useState(true);
    const [selectValue, setSelectValue] = useState('1');
    const [showForm, setShowForm] = useState(false);

    return (
        <ReactAux>
            <MainContainerHeader>
                <ActionContainer>
                    <ActionContainerHeader>
                        <ActionContainerCrumbs crumbs={['Risk Rating']} />
                        <ActionSettings>
                            <LinkWithIcon icon="fal fa-file-invoice-dollar">
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowForm(true)
                                     }}
                                    data-tip="Facility Selection"
                                    href="/"
                                >
                                    Pending
                                </a>
                            </LinkWithIcon>
                        </ActionSettings>
                        <ActionSettingsForm isActive={showForm}>
                            <p className="white-space-nowrap">Facility Selection:</p>
                            <Select
                                className="width-m"
                                value={selectValue}
                                onChange={(obj: any) => setSelectValue(obj.value)}
                                options={[
                                    { value: '1', label: 'Pending' },
                                    { value: '2', label: 'Other...' }
                                ]}
                            />
                            <Button onClick={e => setShowForm(false)} icon="fal fa-save">Update</Button>
                            <Button onClick={e => setShowForm(false)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                        </ActionSettingsForm>
                    </ActionContainerHeader>
                    <Button icon="fal fa-save">Save</Button>
                    <Button btnType="secondary" icon="fal fa-save">Save and Calculate</Button>
                    <Button btnType="secondary" icon="fal fa-times-circle">Cancel</Button>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Collapse
                    title="Global Cash Flow"
                    expanded={collapsOne}
                    onClick={() => setCollapsOne(!collapsOne)}
                    className="mt-m"
                >
                    <div>
                        <InfoBox className="mb-s">
                            Any changes to Parties or Periods will be reflected on the Global Cash Flow for the file when the ‘Save’ or ‘Save and Calculate’ is clicked.
                        </InfoBox>
                        <DemoTable />
                    </div>
                </Collapse>
                <Collapse
                    title="Borrowers and Guarantors"
                    expanded={collapsTwo}
                    onClick={() => setCollapsTwo(!collapsTwo)}
                >
                    <DemoTable />
                </Collapse>
                <Collapse
                    title="Collateral"
                    expanded={collapsThree}
                    onClick={() => setCollapsThree(!collapsThree)}
                >
                    <DemoTable />
                </Collapse>
            </MainContainerScrollable>
        </ReactAux>
    );
}

export default DemoRiskRating;