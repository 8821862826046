/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
    ChatContactsContainer, ChatPersonContainer, Input, Button,
    ChatContact, ChatDateSeparator, ChatPersonMessages, ChatMessage, ChatContainer,
    ChatContactsSection, ContextMenu, ReactAux,
} from '@jkhy/vsg-loanvantage-design-system';

const contacts = [
    {
        isActive: false,
        device: 'mobile',
        avatar: { text: 'MH', status: 'online' },
        name: 'Mark Hill',
        messageText: {
            message: 'This is very long message here veryyy longgg here',
            dateTime: 'Apr 8'
        },
        newMessagesCount: 2,
        type: 'pinned',
        messages: [
            { from: 'otherPerson', status: { dateTime: '1:31 PM' }, text: 'Test message otherPerson' },
            { from: 'otherPerson', status: { dateTime: '1:32 PM' }, text: 'Test message 2 otherPerson' },
            { from: 'otherPerson', status: { dateTime: '1:33 PM' }, text: 'Test message 3 otherPerson' },
            { from: 'me', status: { dateTime: '2:45 PM' }, text: 'Test message Me' },
            { from: 'otherPerson', status: { dateTime: '2:50 PM' }, text: 'Test message otherPerson' },
            { from: 'otherPerson', status: { dateTime: '2:52 PM' }, text: 'Test message 2 otherPerson' },
            { separator: 'March 12' },
            { from: 'me', status: { dateTime: '2:55 PM' }, text: 'Test message Me' },
            { from: 'me', status: { dateTime: '2:58 PM' }, text: 'Test message 2 Me' },
            { from: 'otherPerson', status: { dateTime: '3:11 PM' }, text: 'Test message otherPerson' },
            { from: 'me', status: { dateTime: '4:00 PM' }, text: 'Test message with link Me', onClick: () => alert('Link click') },
            { from: 'me', status: { dateTime: '4:00 PM' }, text: 'Test message 2 Me' },
            { from: 'me', status: { dateTime: '4:01 PM', type: 'seen' }, text: 'Test message 3 Me' },
        ]
    },
    {
        isActive: false,
        device: 'mobile',
        avatar: { src: `${process.env.PUBLIC_URL}/img/avatar.svg`, alt: 'Joro Georgiev', text: 'JG', status: 'online' },
        name: 'Joro Georgiev',
        messageText: {
            message: 'This is very long message here veryyy longgg here',
            dateTime: '7:54 AM'
        },
        newMessagesCount: 6,
        type: 'pinned',
        messages: [
            { separator: 'March 15' },
            { from: 'otherPerson', status: { dateTime: '1:31 PM' }, text: 'Hello' },
        ]
    },
    {
        isActive: false,
        device: 'desktop',
        avatar: { text: 'YS', status: 'online' },
        name: 'Yana Stoyanova',
        type: 'recent',
        messages: [
        ]
    },
    {
        isActive: false,
        avatar: { text: 'ED', status: 'offline' },
        name: 'Elena Dyulgerska',
        messageText: {
            message: 'You: This is message here',
            dateTime: 'Mar 22'
        },
        type: 'recent',
        messages: [
        ]
    },
    {
        isActive: false,
        avatar: { text: 'S', status: 'offline' },
        name: 'Stoil',
        type: 'recent',
        messages: [
        ]
    },
    {
        isActive: false,
        avatar: { text: 'G', status: 'offline' },
        name: 'Galab',
        type: 'recent',
        messages: [
        ]
    },
    {
        isActive: false,
        avatar: { text: 'J', status: 'offline' },
        name: 'Jean',
        type: 'recent',
        messages: [
        ]
    },
    {
        isActive: false,
        avatar: { text: 'NU', status: 'online' },
        name: 'New user',
        messageText: {
            message: 'You: This is message here',
            dateTime: 'Mar 22'
        },
        type: 'none',
        messages: [
        ]
    },
];

const DemoChatComponent = () => {
    const chatPersonContainerRef = useRef();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const messageInputRef = useRef<HTMLInputElement>(null);
    const [chatContainerIsExpanded, setChatContainerIsExpanded] = useState(true);
    const [chatPersonIsExpanded, setChatPersonIsExpanded] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<any>(null);
    const [searchName, setSearchName] = useState<string>('');
    const [chatInput, setChatInput] = useState<string>('');
    const [chatIsLoading, setChatIsLoading] = useState(true);
    const [oldMessagesIsLoading, setOldMessagesIsLoading] = useState(false);
    const [searchType, setSearchType] = useState<'filter' | 'newChat' | undefined>(undefined);
    const [isPinnedSectionExpanded, setIsPinnedSectionExpanded] = useState(true);
    const [isRecentSectionExpanded, setIsRecentSectionExpanded] = useState(true);

    const loadingOldMessages = () => {
        const current = chatPersonContainerRef.current as any;

        const scrollTop = current.containerMessagesScrollableRef?.scrollTop;
        const clientHeight = current.containerMessagesScrollableRef?.clientHeight;
        const scrollHeight = current.containerMessagesScrollableRef?.scrollHeight;

        if (scrollTop === 0 && scrollHeight > clientHeight) {
            setOldMessagesIsLoading(true);

            selectedPerson?.messages?.unshift(
                { from: 'me', status: { dateTime: '10:22 AM' }, text: 'Old message' },
                { from: 'me', status: { dateTime: '10:23 AM' }, text: 'Old message 2' },
            );

            setTimeout(() => {
                setSelectedPerson({ ...selectedPerson });
                setOldMessagesIsLoading(false);
            }, 3000);
        }
    };

    useEffect(() => {
        const current = chatPersonContainerRef.current as any;

        current.containerMessagesScrollableRef?.addEventListener("scroll", () => loadingOldMessages());

        return current.containerMessagesScrollableRef?.removeEventListener("scroll", () => loadingOldMessages());
    }, [chatPersonIsExpanded]);

    const closeSelectedContact = () => {
        const index = contacts.findIndex((c) => c.isActive === true);
        if (index > -1) contacts[index].isActive = false;
    }

    const scrollChatPersonToBottom = () => {
        const current = chatPersonContainerRef.current as any;

        if (current) current.scrollChatPersonToBottom();
    }

    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    const getChatContacts = () => {
        const result: any = [[], [], []];

        contacts.forEach((contact, index) => {
            if (searchName) {
                const nameToLower = contact.name.toLowerCase();
                const searchNameToLower = searchName.toLowerCase();

                if (!nameToLower.includes(searchNameToLower)) return '';
            }

            const contextMenuItems = [
                {
                    title: contact.type === 'pinned' ? 'Unpin' : 'Pin',
                    icon: { name: contact.type === 'pinned' ? 'custom-icon-unpin-s' : 'fal fa-thumbtack' },
                    onClick: () => { contacts[index].type = contact.type === 'pinned' ? 'recent' : 'pinned'; forceUpdate(); }
                },
            ];

            if (contact.type !== 'none') {
                contextMenuItems.push({
                    title: 'Hide',
                    icon: { name: 'fal fa-eye-slash' },
                    onClick: () => { contacts[index].type = 'none'; forceUpdate(); }
                });
            }

            const chatContact = <ChatContact
                key={index}
                isActive={contact.isActive}
                device={contact.device as any}
                avatar={contact.avatar as any}
                name={contact.name}
                searchName={searchName}
                messageText={contact.messageText}
                newMessagesCount={contact.newMessagesCount}
                contextMenu={<ContextMenu items={contextMenuItems} />}
                onClick={() => {
                    if (contact.type === 'none') contacts[index].type = 'recent';
                    if (searchType === 'newChat') {
                        setSearchName('');
                        setSearchType(undefined);
                    }
                    closeSelectedContact();
                    contact.isActive = true;
                    setSelectedPerson(contact);
                    setChatPersonIsExpanded(true);
                    setChatIsLoading(true);
                    setTimeout(() => {
                        if (messageInputRef.current) {
                            messageInputRef.current.focus();
                        }
                    });

                    setTimeout(() => {
                        setChatIsLoading(false);
                        scrollChatPersonToBottom();
                    }, 1000);
                }}
            />

            if (contact.type === 'pinned') {
                result[0].push(chatContact);
            } else if (contact.type === 'recent') {
                result[1].push(chatContact);
            }

            result[2].push(chatContact);
        });

        return result;
    };

    const chatContacts = getChatContacts();
    const chatContactsLength = searchType === 'newChat' ? chatContacts[2].length : chatContacts[0].length + chatContacts[1].length;

    const renderPersonMessagesMemo = useMemo(() => {
        const renderPersonMessages = (messages: any) => {
            const result: any = [];
            let currentFrom: any = '';
            let currentMessages: any = [];

            messages?.map((message: any, i: any) => {
                if ((message.from !== currentFrom) || message.separator) {
                    if (currentMessages.length > 0) {
                        result.push(
                            <ChatPersonMessages
                                key={i}
                                from={currentFrom}
                                avatar={{ ...selectedPerson?.avatar, status: undefined }}
                                status={messages[i - 1]?.status}
                            >
                                {currentMessages}
                            </ChatPersonMessages>
                        );
                    }

                    currentFrom = message.from || '';
                    currentMessages = [];
                }

                if (message.separator) {
                    result.push(
                        <ChatDateSeparator key={i + '.1'} dateTime={message.separator} />
                    );
                } else {
                    currentMessages.push(
                        <ChatMessage
                            key={i + '.1'}
                            text={message.text}
                            timestamp={message?.status?.dateTime}
                            {...(message.onClick && { onClick: () => message.onClick() })}
                        />
                    );
                }
            });

            if (currentMessages.length > 0) {
                result.push(
                    <ChatPersonMessages
                        key={messages.length}
                        from={currentFrom}
                        avatar={{ ...selectedPerson?.avatar, status: undefined }}
                        status={messages[messages.length - 1]?.status}
                    >
                        {currentMessages}
                    </ChatPersonMessages>
                )
            } else {
                result.push(
                    <ReactAux key={0 + '.1'}>
                        <ChatDateSeparator dateTime={'Today'} />
                        <p className="lv-small text-muted text-center">Send a message to start a convesation.</p>
                    </ReactAux>
                )
            }

            if (!oldMessagesIsLoading) setTimeout(() => scrollChatPersonToBottom());
            return <>{result}</>;
        };

        return renderPersonMessages(selectedPerson?.messages);
    }, [selectedPerson]);

    useEffect(() => {
        if (searchInputRef.current) searchInputRef.current.focus();
    }, [searchType]);

    return (
        <ChatContainer
            isExpanded={chatContainerIsExpanded}
            newNotification
            onExpandBtnClick={() => setChatContainerIsExpanded(!chatContainerIsExpanded)}
        >
            <ChatContactsContainer
                title="Contacts"
                btnElements={<>
                    <Button btnType="icon" icon="fal fa-filter" className="ml-s" data-tip="Filter" onClick={() => setSearchType('filter')} />
                    <Button btnType="icon" icon="fal fa-pen" className="ml-s" data-tip="Search" onClick={() => setSearchType('newChat')} />
                </>}
                titleRender={searchType && <>
                    <Button btnType="icon" className="mr-s" icon="fal fa-arrow-left" data-tip="Back" onClick={() => { setSearchName(''); setSearchType(undefined) }} />
                    {searchType === 'newChat' && <p className="mr-s">To:</p>}
                    <Input
                        ref={searchInputRef}
                        className="flex-grow-1"
                        placeholder={searchType === 'filter' ? 'Filter By Name' : 'Enter name'}
                        value={searchName}
                        onChange={(e: any) => setSearchName(e.target.value)}
                        onKeyDown={(e: any) => { if (e.keyCode === 27) { setSearchName(''); setSearchType(undefined) } }}
                    />
                </>}
            >
                {searchName && <>
                    <h2 className="text-muted text-center mb-m">
                        {chatContactsLength > 0 ? chatContactsLength : 'No'} result{chatContactsLength !== 1 && 's'} found for “{searchName}”
                    </h2>
                    <hr className="mb-s" />
                </>}
                {searchType !== 'newChat' && <>
                    {chatContacts[0].length > 0 && <ChatContactsSection className="mb-l" title="Pinned" expanded={isPinnedSectionExpanded} onClick={() => setIsPinnedSectionExpanded(!isPinnedSectionExpanded)}>
                        {chatContacts[0]}
                    </ChatContactsSection>}
                    {chatContacts[1].length > 0 && <ChatContactsSection title="Recent" expanded={isRecentSectionExpanded} onClick={() => setIsRecentSectionExpanded(!isRecentSectionExpanded)}>
                        {chatContacts[1]}
                    </ChatContactsSection>}
                </>}
                {(searchType === 'newChat' && !searchName) && <>
                    <h2 className="text-muted text-center mb-m">
                        Start typing to find users.
                    </h2>
                    <hr className="mb-s" />
                </>}
                {(searchType === 'newChat' && searchName) && chatContacts[2]}
            </ChatContactsContainer>

            <ChatPersonContainer
                ref={chatPersonContainerRef}
                isExpanded={chatPersonIsExpanded}
                avatar={selectedPerson?.avatar}
                name={selectedPerson?.name}
                onCloseClick={() => {
                    closeSelectedContact();
                    setChatPersonIsExpanded(false);
                }}
                bottomSection={
                    <form
                        className="d-flex align-items-center flex-grow-1"
                        onSubmit={(e) => {
                            e.preventDefault();

                            if (chatInput) {
                                selectedPerson?.messages?.push(
                                    { from: 'me', status: { dateTime: '01:00 PM', type: 'notDelivered' }, text: chatInput },
                                );
                                setChatInput('');
                                setSelectedPerson({ ...selectedPerson });

                                setTimeout(() => {
                                    const lastMessage = selectedPerson?.messages[selectedPerson?.messages.length - 1];
                                    lastMessage.status.type = 'delivered';
                                    setSelectedPerson({ ...selectedPerson });

                                    setTimeout(() => {
                                        const lastMessage = selectedPerson?.messages[selectedPerson?.messages.length - 1];
                                        lastMessage.status.type = 'seen';
                                        setSelectedPerson({ ...selectedPerson });
                                    }, 5000);
                                }, 2000);
                            }
                        }}>
                        <Button
                            className="mr-s"
                            btnType="icon"
                            icon="fas fa-share-square"
                            data-tip="Send current page as link"
                            onClick={() => {
                                selectedPerson?.messages?.push(
                                    { from: 'me', status: { dateTime: '01:00 PM', type: 'notDelivered' }, text: 'Current page link', onClick: () => alert('Link click') },
                                );
                                setSelectedPerson({ ...selectedPerson });
                            }} />
                        <Input
                            ref={messageInputRef}
                            className="flex-grow-1"
                            placeholder="Aa"
                            value={chatInput}
                            onChange={(e: any) => setChatInput(e.target.value)}
                        />
                        <Button btnType="icon" className="ml-s" icon="fas fa-paper-plane" type="submit" data-tip="Submit" />
                    </form>
                }>
                {chatIsLoading && <div className="d-flex justify-content-center align-items-center height-full">
                    <div className="lv-loader-container">
                        <div className="lv-loader-big">
                            <div /><div /><div /><div /><div /><div /><div /><div />
                        </div>
                    </div>
                </div>}
                {oldMessagesIsLoading && <div className="d-flex justify-content-center mt-s mb-m">
                    <div className="lv-loader">
                        <div /><div /><div />
                    </div>
                </div>}
                {!chatIsLoading && renderPersonMessagesMemo}
            </ChatPersonContainer>
        </ChatContainer>
    );
}

export default DemoChatComponent;