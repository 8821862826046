import React, { useState } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, Radio, MainContainerScrollable, DrillDownContainer, DrillDown, Card, CardSection,
    Row, Col, Label, Input, Select, Textarea, Checkbox, Collapse, Popover, Modal
} from '@jkhy/vsg-loanvantage-design-system';


const cardSection = <CardSection>
    <Row>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label className="mr-s">Covenant Id</Label>
                <Label className="mr-s"><strong>347318</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Measurement Id</Label>
                <Label><strong>82184</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Covenant</Label>
                <Label><strong>Debt Service Ration</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Description</Label>
                <Label><strong>Description</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Entities</Label>
                <Label><strong>Randall Enterprises - CR 123654789</strong></Label>
            </div>
        </Col>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label className="mr-s">Loan</Label>
                <Label><strong>Type</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Stmt date</Label>
                <Label><strong>1/31/2021</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Frequency</Label>
                <Label><strong>Annually</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Current Due date</Label>
                <Label><strong>1/31/2021</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">New Due date</Label>
            </div>
        </Col>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label className="mr-s">Measurement Status</Label>
                <Label><strong>Extended</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Ext Cnt</Label>
                <Label><strong>1</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label className="mr-s">Wvr Cnt</Label>
                <Label><strong>0</strong></Label>
            </div>
        </Col>
    </Row>
</CardSection>;

// This is a sample component that showcases how a long text content
// can be trimmed and how a Popover can be added to display the full text.
// IMPORTANT: This approach is just for demo purpose.
const LargeTextPopoverWrapper = (props:{text:string}) => {
    const exampleMaxTextLength = 40;
    const [popoverActive, togglePopover] = useState(false);
    const { text } = props;

    if(text.length<exampleMaxTextLength){
        return <p>{text}</p>;
    }
    return (
        <>
            <p>
                {text.substring(0, exampleMaxTextLength)+'...'}
                <br/>
                <Popover
                    title="Description"
                    show={popoverActive}
                    render={() => (
                        <p>{text}</p>
                    )}
                    onClose={() => togglePopover(false)}
                >
                    <span className="d-inline-block mt-xs lv-link-styles" onClick={() => togglePopover(true)}>
                        See more
                    </span>
                </Popover>
            </p>
        </>
    );
};

// This is a sample component that showcases how a long list of text items
// can be shown with reducing to a fixed limit and how a Popover can be added to display the full text.
// IMPORTANT: This approach is just for demo purpose.
const LargeListPopoverWrapper = (props:{items:string[]}) => {
    const maxItemsCount = 3;
    const [popoverActive, togglePopover] = useState(false);
    const { items } = props;

    const visibleItems =items.slice(0, maxItemsCount); 
    return (
        <>
            {visibleItems.map((itemText, i) => {
                return <p key={i}>{itemText}{i < visibleItems.length-1 ? ',' : ''}</p>
            })}
            {items.length>maxItemsCount && (
                <Popover
                    title="Entities"
                    show={popoverActive}
                    render={() => (
                        <ul>
                        {items.map((itemText,i) => {
                            return <li key={i}>{itemText}</li>
                        })}
                    </ul>
                    )}
                    onClose={() => togglePopover(false)}
                >
                    <span className="d-inline-block mt-xs lv-link-styles" onClick={() => togglePopover(true)}>
                        View all
                    </span>
                </Popover>
            )}
        </>
    );
};

// The purpose of this component is to demontstrate the usage
// of .text-truncated and .text-no-break utility classes, and also
// how to display the remaining text in a Popover.
// IMPORTANT: This approach is just for demo purpose.
const RequireDocsModal = () => {
    const [docsModalActive, toggleDocsModal] = useState(false);
    const [entitiesPopoverActive, toggleEntitiesPopover] = useState(false);
    return (
        <div>
            <Button btnType="secondary" icon='fal fa-file' onClick={()=>toggleDocsModal(true)}>Documents Details</Button>
            <Modal title="Required Documents Details" isVisible={docsModalActive} size="M" onClose={() => toggleDocsModal(false)}>
            <Row className="mt-m">
                        <Col xs={3} className="text-right">
                            <Label className="mb-m justify-content-end">Required Document Type</Label>
                            <Label className="mb-m justify-content-end">Entities</Label>
                            <Label className="mb-m justify-content-end">Category</Label>
                            <Label className="mb-m justify-content-end">Document</Label>
                            <Label className="mb-m justify-content-end">Year</Label>
                            <Label className="mb-m justify-content-end">Make</Label>
                            <Label className="mb-m justify-content-end">Model</Label>
                            <Label className="mb-m justify-content-end">Body</Label>
                        </Col>
                        <Col xs={6}>
                            <Label className="mb-m"><strong>Appraisal</strong></Label>
                            <Label className="mb-m">
                                <strong className="text-truncated">Randall Enterprises, Kevin Testcase,
                                David Testcase,
                                Yana Testcase,
                                Stan Testcase,
                                Dimo Testcase,
                                Joro Testcase</strong>
                                <Popover
                                    title="Entities"
                                    show={entitiesPopoverActive}
                                    render={() => (
                                    <ul>
                                        <li>Randall Enterprises</li>
                                        <li>Kevin Testcase</li>
                                        <li>David Testcase</li>
                                        <li>Yana Testcase</li>
                                        <li>Stan Testcase</li>
                                        <li>Dimo Testcase</li>
                                        <li>Joro Testcase</li>
                                    </ul>
                                    )}
                                    onClose={() => toggleEntitiesPopover(false)}
                                >
                                <span className="d-inline-block ml-xs lv-link-styles text-no-break" onClick={()=>toggleEntitiesPopover(true)}>View all</span>
                                </Popover>
                            </Label>
                            <Label className="mb-m"><strong>1.reccuring document</strong></Label>
                            <div className="mb-m d-flex">
                                <Radio name="radio-rdt">Party</Radio>
                                <Radio name="radio-rdt">Account</Radio>
                                <Radio name="radio-rdt">Collateral</Radio>
                            </div>
                            <Input className="mb-m"/>
                            <Input className="mb-m"/>
                            <Input className="mb-m"/>
                            <Input className="mb-m"/>
                        </Col>
                    </Row>
            </Modal>
        </div>
    )
};

const drillDowns = [
    {
        cols: ['ChopevTEsttt', '', 
            <LargeListPopoverWrapper items={[
                'Randall Enterprises',
                'Kevin Testcase',
                'David Testcase',
                'Yana Testcase',
                'Stan Testcase',
                'Dimo Testcase',
                'Joro Testcase',
                'Randall Enterprises',]} />, 
        'Party', '11/5/2020', 'Continuous', '11/5/2020', '', 'Pending', '0', '0'],
        content:
            <>
                <Card
                    className="mb-s"
                    fullWidth
                >
                    {cardSection}
                </Card>
                <Card
                    fullWidth
                >
                    {cardSection}
                </Card>
                <div className="mt-m">
                    <RequireDocsModal/>
                </div>
            </>,
    },
    {
        cols: ['ChopevTEsttt',  
        <LargeTextPopoverWrapper
        text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur.' />,
        'Randall Enterprises', 'Party', '11/5/2020', 'Continuous', '11/5/2020', '', 'Pending', '0', '0'],
        content:
            <Card
                className="mb-s"
                fullWidth
            >
                {cardSection}
            </Card>
    },
    {
        cols: ['ChopevTEsttt', '', 'Carlos John Testcase, Elizabeth K Adams, Sprinkles Holdings LLC', 'Party', '11/5/2020', 'Continuous', '11/5/2020', '', 'Pending', '0', '0'],
        content:
            <Card
                className="mb-s"
                fullWidth
            >
                {cardSection}
            </Card>
    },
];

class DemoServiceRequest extends React.Component {

    state = {
        collapsesState: [true, false],
        drillDownsState: [
            { isActive: false, expanded: false },
            { isActive: true, expanded: true },
            { isActive: false, expanded: false },
            { isActive: false, expanded: false },
        ]
    }

    onCollapseClick(index: number) {
        const { collapsesState } = this.state;

        collapsesState[index] = !collapsesState[index];

        this.setState({ collapsesState });
    }

    onChecked(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].isActive = !drillDownsState[index].isActive;

        this.setState({ drillDownsState });
    }

    onExpand(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].expanded = !drillDownsState[index].expanded;

        this.setState({ drillDownsState });
    }

    render() {

        const { collapsesState, drillDownsState } = this.state;

        return (
            <ReactAux>
                <MainContainerHeader>
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={['Service Request -Due Date Extension (Required Document)']} />
                        </ActionContainerHeader>
                        <Button icon="fal fa-save">Save</Button>
                        <Button icon="fal fa-times-circle" btnType="secondary">Cancel</Button>
                        <Button icon="fal fa-trash" btnType="secondary">Delete</Button>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>

                    <Row className="mt-m">
                        <Col xs={3} className="text-right">
                            <Label className="mb-m justify-content-end">Requested by</Label>
                            <Label className="mb-m justify-content-end">Requested date</Label>
                            <Label className="mb-m justify-content-end">Status</Label>
                            <Label className="mb-m justify-content-end">Extension period</Label>
                            <Label className="mb-m justify-content-end">Reasons for request</Label>
                            <Label className="mb-m justify-content-end">Notes</Label>
                        </Col>
                        <Col xs={6}>
                            <Label className="mb-m"><strong>Randall</strong></Label>
                            <Label className="mb-m"><strong>10/30/2020</strong></Label>
                            <Label className="mb-m"><strong>In progress</strong></Label>
                            <div className="mb-m d-flex">
                                <Radio name="radio-1">Days to extend</Radio>
                                <Input className="mr-m width-s" disabled value={30} />
                                <Radio name="radio-1" defaultChecked>New due date</Radio>
                            </div>
                            <Select
                                className="mb-m width-m"
                                value="1"
                                options={[
                                    { value: '1', label: 'Owner extended absence' },
                                    { value: '4', label: 'Option 4' }
                                ]}
                            />
                            <Textarea />
                        </Col>
                    </Row>

                    <Toolbar
                        className="mt-l mb-m"
                        leftSide={
                            <>
                                <Label className="mr-s"><strong>Show:</strong></Label>
                                <Label className="mr-xs">Non-Exception Only:</Label>
                                <Checkbox className="mr-m" />
                                <Label className="mr-xs">Coming due in:</Label>
                                <Select
                                    className="mr-m width-s"
                                    value="1"
                                    options={[
                                        { value: '1', label: 'Anytime' },
                                        { value: '4', label: 'Option 4' }
                                    ]}
                                />
                                <Label className="mr-xs">Include Loan Requests:</Label>
                                <Checkbox />
                            </>
                        }
                    />

                    <Collapse
                        title="Required Documents with Linked Covenant Measurements"
                        expanded={collapsesState[0]}
                        onClick={() => this.onCollapseClick(0)}
                    >
                        <DrillDownContainer
                            cols={[
                                { title: 'Covenant', percentage: 13, textAlign: 'left' },
                                { title: 'Description', percentage: 12, textAlign: 'left' },
                                { title: 'Entities', percentage: 11, textAlign: 'left' },
                                { title: 'Type', percentage: 7, textAlign: 'left' },
                                { title: 'Stmt date', percentage: 11, textAlign: 'right' },
                                { title: 'Frequency', percentage: 10, textAlign: 'left' },
                                { title: 'Current Due Date', percentage: 11, textAlign: 'right' },
                                { title: 'New Due Date', percentage: 11, textAlign: 'right' },
                                { title: 'Measurement Status', percentage: 8, textAlign: 'left' },
                                { title: 'Ext Cnt', percentage: 3, textAlign: 'right' },
                                { title: 'Wvr Cnt', percentage: 3, textAlign: 'right' }
                            ]}
                        >
                            {drillDowns.map((d, i) =>
                                <DrillDown
                                    key={i}
                                    cols={d.cols}
                                    isActive={drillDownsState[i].isActive}
                                    expanded={drillDownsState[i].expanded}
                                    controlRender={() => <Checkbox checked={drillDownsState[i].isActive} onChange={() => this.onChecked(i)} />}
                                    onArrowClick={() => this.onExpand(i)}
                                    dataUI={i + ''}
                                >
                                    {d.content}
                                </DrillDown>
                            )}
                        </DrillDownContainer>
                    </Collapse>

                    <Collapse
                        title="Required Documents with Linked Covenant Measurements"
                        expanded={collapsesState[1]}
                        onClick={() => this.onCollapseClick(1)}
                    >
                        <p>Content...</p>
                    </Collapse>
                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoServiceRequest;