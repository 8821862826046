import React from 'react';
import Highlight from 'react-highlight';

import { Dropzone, SimpleTableContainer, Button, Modal, ModalScrollableContainer } from '@jkhy/vsg-loanvantage-design-system';
import DemoLocationPage from '../../components/DemoLocationPage';


class DropzonePage extends React.Component {

    state = {
        modal1: false,
    }

    render() {
        return (
            <div>
                <h1 className="mb-s">Dropzone</h1>

                <p className="mb-l">Custom component that makes a drag and drop zone.</p>

                <Button className="mb-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal1: true })}>Dropzone in modal</Button>
                <Modal size="L" title="Modal" isExpandable isVisible={this.state.modal1} onClose={() => this.setState({ modal1: false })}>
                    <h1 className="mb-m">Static Title</h1>
                    <ModalScrollableContainer>
                        <Dropzone className="mb-m" text="Drop file" onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                            <DemoLocationPage />
                        </Dropzone>
                    </ModalScrollableContainer>
                </Modal>

                <Dropzone className="mb-m" text="Drop file" onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                    <div>
                        <h2 className="mb-m">Drag file over this text</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </Dropzone>

                <SimpleTableContainer className="mb-xl">
                    <table>
                        <thead>
                            <tr>
                                <th>Heading</th>
                                <th>Heading</th>
                                <th>Heading</th>
                            </tr>

                        </thead>
                        <tbody>
                            <Dropzone isElement onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                                <tr className="out-class" data-uid="e99ce0cc-3f7a-4980-bb4d-c545ee4f1711" role="row">
                                    <td>Row Cell</td>
                                    <td>Row Cell</td>
                                    <td>Row Cell</td>
                                </tr>
                            </Dropzone>
                            <Dropzone isElement onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                                <tr>
                                    <td>Row Cell</td>
                                    <td>Row Cell</td>
                                    <td>Row Cell</td>
                                </tr>
                            </Dropzone>
                            <Dropzone isElement onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                                <tr>
                                    <td>Row Cell</td>
                                    <td>Row Cell</td>
                                    <td>Row Cell</td>
                                </tr>
                            </Dropzone>
                        </tbody>
                    </table>
                </SimpleTableContainer>


                <Highlight className="React">
                    {
                        `
import React from 'react';
import { Dropzone, SimpleTableContainer, Button, Modal, ModalScrollableContainer } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    state = {
        modal1: false,
    }

    render() {
        return (
            <Button className="mb-m" icon="fal fa-pastafarianism" onClick={() => this.setState({ modal1: true })}>Dropzone in modal</Button>
            <Modal size="L" title="Modal" isExpandable isVisible={this.state.modal1} onClose={() => this.setState({ modal1: false })}>
                <h1 className="mb-m">Static Title</h1>
                <ModalScrollableContainer>
                    <Dropzone className="mb-m" text="Drop file" onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                        <DemoLocationPage />
                    </Dropzone>
                </ModalScrollableContainer>
            </Modal>

            <Dropzone className="mb-m" text="Drop file" onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                <div>
                    <h2 className="mb-m">Drag file over this text</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>
            </Dropzone>

            <SimpleTableContainer className="mb-xl">
                <table>
                    <thead>
                        <tr>
                            <th>Heading</th>
                            <th>Heading</th>
                            <th>Heading</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Dropzone isElement onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                            <tr className="out-class" data-uid="e99ce0cc-3f7a-4980-bb4d-c545ee4f1711" role="row">
                                <td>Row Cell</td>
                                <td>Row Cell</td>
                                <td>Row Cell</td>
                            </tr>
                        </Dropzone>
                        <Dropzone isElement onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                            <tr>
                                ...
                            </tr>
                        </Dropzone>
                        <Dropzone isElement onChange={(files) => { console.log(files, ' ~~~ onChange') }}>
                            <tr>
                                ...
                            </tr>
                        </Dropzone>
                    </tbody>
                </table>
            </SimpleTableContainer>
        );
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isElement</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>When set to true, the wrapped element becomes a Dropzone container. This use-case happens when table rows <i>({'<tr>'})</i> should act like Dropzones.</td>
                        </tr>
                        <tr>
                            <td>text</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the text to show when the user drops a file.</td>
                        </tr>
                        <tr>
                            <td>acceptedFiles</td>
                            <td>string</td>
                            <td>"image/*,.xlsx,.xls,.pdf,.doc,.docx"</td>
                            <td>Specify what file types the user can upload. Types need to be separate with <strong>comma</strong>.</td>
                        </tr>
                        <tr>
                            <td>isMultiple</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Specifies that the user is allowed to enter more than one file.</td>
                        </tr>
                        <tr>
                            <td>maxFileSizeMB</td>
                            <td>number</td>
                            <td>50</td>
                            <td>Set maximum file size to upload in MB.</td>
                        </tr>
                        <tr>
                            <td>onChange</td>
                            <td>{'(files: { file: File, error: string }[]) => void'}</td>
                            <td className="text-center">-</td>
                            <td>
                                Callback, executed when the user uploads a file. Returns array with objects. Object contains 'file' and 'error'.
                                <br />
                                <br />
                                Errors: 'invalid name', 'exceeded maximum size', 'invalid type' or null
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default DropzonePage;
