import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { ButtonGroup, Button } from '@jkhy/vsg-loanvantage-design-system';

const ButtonGroupPage: React.FC = () => {

    const [activeBtn, setActiveBtn] = useState(0);

    return (
        <div>
            <h1 className="mb-s">ButtonGroup</h1>
            <p className="mb-l">The ButtonGroup component can be used to group related buttons.</p>

            <ButtonGroup>
                <Button btnType={activeBtn === 1 ? 'primary' : 'secondary'} onClick={() => setActiveBtn(1)}>File</Button>
                <Button btnType={activeBtn === 2 ? 'primary' : 'secondary'} onClick={() => setActiveBtn(2)}>Facility</Button>
                <Button disabled btnType={activeBtn === 3 ? 'primary' : 'secondary'} onClick={() => setActiveBtn(3)}>Presentation Report</Button>
            </ButtonGroup>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { ButtonGroup, Button } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {

    const [activeBtn, setActiveBtn] = useState(0);

    return (
      <div>
        <ButtonGroup>
            <Button btnType={activeBtn === 1 ? 'primary' : 'secondary'} onClick={() => setActiveBtn(1)}>File</Button>
            <Button btnType={activeBtn === 2 ? 'primary' : 'secondary'} onClick={() => setActiveBtn(2)}>Facility</Button>
            <Button disabled btnType={activeBtn === 3 ? 'primary' : 'secondary'} onClick={() => setActiveBtn(3)}>Presentation Report</Button>
        </ButtonGroup>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

        </div>
    )
}

export default ButtonGroupPage;
