import React from 'react';
import Highlight from 'react-highlight';

import { LoanOptionCard, LoanOptionCardContainer } from '@jkhy/vsg-loanvantage-design-system';

const loanOptionCards = [false, true, false];

class LoanOptionCardPage extends React.Component {

    state = {
        loanOptionCards: loanOptionCards,
    }

    onChangeLoanOption(index: number) {
        const { loanOptionCards } = this.state;

        loanOptionCards.map((c, i) => {
            if (i === index) return loanOptionCards[i] = true;
            return loanOptionCards[i] = false;
        });

        this.setState({ loanOptionCards });
    }

    render() {
        return (
            <div>
                <h1 className="mb-l">LoanOptionCard</h1>

                <h2 className="mb-m">LoanOptionCard module components:</h2>
                <p className="mb-s"><strong><a href="#card">LoanOptionCardContainer</a></strong> - The main wrapper component</p>
                <p className="mb-l"><strong><a href="#card-section">LoanOptionCard</a></strong> - This component displays data for its predefined titles. Works like collapse.</p>

                <LoanOptionCardContainer
                    cols={[
                        { title: 'Description', percentage: 13 },
                        { title: 'Loan Amount', percentage: 9 },
                        { title: 'Base Payment', percentage: 9 },
                        { title: 'Total Payment', percentage: 9 },
                        { title: 'Term', percentage: 12 },
                        { title: 'Payment Type', percentage: 13 },
                        { title: 'Interest Rate', percentage: 13 },
                        { title: 'APR', percentage: 6 },
                        { title: 'Fees', percentage: 16 },
                    ]}
                >
                    <LoanOptionCard
                        onClick={() => this.onChangeLoanOption(0)}
                        expanded={this.state.loanOptionCards[0]}
                        checked={this.state.loanOptionCards[0]}
                        cols={[
                            '3 years',
                            'US$960,000.00',
                            'US$16,644.00',
                            '500',
                            '12 months',
                            'Interest Only',
                            '0% (Fixed)',
                            '0.00%',
                            '0',
                        ]}
                    >
                        <div style={{ height: '300px', backgroundColor: 'var(--color-select)' }}>Content...</div>
                    </LoanOptionCard>
                    <LoanOptionCard
                        onClick={() => this.onChangeLoanOption(1)}
                        checked={this.state.loanOptionCards[1]}
                        cols={[
                            '3 years',
                            'US$960,000.00',
                            'US$16,644.00',
                            '-',
                            '36 months',
                            'P & I Due at Maturity',
                            '0% (Fixed)',
                            '0.00%',
                            '0',
                        ]}
                    >
                        <div>Content...</div>
                    </LoanOptionCard>
                    <LoanOptionCard
                        onClick={() => this.onChangeLoanOption(2)}
                        expanded={this.state.loanOptionCards[2]}
                        checked={this.state.loanOptionCards[2]}
                        disabled
                        cols={[
                            '3 years',
                            'US$960,000.00',
                            'US$16,644.00',
                            '-',
                            '36 months',
                            'P & I Due at Maturity',
                            '0% (Fixed)',
                            '0.00%',
                            '0',
                        ]}
                    >
                        <div>Content...</div>
                    </LoanOptionCard>
                </LoanOptionCardContainer>


                <Highlight className="React">
                    {
                        `
import React from 'react';
import { LoanOptionCard } from '@jkhy/vsg-loanvantage-design-system';

const loanOptionCards = [false, true, false];

class Example extends React.Component {

    state = {
        loanOptionCards: loanOptionCards,
    }

    onChangeLoanOption(index: number) {
        const { loanOptionCards } = this.state;

        loanOptionCards.map((c, i) => {
            if (i === index) return loanOptionCards[i] = true;
            return loanOptionCards[i] = false;
        });

        this.setState({ loanOptionCards });
    }

    render() {
        return (
            <div>
            <LoanOptionCardContainer
                cols={[
                    { title: 'Description', percentage: 13 },
                    { title: 'Loan Amount', percentage: 9 },
                    { title: 'Base Payment', percentage: 9 },
                    { title: 'Total Payment', percentage: 9 },
                    { title: 'Term', percentage: 12 },
                    { title: 'Payment Type', percentage: 13 },
                    { title: 'Interest Rate', percentage: 13 },
                    { title: 'APR', percentage: 6 },
                    { title: 'Fees', percentage: 16 },
                ]}
            >
                <LoanOptionCard
                    onClick={() => this.onChangeLoanOption(0)}
                    expanded={this.state.loanOptionCards[0]}
                    checked={this.state.loanOptionCards[0]}
                    cols={[
                        '3 years',
                        'US$960,000.00',
                        'US$16,644.00',
                        '500',
                        '12 months',
                        'Interest Only',
                        '0% (Fixed)',
                        '0.00%',
                        '0',
                    ]}
                >
                    <div style={{ height: '300px', backgroundColor: 'var(--color-select)' }}>Content...</div>
                </LoanOptionCard>
                <LoanOptionCard
                    onClick={() => this.onChangeLoanOption(1)}
                    checked={this.state.loanOptionCards[1]}
                    cols={[
                        ...
                    ]}
                >
                    <div>Content...</div>
                </LoanOptionCard>
                <LoanOptionCard
                    onClick={() => this.onChangeLoanOption(2)}
                    expanded={this.state.loanOptionCards[2]}
                    checked={this.state.loanOptionCards[2]}
                    disabled
                    cols={[
                        ...
                    ]}
                >
                    <div>Content...</div>
                </LoanOptionCard>
            </LoanOptionCardContainer>
            </div>
        );
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <p className="mb-xl">LoanOptionCard component in LoanOptionCard module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

                <h3 id="card">LoanOptionCardContainer</h3>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>cols</td>
                            <td>
                                array of objects with props: <br /><br />
                                "title": string, "percentage": number, "textAlign": 'right' | 'left'
                            </td>
                            <td className="text-center">-</td>
                            <td>Set the cols with title, width percentage and textAlign.</td>
                        </tr>
                    </tbody>
                </table>

                <h3 id="card-section">LoanOptionCard</h3>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>onClick</td>
                            <td>() {'=>'} void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the component is clicked.</td>
                        </tr>
                        <tr>
                            <td>expanded</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Shows the expanded content.</td>
                        </tr>
                        <tr>
                            <td>checked</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Shows the active style.</td>
                        </tr>
                        <tr>
                            <td>disabled</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Disables the component.</td>
                        </tr>
                        <tr>
                            <td>cols</td>
                            <td>array of strings</td>
                            <td className="text-center">-</td>
                            <td>Set the cols text. Must be the same length as <strong>LoanOptionCardContainer</strong> cols.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default LoanOptionCardPage;
