import React from 'react';
import { Link } from 'react-router-dom';

import { MainContainerScrollable } from '@jkhy/vsg-loanvantage-design-system';

class DemoPagesLinks extends React.Component {

    render() {

        return (
            <MainContainerScrollable>
                <h1 className="mb-s">LeftSideBar </h1>
                <h3 className="mb-m"><Link className="lv-link-styles" to="?">Switch to LeftSideBar with Party Navigation and Activity Checklist</Link></h3>
                <h3 className="mb-m"><Link className="lv-link-styles" to="?wizard-nav">Switch to LeftSideBar with WizardNav</Link></h3>
                <h3 className="mb-m"><Link className="lv-link-styles" to="?only-top">LeftSideBar with TopSection only</Link></h3>
                <h3 className="mb-xl"><Link className="lv-link-styles" to="/demo-bar">LeftSideBar with custom width</Link></h3>

                <h1 className="mb-s">Demo Pages</h1>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/credit-check">Credit Check</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/loan-request">Loan Request</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/flood-determination">Flood Determination</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/service-request">Service Request</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/file-collateral">File Collateral</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/engagements">Engagements</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/show-image">Show Image</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/work-page-1">Work Page 1</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/work-page-2">Work Page 2</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/work-page-3">Work Page 3</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/work-page-4">Work Page 4</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/sba">SBA</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/covenant">Covenant</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/policy-exception">Policy Exception</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/risk-rating">Risk Rating</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/application-ciba">Application CIBA</Link></h3>
                <h3 className="mb-xl"><Link className="lv-link-styles" to="/components/tickler-record">Tickler Record Managment</Link></h3>


                <h1 className="mb-s">Demo Scroll Pages</h1>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/scroll1">Simple page with scroll</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/scroll2">Page with tabs with scroll</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/scroll3">Page without Static elements</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/scroll4">Page with tabs with scroll and dynamic content</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/scroll5">Page with static top and bottom parts</Link></h3>
                <h3 className="mb-s"><Link className="lv-link-styles" to="/demo/scroll6">Page with KendoTable and Modal with KendoTable</Link></h3>
                <h3 className="mb-xl"><Link className="lv-link-styles" to="/demo/scroll7">Page with tabs with KendoTable and Modal with tab with KendoTable</Link></h3>

                <h1 className="mb-s">Demo Modal</h1>
                <h3 className="mb-xl"><Link className="lv-link-styles" to="/demo/modal">Modal</Link></h3>
            </MainContainerScrollable>
        );
    }
}

export default DemoPagesLinks;
