import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, Radio, MainContainerScrollable, DrillDownContainer, DrillDown, Card, CardSection,
    SimpleTableContainer, Icon, Row, Col, Label,
} from '@jkhy/vsg-loanvantage-design-system';


const drillDowns = [
    {
        cols: ['5 unit month to month tenant list', 'Carlos Testcase', '39604', '48 Groton Str'],
        content: <div style={{ height: '300px', backgroundColor: 'var(--color-select)' }}>Content...</div>,
    },
    {
        cols: ['Apartment complex with MFA template', 'Carlos Testcase', '39604', '808 Atlantic Ave'],
        content:
            <Card
                fullWidth
                actionButton={
                    <>
                        <Button className="mr-s" icon="fal fa-plus" btnType="secondary">Create flood request</Button>
                        <Button className="mr-s" icon="fal fa-print" btnType="secondary">Print</Button>
                        <Button className="mr-s" icon="fal fa-history" btnType="secondary">History</Button>
                        <Button icon="fal fa-trash" btnType="secondary">Delete</Button>
                    </>
                }
            >
                <CardSection title="Certificate Number 2007088414">
                    <SimpleTableContainer className="mb-l">
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-right">Date</th>
                                    <th>Flood Zone</th>
                                    <th>Specific Address/Building</th>
                                    <th className="text-right">Parcel ID</th>
                                    <th>Staus</th>
                                    <th>Split</th>
                                    <th className="text-center">Reviewed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-right">07/29/20</td>
                                    <td>X500</td>
                                    <td>48 Groton Str</td>
                                    <td className="text-right"></td>
                                    <td>Complete</td>
                                    <td></td>
                                    <td className="text-center"><Icon className="text-success" icon="far fa-check" size="S" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                    <Row>
                        <Col xs={4}>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">Collateral Tracking  ID</Label>
                                <Label className="mr-s"><strong>347318</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">Certificate Number</Label>
                                <Label><strong>2007088239</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">Flood Deter. Date</Label>
                                <Label><strong>7/29/2020</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">Flood Zone</Label>
                                <Label><strong>X500*</strong></Label>
                            </div>
                            <Label className="mb-m">SFHA</Label>
                            <Label className="mb-m">Comments</Label>
                            <Label className="mb-m">Search Results</Label>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">NFIP Comm. Name</Label>
                                <Label><strong>CAMDEN, CITY OF</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">NFIP County</Label>
                                <Label><strong>CAMDEN</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">NFIP State</Label>
                                <Label><strong>NJ</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">NFIP Comm. #</Label>
                                <Label><strong>347318</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">No NFIP Map</Label>
                                <Label><Icon icon="far fa-check" size="S" /></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">NFIP Map Number</Label>
                                <Label><strong>34007C 0036F</strong></Label>
                            </div>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">NFIP Map Panel Date</Label>
                                <Label><strong>8/17/2016</strong></Label>
                            </div>
                            <Label className="mb-m">LOMA/LOMR Date</Label>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">Fed. Flood Ins. Is Ava.</Label>
                                <Label><Icon icon="far fa-check" size="S" /></Label>
                            </div>
                            <Label className="mb-m">Fed. Flood Ins. Is Not Ava.</Label>
                            <Label className="mb-m">CBRA Or OPA</Label>
                            <Label className="mb-m">CBRA/OPA Date</Label>
                            <div className="d-flex mb-m">
                                <Label className="mr-s">Regular Program</Label>
                                <Label><Icon icon="far fa-check" size="S" /></Label>
                            </div>
                            <Label className="mb-m">Emergency Program</Label>
                        </Col>
                    </Row>
                </CardSection>
            </Card>,
    },
    {
        cols: ['Apartment complex with MFA template', 'Carlos Testcase', '39604', '808 Atlantic Ave'],
        content: <div>Content...</div>,
    },
];

class DemoFloodDetermination extends React.Component {

    state = {
        drillDownsState: [
            { isAcitve: false, expanded: false },
            { isAcitve: true, expanded: true },
            { isAcitve: false, expanded: false },
            { isAcitve: false, expanded: false },
        ],
    }

    onExpand(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].isAcitve = !drillDownsState[index].isAcitve;
        drillDownsState[index].expanded = !drillDownsState[index].expanded;

        this.setState({ drillDownsState });
    }

    toggleExpandAll() {
        const { drillDownsState } = this.state;

        if (this.isAllExpanded()) {
            drillDownsState.forEach((c, i) => {
                drillDownsState[i].isAcitve = false;
                drillDownsState[i].expanded = false;
            });
        } else {
            drillDownsState.forEach((c, i) => {
                drillDownsState[i].isAcitve = true;
                drillDownsState[i].expanded = true;
            });
        }

        this.setState({ drillDownsState });
    }

    isAllExpanded() {
        const { drillDownsState } = this.state;

        for (let i = 0; i < drillDownsState.length; i++) {
            if (drillDownsState[i].expanded === false) return false;
        }

        return true;
    }

    render() {

        const { drillDownsState } = this.state;

        return (
            <ReactAux>
                <MainContainerHeader>
                    <ActionContainer>
                        <ActionContainerHeader>
                            <ActionContainerCrumbs crumbs={['Flood Determination']} />
                        </ActionContainerHeader>
                        <Button icon="fal fa-history">Flood Calculation worksheet</Button>
                    </ActionContainer>
                </MainContainerHeader>

                <Toolbar
                    className="mt-m mb-s"
                    leftSide={
                        <>
                            <Label className="mr-s"><strong>Show:</strong></Label>
                            <Radio className="mr-xs" name="radio-1">Property</Radio>
                            <Radio className="mr-xs" name="radio-1">UCC</Radio>
                            <Radio name="radio-1">All</Radio>
                        </>
                    }
                />

                <MainContainerScrollable>

                    <DrillDownContainer
                        actionBtnRender={() => <Button btnType="icon" icon={`fal fa-${this.isAllExpanded() ? 'compress' : 'expand'}-alt`} data-tip={this.isAllExpanded() ? 'Compress' : 'Expand'} onClick={() => this.toggleExpandAll()} />}
                        cols={[
                            { title: 'Collateral Decrtiption', percentage: 35 },
                            { title: 'Borrower Name', percentage: 25, textAlign: 'left' },
                            { title: 'Facility ID', percentage: 10 },
                            { title: 'Main Address', percentage: 30, textAlign: 'left' },
                        ]}
                    >
                        {drillDowns.map((d, i) =>
                            <DrillDown
                                key={i}
                                cols={d.cols}
                                isActive={drillDownsState[i].isAcitve}
                                expanded={drillDownsState[i].expanded}
                                onArrowClick={() => this.onExpand(i)}
                                onRowClick={() => this.onExpand(i)}
                                dataUI={i + ''}
                            >
                                {d.content}
                            </DrillDown>
                        )}
                    </DrillDownContainer>

                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoFloodDetermination;